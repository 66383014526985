import React, { useContext, useState } from "react";
import { CoachCalendarData } from "utility/constants/useContext";
import Baseline from "assets/icons/CoachCalendar/Baseline.svg";
import Tempo from "assets/icons/CoachCalendar/Tempo.svg";
import Intervals from "assets/icons/CoachCalendar/Intervals.svg";
import TimeTrail from "assets/icons/CoachCalendar/TimeTrail.svg";
import Custom from "assets/icons/CoachCalendar/Custom.svg";
import SprintICon from "assets/icons/CoachCalendar/SprintIcon.svg";
import OlympicIcon from "assets/icons/CoachCalendar/Olympic.svg";
import HalfIronman from "assets/icons/CoachCalendar/HalfIronMan.svg";
import Ironman from "assets/icons/CoachCalendar/Ironman.svg";
import rightArrow from "assets/icons/right-arrow.png";
import Run from "assets/icons/RunIcon.svg";
import Swim from "assets/icons/swim.svg";
import Bike from "assets/icons/bike.svg";
import BrickIcon from "assets/icons/CoachCalendar/BrickIcon.svg";
import TriIcon from "assets/icons/CoachCalendar/TriIcon.svg";
import RightIcon from "assets/icons/right-arrow.png";
import { RunIntervals } from "pages/CoachCalendarPage/predefinedTemplates";
import { RunBaseline } from "pages/CoachCalendarPage/predefinedTemplates";
import { RunTT } from "pages/CoachCalendarPage/predefinedTemplates";
import { RunTempo } from "pages/CoachCalendarPage/predefinedTemplates";
import { RunCustom } from "pages/CoachCalendarPage/predefinedTemplates";
import { SwimBaseline } from "pages/CoachCalendarPage/predefinedTemplates";
import { SwimCustom } from "pages/CoachCalendarPage/predefinedTemplates";
import { SwimIntervals } from "pages/CoachCalendarPage/predefinedTemplates";
import { SwimTempo } from "pages/CoachCalendarPage/predefinedTemplates";
import { SwimTT } from "pages/CoachCalendarPage/predefinedTemplates";
import { BikeBaseline } from "pages/CoachCalendarPage/predefinedTemplates";
import { BikeIntervals } from "pages/CoachCalendarPage/predefinedTemplates";
import { BikeTT } from "pages/CoachCalendarPage/predefinedTemplates";
import { BikeTempo } from "pages/CoachCalendarPage/predefinedTemplates";
import { BikeCustom } from "pages/CoachCalendarPage/predefinedTemplates";
import { MultiWorkoutStpesData } from "pages/CoachCalendarPage/predefinedTemplates";
import { format, getISODay } from "date-fns";

function NewWorkoutMenu({ setNewWorkout }) {
  const Props = useContext(CoachCalendarData);
  const [openDropdown, setOpenDropdown] = useState(null); // Track the currently opened dropdown
  const formattedDate = format(new Date(Props.eventDate), "dd-MM-yyyy");
  const dayOfWeek = getISODay(new Date(Props.eventDate));

  const brickSelection = ["Run", "Bike", "Swim"];

  const MenuData = [
    {
      Icon: Run,
      category: "Run",
      subCategory: ["Baseline", "Tempo", "Intervals", "Time Trial", "Custom"],
      imgUrl: [Baseline, Tempo, Intervals, TimeTrail, Custom],
    },

    {
      Icon: Swim,
      category: "Swim",
      subCategory: ["Baseline", "Tempo", "Intervals", "Time Trial", "Custom"],
      imgUrl: [Baseline, Tempo, Intervals, TimeTrail, Custom],
    },

    {
      Icon: Bike,
      category: "Bike",
      subCategory: ["Baseline", "Tempo", "Intervals", "Time Trial", "Custom"],
      imgUrl: [Baseline, Tempo, Intervals, TimeTrail, Custom],
    },

    {
      Icon: BrickIcon,
      category: "Brick",
      subCategory: ["Run", "Bike", "Swim"],
      imgUrl: [Baseline, Tempo, Intervals, TimeTrail, Custom],
    },

    {
      Icon: TriIcon,
      category: "Tri",
      subCategory: ["Sprint", "Olympic", "70.3", "Ironman"],
      imgUrl: [SprintICon, OlympicIcon, HalfIronman, Ironman],
    },
  ];

  const BrickSwim = {
    day: dayOfWeek,
    date: formattedDate,
    name: "Brick - Swim",
    short_name: "Tri - Swim",
    long_name: "Tri - Swim",
    workout_type: "SWIM",
    total_distance: 100.0,
    notes: "",
    sync_device: 1,
    is_baseline: false,
    steps: [
      {
        sequence: 1,
        no_of_repeats: 1,
        activity_steps: [
          {
            time: null,
            distance: 100.0,
            activity: "Swim",
            tag1: null,
            tag2: null,
            note: null,
            sync_device: 1,
            primaryTarget: null,
            strokeType: "FR",
            equipmentType: "NA",
            secondaryTarget: null,
            sequence: 1,
          },
        ],
      },
    ],
  };

  const BrickBike = {
    day: dayOfWeek,
    date: formattedDate,
    name: "Brick - Bike",
    short_name: "Brick - Bike",
    long_name: "Brick - Bike",
    workout_type: "BIKE",
    total_hours: "00:40:00",
    total_distance: 0.0,
    notes: "",
    sync_device: 1,
    is_baseline: false,
    steps: [
      {
        sequence: 1,
        no_of_repeats: 1,
        activity_steps: [
          {
            time: "00:40:00",
            distance: 0.0,
            activity: "Bike",
            tag1: "1",
            tag2: "",
            note: null,
            sync_device: 1,
            primaryTarget: {
              target: "HR",
              value: "1",
            },
            strokeType: null,
            equipmentType: null,
            secondaryTarget: {
              target: "No Target",
              value: null,
            },
            sequence: 1,
          },
        ],
      },
    ],
  };

  const BrickRun = {
    day: dayOfWeek,
    date: formattedDate,
    name: "Brick - Run",
    short_name: "Brick - Run",
    long_name: "Brick - Run",
    workout_type: "RUN",
    total_hours: "00:40:00",
    total_distance: 0.0,
    notes: "",
    sync_device: 1,
    is_baseline: false,
    steps: [
      {
        sequence: 1,
        no_of_repeats: 1,
        activity_steps: [
          {
            time: "00:40:00",
            distance: 0.0,
            activity: "Run",
            tag1: "1",
            tag2: "",
            note: null,
            sync_device: 1,
            primaryTarget: {
              target: "HR",
              value: "1",
            },
            strokeType: null,
            equipmentType: null,
            secondaryTarget: null,
            sequence: 1,
          },
        ],
      },
    ],
  };

  const toggleDropdown = (index) => {
    // Toggle the dropdown state
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleBrickSelection = (event) => {
    Props.setBrickSelectionOne(event.target.value);
  };

  const handleNext = () => {
    if (
      Props.brickSelectionOne === "Swim" ||
      Props.brickSelectionTwo === "Swim"
    ) {
      Props.setMultiWorkoutType("SWIM");
      Props.setmultiWorkout(true);
      Props.setEditWorkout(true);
      if (Props.brickSelectionOne === "Swim") {
        Props.setSubmitSteps(BrickSwim);
      }
      Props.setSubmitMultiSteps((prevSteps) => {
        return [BrickSwim, ...prevSteps];
      });
    }
    if (
      Props.brickSelectionOne === "Bike" ||
      Props.brickSelectionTwo === "Bike"
    ) {
      Props.setMultiWorkoutType("BIKE");
      Props.setmultiWorkout(true);
      Props.setEditWorkout(true);
      if (Props.brickSelectionOne === "Bike") {
        Props.setSubmitSteps(BrickBike);
      }
      Props.setSubmitMultiSteps((prevSteps) => {
        return [BrickBike, ...prevSteps];
      });
    }
    if (
      Props.brickSelectionOne === "Run" ||
      Props.brickSelectionTwo === "Run"
    ) {
      Props.setMultiWorkoutType("RUN");
      Props.setmultiWorkout(true);
      Props.setEditWorkout(true);
      if (Props.brickSelectionOne === "Run") {
        Props.setSubmitSteps(BrickRun);
      }
      Props.setSubmitMultiSteps((prevSteps) => {
        return [BrickRun, ...prevSteps];
      });
    }
  };

  const handleClick = (category, type) => {
    Props.setNewWorkoutSelected(true);

    if (category === "Run") {
      if (type === "Baseline") {
        Props.setBaseline(true);
        Props.setSubmitSteps(RunBaseline);
      } else if (type === "Tempo") {
        Props.setSubmitSteps(RunTempo);
      } else if (type === "Intervals") {
        Props.setSubmitSteps(RunIntervals);
      } else if (type === "Time Trial") {
        Props.setSubmitSteps(RunTT);
      } else if (type === "Custom") {
        Props.setSubmitSteps(RunCustom);
      }
      Props.setEditWorkout(true);
    } else if (category === "Tri") {
      Props.setmultiWorkout(true);
      Props.setEditWorkout(true);
      Props.setSubmitSteps(MultiWorkoutStpesData[0]);
      Props.setSubmitMultiSteps(MultiWorkoutStpesData);
    } else if (category === "Swim") {
      if (type === "Baseline") {
        Props.setBaseline(true);
        Props.setSubmitSteps(SwimBaseline);
      } else if (type === "Tempo") {
        Props.setSubmitSteps(SwimTempo);
      } else if (type === "Intervals") {
        Props.setSubmitSteps(SwimIntervals);
      } else if (type === "Time Trial") {
        Props.setSubmitSteps(SwimTT);
      } else if (type === "Custom") {
        Props.setSubmitSteps(SwimCustom);
      }
      Props.setEditWorkout(true);
    } else if (category === "Bike") {
      if (type === "Baseline") {
        Props.setBaseline(true);
        Props.setSubmitSteps(BikeBaseline);
      } else if (type === "Tempo") {
        Props.setSubmitSteps(BikeTempo);
      } else if (type === "Intervals") {
        Props.setSubmitSteps(BikeIntervals);
      } else if (type === "Time Trial") {
        Props.setSubmitSteps(BikeTT);
      } else if (type === "Custom") {
        Props.setSubmitSteps(BikeCustom);
      }
      Props.setEditWorkout(true);
    }
  };

  return (
    <>
      <div className="min-w-[20%] min-h-full overflow-hidden z-20 py-8">
        <div className="flex flex-col h-full">
          <div className="calendar_border bg-[#F8F8F8] w-full h-full p-4">
            <div className="flex justify-end">
              <p className="text-right text-[var(--black)]">
                {Props.trainigSliderDate}
              </p>
            </div>

            <div
              className=" flex items-center pt-4 cursor-pointer"
              onClick={() => setNewWorkout(false)}
            >
              <img src={RightIcon} className="w-4 h-4 -rotate-180" alt="" />
              <p className="text-[var(--blue)] text-[1.2rem]">
                Create a New Workout
              </p>
            </div>

            <div className="flex flex-col">
              <div className="flex flex-col pt-4">
                {MenuData.map((item, index) => (
                  <div
                    className="border-b-[1px] border-[#DBDBDB] py-2"
                    key={`${index}-${item.category}`}
                  >
                    <div
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() => toggleDropdown(index)}
                    >
                      <div className="flex items-center ">
                        <img
                          className="w-auto h-4 mr-2"
                          src={item.Icon}
                          alt=""
                        />
                        <p className="text-[1rem] text-[var(--blue)]">
                          {item.category}
                        </p>
                      </div>

                      <img
                        className={`h-3 w-3 transition-transform ${
                          openDropdown === index ? "rotate-90" : ""
                        }`}
                        src={rightArrow}
                        alt=""
                      />
                    </div>

                    {/* Dropdown Section */}
                    {openDropdown === index && (
                      <div className="py-2">
                        {item.category === "Brick" ? (
                          <div className="flex flex-col">
                            {/* Selector for Brick */}
                            <div className="flex items-center">
                              <div className="flex flex-col items-center">
                                <span className="flex justify-center items-center bg-white w-4 h-4 rounded-full">
                                  <span className="text-[var(--blue)]">1</span>
                                </span>
                              </div>
                              <select
                                value={Props.brickSelectionOne}
                                onChange={(e) =>
                                  Props.setBrickSelectionOne(e.target.value)
                                }
                                className="w-full p-2 border border-[#DBDBDB] rounded-md text-[0.9rem] text-[var(--blue)] outline-none ml-2 appearance-none"
                              >
                                <option value="" disabled>
                                  Select First Brick Workout
                                </option>
                                {brickSelection
                                  .filter(
                                    (option) =>
                                      option !== Props.brickSelectionTwo
                                  ) // Filter out the selected value from the first dropdown
                                  .map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  ))}
                              </select>
                            </div>

                            <div className="h-4 w-[1.5px] bg-[var(--blue)] ml-2"></div>

                            <div className="flex pb-4 items-center">
                              <div className="flex flex-col items-center">
                                <span className="flex justify-center items-center bg-white w-4 h-4 rounded-full">
                                  <span className="text-[var(--blue)]">2</span>
                                </span>
                              </div>
                              <select
                                value={Props.brickSelectionTwo}
                                onChange={(e) =>
                                  Props.setBrickSelectionTwo(e.target.value)
                                }
                                className="w-full p-2 border border-[#DBDBDB] rounded-md text-[0.9rem] text-[var(--blue)] outline-none ml-2 appearance-none"
                              >
                                <option value="" disabled>
                                  Select Second Brick Workout
                                </option>
                                {brickSelection
                                  .filter(
                                    (option) =>
                                      option !== Props.brickSelectionOne
                                  ) // Filter out the selected value from the first dropdown
                                  .map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  ))}
                              </select>
                            </div>

                            {/* Next Button & Cancel  BUtton*/}
                            <div className="flex justify-end">
                              <button
                                onClick={() => {
                                  Props.setBrickSelectionOne("");
                                  Props.setBrickSelectionTwo("");
                                }}
                                className="cancelButton"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={handleNext}
                                className="saveButton"
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        ) : (
                          // Normal Subcategory List
                          item.subCategory.map((subItem, subIndex) => (
                            <div
                              key={`${index}-${subIndex}`}
                              className="flex items-center py-2 pl-4 cursor-pointer"
                              onClick={() =>
                                handleClick(item.category, subItem)
                              }
                            >
                              <img
                                className="w-4 h-4 mr-4"
                                src={item.imgUrl[subIndex]}
                                alt=""
                              />
                              <p className="text-[0.9rem] text-[var(--blue)] ">
                                {subItem}
                              </p>
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewWorkoutMenu;
