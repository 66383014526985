import React, { useContext, useEffect, useState } from "react";
import { CoachCalendarData } from "utility/constants/useContext";
import axios from "axios";
import { Header } from "api/Signin";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import RightIcon from "assets/icons/right-arrow.png";
import { ShortToFull, FullToShort } from "hooks/convertValues";

function EditWorkoutSteps({
  setEdit,
  stepsIndex,
  activityStepsIndex,
  WorkoutType,
  setSubmitSteps,
}) {
  const Props = useContext(CoachCalendarData);
  let disableBtn = Props.isBaseline;
  const stepsType = [WorkoutType, "Warmup", "Rest", "Recover", "CoolDown"];
  const primaryTarget = [
    "No Target",
    "Heart Rate",
    "Power Zone",
    "Pace",
    "Cadence",
  ];
  const secondaryTarget = ["No Target", "Power Zone", "Cadence"];
  const stroke = [
    "Any Stroke",
    "Free Style",
    "BackStroke",
    "BreastStroke",
    "Drill",
    "Fly",
    "Mixed",
  ];

  const equipment = [
    "Pull Buoy",
    "Kickboard",
    "Fins",
    "Paddles",
    "Snorkel",
    "None",
  ];

  // State variables
  const [stepType, setStepType] = useState(WorkoutType);
  const [durationType, setDurationType] = useState("Time");
  const [durationValue, setDurationValue] = useState("00:10:00");
  const [primaryTargetValue, setPrimaryTargetValue] = useState("No Target");
  const [primaryZone, setPrimaryZone] = useState("");
  const [secondaryZone, setSecondaryZone] = useState("");
  const [secondaryTargetValue, setSecondaryTargetValue] = useState("No Target");
  const [strokeType, setStrokeType] = useState(null);
  const [equipmentType, setEquipmentType] = useState(null);
  const [cadenceInputOne, setCadenceInputOne] = useState("0.0");
  const [cadenceInputTwo, setCadenceInputTwo] = useState("0.0");
  const [ScadenceInputOne, setSCadenceInputOne] = useState("0.0");
  const [ScadenceInputTwo, setSCadenceInputTwo] = useState("0.0");
  const [paceInputOne, setPaceInputOne] = useState("00:00");
  const [paceInputTwo, setPaceInputTwo] = useState("00:00");
  const [PsubmitHrPz, setPSubmitHrPz] = useState();
  const [SsubmitHrPz, setSSubmitHrPz] = useState();
  const [notes, setNotes] = useState("");
  const [zone, setZone] = useState([]);
  const [zoneS, setZoneS] = useState([]);
  const header = Header(); // Assuming this function provides the required headers.
  const type = FullToShort(primaryTargetValue); // Maps target value to type.
  const typeS = FullToShort(secondaryTargetValue); // Maps target value to type.
  const workout_category = WorkoutType.toUpperCase(); // Convert to uppercase for consistency.

  // check if baeslin or not

  const filteredPrimaryTarget = primaryTarget.filter((item) => {
    if (
      WorkoutType === "Run" &&
      (item === "Cadence" || item === "Power Zone")
    ) {
      return false;
    }
    if (WorkoutType === "Bike" && item === "Pace") {
      return false;
    }
    if (item === secondaryTargetValue) {
      return false;
    }
    return true;
  });

  // Ensure "No Target" is always included
  if (!filteredPrimaryTarget.includes("No Target")) {
    filteredPrimaryTarget.unshift("No Target");
  }

  const filteredSecondaryTarget = secondaryTarget.filter(
    (item) => item !== primaryTargetValue
  );

  // Ensure "No Target" is always included
  if (!filteredSecondaryTarget.includes("No Target")) {
    filteredSecondaryTarget.unshift("No Target");
  }

  // get zones
  useEffect(() => {
    if (primaryTarget === "No Target") {
      return;
    } else {
      getZones();
    }

    if (secondaryTargetValue === "No Target") {
      return;
    } else {
      getZonesS();
    }
  }, [primaryTargetValue, secondaryTargetValue]); // Add necessary dependencies.

  const getZones = async () => {
    try {
      const res = await axios.get(API_URL_CONSTANTS.GET_ZONES, {
        headers: header,
        params: {
          ref: Props.athleteId,
          type: type,
          workout_category: workout_category,
        },
      });

      if (res.status === 200) {
        // Filter the content array to find the matching workout category and set the zones.
        const matchingContent = res.data.content.find(
          (content) =>
            content.workout_category === workout_category.toUpperCase()
        );

        if (matchingContent) {
          setZone(matchingContent.zones);
        } else {
          console.warn("No matching workout category found");
          setZone([]); // Reset zones if no match is found.
        }
      } else {
        console.error(`Unexpected status code: ${res.status}`);
      }
    } catch (error) {
      console.error("Error fetching zones:", error);
    }
  };

  const getZonesS = async () => {
    try {
      const res = await axios.get(API_URL_CONSTANTS.GET_ZONES, {
        headers: header,
        params: {
          ref: Props.athleteId,
          type: typeS,
          workout_category: workout_category,
        },
      });

      if (res.status === 200) {
        // Filter the content array to find the matching workout category and set the zones.
        const matchingContent = res.data.content.find(
          (content) =>
            content.workout_category === workout_category.toUpperCase()
        );

        if (matchingContent) {
          setZoneS(matchingContent.zones);
        } else {
          setZoneS([]); // Reset zones if no match is found.
        }
      } else {
        console.error(`Unexpected status code: ${res.status}`);
      }
    } catch (error) {
      console.error("Error fetching zones:", error);
    }
  };

  // set Perfilled values
  useEffect(() => {
    const mapToIndex =
      Props.submitSteps.steps[stepsIndex].activity_steps[activityStepsIndex];
    setStepType(ShortToFull(mapToIndex.activity));
    setEquipmentType(ShortToFull(mapToIndex.equipmentType));
    setStrokeType(ShortToFull(mapToIndex.strokeType));
    if (mapToIndex?.note) {
      setNotes(mapToIndex.note);
    }

    if (mapToIndex.time === null || mapToIndex.time === undefined) {
      setDurationType("Distance");
      setDurationValue(mapToIndex.distance);
    } else {
      setDurationType("Time");
      setDurationValue(mapToIndex.time);
    }

    const primaryTarget = {
      target: ShortToFull(mapToIndex?.primaryTarget?.target),
      value: ShortToFull(mapToIndex?.primaryTarget?.value),
    };
    if (
      mapToIndex?.primaryTarget === null ||
      mapToIndex?.primaryTarget === undefined
    ) {
      setPrimaryTargetValue("No Target");
    } else {
      setPrimaryTargetValue(primaryTarget.target);
    }

    const secondaryTarget = {
      target: ShortToFull(mapToIndex?.secondaryTarget?.target),
      value: ShortToFull(mapToIndex?.secondaryTarget?.value),
    };
    if (
      mapToIndex?.secondaryTarget === null ||
      mapToIndex?.secondaryTarget === undefined
    ) {
      setSecondaryTargetValue("No Target");
    } else {
      setSecondaryTargetValue(secondaryTarget.target);
    }

    // set cadence primary
    if (mapToIndex?.primaryTarget?.value) {
      const [PcadenceInputOne, PcadenceInputTwo] =
        mapToIndex?.primaryTarget?.value?.split("~")?.map(Number);
      setCadenceInputOne(PcadenceInputOne);
      setCadenceInputTwo(PcadenceInputTwo);
    }

    // set cadence secondary
    if (mapToIndex?.secondaryTarget?.value) {
      const [ScadenceInputOne, ScadenceInputTwo] =
        mapToIndex?.secondaryTarget?.value?.split("~")?.map(Number);
      setSCadenceInputOne(ScadenceInputOne);
      setSCadenceInputTwo(ScadenceInputTwo);
    }

    // set pace value primary
    if (mapToIndex?.primaryTarget?.target === "PC") {
      const pace = Props.convertZones(
        Props.submitSteps.Workout_type,
        mapToIndex?.primaryTarget.target,
        mapToIndex?.primaryTarget.value
      );
      const [paceOne, paceTwo] = pace?.limit?.split(" to ");
      setPaceInputOne(paceOne);
      setPaceInputTwo(paceTwo);
    }
  }, []);

  // set level primary
  useEffect(() => {
    if (primaryZone) {
      const filteredPrimaryZone = primaryZone.split(" ")[0]; // e.g., "Z1"

      const matchingZone = zone.find((z) => z.name === filteredPrimaryZone);

      if (matchingZone) {
        setPSubmitHrPz(matchingZone.level);
      }
    }
  }, [primaryZone]);

  // set level secondary
  useEffect(() => {
    if (secondaryZone) {
      const filteredSecondaryZone = secondaryZone.split(" ")[0]; // e.g., "Z1"

      const matchingZone = zoneS.find((z) => z.name === filteredSecondaryZone);

      if (matchingZone) {
        setSSubmitHrPz(matchingZone.level);
      }
    }
  }, [secondaryZone]);

  // set cadence as primayZone
  useEffect(() => {
    const cadence = `${cadenceInputOne}~${cadenceInputTwo}`;
    if (cadenceInputOne !== "0.0" && cadenceInputOne !== "0.0") {
      setPSubmitHrPz(cadence);
    }
  }, [cadenceInputOne, cadenceInputTwo]);

  // set cadence as SecondaryZone
  useEffect(() => {
    const cadence = `${ScadenceInputOne}~${ScadenceInputTwo}`;

    if (ScadenceInputOne !== "0.0" && ScadenceInputOne !== "0.0") {
      setSSubmitHrPz(cadence);
    }
  }, [ScadenceInputOne, ScadenceInputTwo]);

  // convert  mins in secs
  const convertToSeconds = (pace) => {
    const [minutes, seconds] = pace.split(":").map(Number);
    return minutes * 60 + seconds;
  };

  // convert pace
  useEffect(() => {
    // Convert pace inputs to seconds
    const paceSecOne = convertToSeconds(paceInputOne);
    const paceSecTwo = convertToSeconds(paceInputTwo);

    // Calculate pace in meters per second
    const paceOne = (1000 / paceSecOne).toFixed(2);
    const paceTwo = (1000 / paceSecTwo).toFixed(2);

    // Create the pace range string
    const pace = `${paceOne}~${paceTwo}`;
    if (paceInputOne !== "00:00" && paceInputTwo !== "00:00") {
      setPSubmitHrPz(pace);
    }
  }, [paceInputOne, paceInputTwo]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const activitiyShorName = FullToShort(stepType);
    const primaryTargetValueShortName = FullToShort(primaryTargetValue);
    const secondaryTargetValueShortName = FullToShort(secondaryTargetValue);
    const convertedStrokeType = FullToShort(strokeType);
    const convertedEquipmentType = FullToShort(equipmentType);

    let PrimaryTargetValue;
    let SecondaryTarget;

    if (PrimaryTargetValue === "No Target" || WorkoutType === "Swim") {
      PrimaryTargetValue = null;
    } else {
      if (PsubmitHrPz) {
        PrimaryTargetValue = {
          target: primaryTargetValueShortName,
          value: PsubmitHrPz,
        };
      } else {
        PrimaryTargetValue = null;
      }
    }

    if (secondaryTargetValue === "No Target" || WorkoutType === "Swim") {
      SecondaryTarget = null;
    } else {
      if (SsubmitHrPz) {
        SecondaryTarget = {
          target: secondaryTargetValueShortName,
          value: SsubmitHrPz,
        };
      } else {
        SecondaryTarget = null;
      }
    }

    // Construct the new activity step
    const activity_steps = [
      {
        time: durationType === "Time" ? durationValue : null,
        distance:
          durationType === "Distance" ? parseFloat(durationValue) || 0.0 : 0.0,
        activity: activitiyShorName,
        tag1: "",
        tag2: "",
        sync_device: 1,
        primaryTarget: PrimaryTargetValue,
        secondaryTarget: SecondaryTarget,
        strokeType: WorkoutType === "Swim" ? convertedStrokeType : null,
        equipmentType: WorkoutType === "Swim" ? convertedEquipmentType : null,
        note: notes,
        sequence: activityStepsIndex,
      },
    ];

    // Update submitSteps state
    setSubmitSteps((prevSteps) => {
      const updatedSteps = [...prevSteps.steps];

      if (stepsIndex >= 0 && stepsIndex < updatedSteps.length) {
        updatedSteps[stepsIndex] = {
          ...updatedSteps[stepsIndex],
          activity_steps: activity_steps,
        };
      }

      return {
        ...prevSteps,
        id: Props.refId || prevSteps.id,
        steps: updatedSteps,
      };
    });

    setEdit(false);
  };

  return (
    <div className="flex flex-col justify-start w-full p-4 bg-white border-[#8ECAE6] border-[1px]">
      <div className="flex justify-start mb-2">
        <span className="text-[.7rem] text-[var(--blue)]">Add Steps</span>
      </div>
      <form className="space-y-4" onSubmit={handleSubmit}>
        {/* Step Type */}
        <div className="grid grid-cols-3 items-center gap-4">
          <label
            htmlFor="stepType"
            className="text-sm font-medium text-gray-700"
          >
            Step Type:
          </label>
          <div className="relative">
            <select
              id="stepType"
              value={stepType}
              onChange={(e) => setStepType(e.target.value)}
              disabled={disableBtn}
              className=" w-full col-span-3 border border-gray-300 rounded px-3 py-2 focus:outline-none appearance-none"
            >
              {stepsType.map((item, index) => (
                <option key={`stepType-${index}`} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <img className="w-2 h-2 rotate-90" src={RightIcon} alt="" />
            </div>
          </div>
        </div>

        {/* Duration */}
        <div className="grid grid-cols-3 items-center gap-4">
          <div className="">
            <label
              htmlFor="durationType"
              className="text-sm font-medium text-gray-700"
            >
              Duration:
            </label>
          </div>

          <div className="relative w-full">
            <select
              id="durationType"
              value={durationType}
              onChange={(e) => setDurationType(e.target.value)}
              disabled={disableBtn}
              className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none appearance-none"
            >
              <option value="Time">Time</option>
              <option value="Distance">Distance</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <img className="w-2 h-2 rotate-90" src={RightIcon} alt="" />
            </div>
          </div>

          <div className=" w-full  ">
            {durationType === "Distance" ? (
              <div>
                <input
                  type="number"
                  value={durationValue}
                  onChange={(e) => setDurationValue(e.target.value)}
                  placeholder="100.0m"
                  className="w-full flex-1  border border-gray-300 rounded px-3 py-2 focus:outline-none"
                />
              </div>
            ) : (
              <input
                type="text"
                placeholder={durationType === "Time" ? "hh:mm:ss" : "Distance"}
                value={durationValue}
                onChange={(e) => setDurationValue(e.target.value)}
                className="w-full flex-1 border border-gray-300 rounded px-3 py-2 focus:outline-none"
              />
            )}
          </div>
        </div>

        {/* Primary Target ---- Stroke */}

        {WorkoutType === "Swim" ? (
          <div className="grid grid-cols-3 items-center gap-4">
            <label
              htmlFor="primaryTarget"
              className="text-sm font-medium text-gray-700"
            >
              Stroke Type:
            </label>
            <div className="col-span-2 flex gap-4">
              <div className="relative w-full">
                <select
                  id="primaryTarget"
                  value={strokeType}
                  disabled={disableBtn}
                  onChange={(e) => setStrokeType(e.target.value)}
                  className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none appearance-none"
                >
                  {stroke.map((item, index) => (
                    <option key={`primaryTarget-${index}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <img className="w-2 h-2 rotate-90" src={RightIcon} alt="" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-3 items-start gap-4">
            <div className="">
              <label
                htmlFor="primaryTarget"
                className="text-sm font-medium text-gray-700"
              >
                Primary Target:
              </label>
            </div>

            <div className="w-full flex gap-4 ">
              <div className="relative w-full">
                <select
                  id="primaryTarget"
                  value={primaryTargetValue}
                  disabled={disableBtn}
                  onChange={(e) => setPrimaryTargetValue(e.target.value)}
                  className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none appearance-none"
                >
                  {filteredPrimaryTarget.map((item, index) => (
                    <option key={`primaryTarget-${index}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <img className="w-2 h-2 rotate-90" src={RightIcon} alt="" />
                </div>
              </div>
            </div>

            {primaryTargetValue === "No Target" ? (
              <></>
            ) : (
              <>
                {primaryTargetValue === "Heart Rate" ||
                primaryTargetValue == "Power Zone" ? (
                  <div className="relative w-full">
                    <select
                      id="targetZone"
                      value={primaryZone}
                      onChange={(e) => setPrimaryZone(e.target.value)}
                      className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none appearance-none"
                    >
                      {zone?.map((value, index) => (
                        <option key={`zones-${index}`}>
                          {value.name}{" "}
                          <span className="!text-[0.7rem]">
                            {`(${value.lower_limit}-${value.upper_limit})`}
                          </span>
                        </option>
                      ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <img
                        className="w-2 h-2 rotate-90"
                        src={RightIcon}
                        alt=""
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    {primaryTargetValue === "Cadence" ? (
                      <div className="flex flex-col ">
                        <div className="flex items-center">
                          <input
                            value={cadenceInputOne}
                            type="text"
                            placeholder="0.0"
                            onChange={(e) => setCadenceInputOne(e.target.value)}
                            className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none appearance-none"
                          />
                          <p className="px-2">To</p>
                          <input
                            value={cadenceInputTwo}
                            type="text"
                            placeholder="0.0"
                            onChange={(e) => setCadenceInputTwo(e.target.value)}
                            className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none appearance-none"
                          />
                        </div>
                        <div className="flex justify-end">
                          <p className="text-[var(--light-blue)]">rmp</p>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col ">
                        <div className="flex items-center">
                          <input
                            value={paceInputOne}
                            type="text"
                            placeholder="00:00"
                            onChange={(e) => setPaceInputOne(e.target.value)}
                            className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none appearance-none"
                          />
                          <p className="px-2">To</p>
                          <input
                            value={paceInputTwo}
                            type="text"
                            placeholder="00:00"
                            onChange={(e) => setPaceInputTwo(e.target.value)}
                            className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none appearance-none"
                          />
                        </div>
                        <div className="flex justify-end">
                          <p className="text-[var(--light-blue)]">min/km</p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        )}

        {/* Secondary Target --- Equipment*/}

        {WorkoutType === "Swim" ? (
          <div className="grid grid-cols-3 items-center gap-4">
            <label
              htmlFor="secondaryTarget"
              className="text-sm font-medium text-gray-700"
            >
              Equipment:
            </label>
            <div className="relative w-full col-span-2 ">
              <select
                id="secondaryTarget"
                value={equipmentType}
                onChange={(e) => setEquipmentType(e.target.value)}
                disabled={disableBtn}
                className="w-full col-span-2 border border-gray-300 rounded px-3 py-2 focus:outline-none appearance-none"
              >
                {equipment.map((item, index) => (
                  <option key={`secondaryTarget-${index}`} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <img className="w-2 h-2 rotate-90" src={RightIcon} alt="" />
              </div>
            </div>
          </div>
        ) : (
          <>
            {WorkoutType === "Bike" ? (
              <div className=" grid grid-cols-3 items-start gap-4">
                <label
                  htmlFor="secondaryTarget"
                  className="text-sm font-medium text-gray-700"
                >
                  Secondary Target:
                </label>

                <div className="relative">
                  <select
                    id="secondaryTarget"
                    value={secondaryTargetValue}
                    disabled={disableBtn}
                    onChange={(e) => setSecondaryTargetValue(e.target.value)}
                    className=" w-full col-span-2 border border-gray-300 rounded px-3 py-2 focus:outline-none appearance-none"
                  >
                    {filteredSecondaryTarget.map((item, index) => (
                      <option key={`secondaryTarget-${index}`} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <img className="w-2 h-2 rotate-90" src={RightIcon} alt="" />
                  </div>
                </div>

                {secondaryTargetValue === "No Target" ? (
                  <></>
                ) : (
                  <>
                    {secondaryTargetValue !== "Cadence" ? (
                      <div className="relative w-full">
                        <select
                          id="targetZone"
                          value={secondaryZone}
                          onChange={(e) => setSecondaryZone(e.target.value)}
                          className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none appearance-none"
                        >
                          {zoneS?.map((value, index) => (
                            <option key={`zones-${index}`}>
                              {value.name}{" "}
                              <span className="!text-[0.7rem]">
                                {`(${value.lower_limit}-${value.upper_limit})`}
                              </span>
                            </option>
                          ))}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <img
                            className="w-2 h-2 rotate-90"
                            src={RightIcon}
                            alt=""
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col ">
                        <div className="flex items-center">
                          <input
                            value={ScadenceInputOne}
                            type="text"
                            placeholder="0.0"
                            onChange={(e) =>
                              setSCadenceInputOne(e.target.value)
                            }
                            className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none appearance-none"
                          />
                          <p className="px-2">To</p>
                          <input
                            value={ScadenceInputTwo}
                            type="text"
                            placeholder="0.0"
                            onChange={(e) =>
                              setSCadenceInputTwo(e.target.value)
                            }
                            className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none appearance-none"
                          />
                        </div>
                        <div className="flex justify-end">
                          <p className="text-[var(--light-blue)]">rmp</p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </>
        )}

        {/* Notes */}
        <div className="grid grid-cols-3 items-center gap-4">
          <label htmlFor="notes" className="text-sm font-medium text-gray-700">
            Notes:
          </label>
          <textarea
            id="notes"
            rows="2"
            value={notes}
            disabled={disableBtn}
            onChange={(e) => setNotes(e.target.value)}
            className="col-span-2 border border-gray-300 rounded px-3 py-2 focus:outline-none"
            placeholder="Add any notes here"
          ></textarea>
        </div>

        {/* Buttons */}
        <div className="flex justify-end gap-4 mt-4">
          <button
            type="button"
            className="px-2 py-1 text-gray-700 border border-gray-300 rounded hover:bg-gray-100"
            onClick={() => setEdit(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-2 py-1 bg-green-600 text-white rounded hover:bg-green-700"
          >
            ADD
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditWorkoutSteps;

// Custom drop down

// import React, { useState } from "react";

// const CustomDropdown = ({ zones, selectedZone, onZoneSelect }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <div className="relative">
//       {/* Trigger button */}
//       <button
//         onClick={() => setIsOpen(!isOpen)}
//         className="border border-gray-300 rounded px-3 py-2 w-full text-left"
//       >
//         {selectedZone
//           ? `${selectedZone.name} (${selectedZone.lower_limit}-${selectedZone.upper_limit})`
//           : "Select Zone"}
//       </button>

//       {/* Dropdown menu */}
//       {isOpen && (
//         <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded shadow-md">
//           {zones.map((zone, index) => (
//             <li
//               key={`zone-${index}`}
//               onClick={() => {
//                 onZoneSelect(zone);
//                 setIsOpen(false);
//               }}
//               className="px-3 py-2 hover:bg-gray-100 cursor-pointer flex justify-between"
//             >
//               <span>{zone.name}</span>
//               <span className="text-gray-500 text-sm">
//                 {zone.lower_limit}-{zone.upper_limit}
//               </span>
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// export default function App() {
//   const zones = [
//     { name: "Z1", lower_limit: "50", upper_limit: "60" },
//     { name: "Z2", lower_limit: "60", upper_limit: "70" },
//     { name: "Z3", lower_limit: "70", upper_limit: "80" },
//   ];

//   const [selectedZone, setSelectedZone] = useState(null);

//   return (
//     <div className="p-4">
//       <h2 className="mb-2">Custom Styled Dropdown</h2>
//       <CustomDropdown
//         zones={zones}
//         selectedZone={selectedZone}
//         onZoneSelect={setSelectedZone}
//       />
//     </div>
// //   );
// }
