import React, { useState, useContext, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EditWorkoutSteps from "./EditWorkoutSteps";
import DeleteIcon from "assets/icons/DeleteIcon.svg";
import CancelIcon from "assets/img/redCancel.png";
import AddIcon from "assets/icons/add.svg";
import Tick from "assets/icons/CoachCalendar/tick.svg";
// import CancelIcon from "assets/icons/CoachCalendar/red_cancel.svg";
import EditIcon from "assets/icons/editIcon.svg";
import { CoachCalendarData } from "utility/constants/useContext";
import AddBtn from "assets/icons/CoachCalendar/add_btn.svg";
import SubBtn from "assets/icons/CoachCalendar/sub_btn.svg";
import DragIcon from "assets/icons/CoachCalendar/DragIcon (3).svg";
import {
  ZonesShortToFull,
  EquipmentTypeShortToFull,
  StrokeTypeShortToFull,
  convertMetersToKilometers,
  formatTime,
  activityNameShortToFull,
} from "hooks/convertValues";

function ViewStepsSection({
  setSubmitSteps,
  submitSteps,
  WorkoutType,
  notes,
  setNotes,
  stepsData,
}) {
  const Props = useContext(CoachCalendarData);
  const [isEdit, setEdit] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState(null); // Track the current edit index
  const [activityIndex, setActivityIndex] = useState(null);
  const [addNotes, setAddNotes] = useState(false);

  useEffect(() => {
    setNotes(Props.submitSteps.notes);
  }, [addNotes]);

  // plus repeat
  const handleAddRepeat = (index) => {
    setSubmitSteps((prevSteps) => {
      // Create a deep copy of the steps array
      const updatedSteps = prevSteps.steps.map((submitSteps, i) => {
        if (i === index) {
          return {
            ...submitSteps,
            no_of_repeats: submitSteps.no_of_repeats + 1,
          }; // Increment the repeat count for the targeted step
        }
        return submitSteps; // Return other steps unchanged
      });
      return {
        ...prevSteps,
        steps: updatedSteps, // Return updated steps
      };
    });
  };

  // subtract repeat
  const handleSubtractRepeat = (index) => {
    setSubmitSteps((prevSteps) => {
      // Create a deep copy of the steps array
      const updatedSteps = prevSteps.steps.map((submitSteps, i) => {
        if (i === index && submitSteps.no_of_repeats > 1) {
          return {
            ...submitSteps,
            no_of_repeats: submitSteps.no_of_repeats - 1,
          }; // Decrease the repeat count for the targeted step
        }
        return submitSteps; // Return other steps unchanged
      });
      return {
        ...prevSteps,
        steps: updatedSteps, // Return updated steps
      };
    });
  };

  // add steps in repeat section
  const handleAddStepsRepeat = (index) => {
    Props.setSubmitSteps((prevSteps) => {
      // Copy the existing steps array
      const updatedSteps = [...prevSteps.steps];

      // Find the specific step to update
      const stepToUpdate = updatedSteps[index];

      // Add new data to activity_steps[] for the selected step
      const updatedActivitySteps = [
        ...stepToUpdate.activity_steps,
        // New data to add
        {
          time: Props.submitSteps.workout_type === "SWIM" ? null : "00:10:00",
          distance: Props.submitSteps.workout_type === "SWIM" ? 100.0 : 0.0,
          activity: WorkoutType,
          note: null,
          sync_device: 1,
          sequence: 1,
        },
      ];

      // Update the step with the modified activity_steps
      stepToUpdate.activity_steps = updatedActivitySteps;

      // Return the updated SubmitSteps object
      return {
        ...prevSteps,
        steps: updatedSteps,
      };
    });
  };

  function handleEdit(index, ActivityStepsIndex) {
    setEdit(true);
    setCurrentEditIndex(index); // Set the index being edited
    setActivityIndex(ActivityStepsIndex);
  }

  function handleDelete(index, activityStepsIndex) {
    setSubmitSteps((prev) => {
      const updatedSteps = [...prev.steps];

      if (activityStepsIndex !== undefined) {
        // Delete specific activity step
        const updatedActivitySteps = [
          ...updatedSteps[index].activity_steps.slice(0, activityStepsIndex),
          ...updatedSteps[index].activity_steps.slice(activityStepsIndex + 1),
        ];

        updatedSteps[index] = {
          ...updatedSteps[index],
          activity_steps: updatedActivitySteps,
        };

        // Remove the entire step if no activity steps remain
        if (updatedActivitySteps.length === 0) {
          updatedSteps.splice(index, 1);
        }
      } else {
        // Delete the entire step if no specific activityStepsIndex is provided
        updatedSteps.splice(index, 1);
      }

      return {
        ...prev,
        steps: updatedSteps,
      };
    });
  }

  function handleAddNotes() {
    setAddNotes(false);
    setSubmitSteps((prev) => {
      return {
        ...prev,
        notes: notes,
      };
    });
  }

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedSteps = Array.from(submitSteps.steps);
    const [movedStep] = reorderedSteps.splice(result.source.index, 1);
    reorderedSteps.splice(result.destination.index, 0, movedStep);

    setSubmitSteps((prev) => ({
      ...prev,
      steps: reorderedSteps,
    }));
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="stepsDroppable">
          {(provided) => (
            <div
              className="h-full"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {/*--------------- Notes----------------*/}
              <div className=" flex flex-col">
                {addNotes ? (
                  <div className="flex items-center gap-4 text-[var(--blue)]">
                    <input
                      type="text"
                      placeholder="Add Notes"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      className="flex-1  border border-gray-300 rounded px-3 py-2 focus:outline-none"
                    />
                    <div className="flex">
                      <img
                        className=" h-5 w-5 cursor-pointer mr-2"
                        src={CancelIcon}
                        alt=""
                        onClick={() => {
                          setAddNotes(false);
                          setNotes("");
                        }}
                      />
                      <img
                        className=" h-5 w-5 cursor-pointer"
                        src={Tick}
                        alt=""
                        onClick={() => handleAddNotes()}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="text-[var(--blue)]">
                    {submitSteps.notes ? (
                      <div className="flex items-center">
                        <p>
                          Notes: <span>{submitSteps.notes}</span>
                        </p>
                        <img
                          src={EditIcon}
                          className="ml-2 h-3 w-3 cursor-pointer"
                          alt=""
                          onClick={() => setAddNotes(true)}
                        />
                      </div>
                    ) : (
                      <div className="flex items-center ">
                        <p>Notes</p>
                        <img
                          className="ml-2 h-3 w-3 cursor-pointer"
                          src={AddIcon}
                          alt=""
                          onClick={() => setAddNotes(true)}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/*-------------------- edit workout section -----------------*/}
              {/* Training Steps Start */}
              {submitSteps?.steps?.map((steps, index) => {
                const isLastObject = submitSteps.steps.length;
                const isRepeats = steps.no_of_repeats > 1;
                // key={`repeatsssss--${index}`}
                return (
                  <Draggable
                    key={`step-${index}`}
                    draggableId={`step-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={
                          isRepeats
                            ? "border-[#DBDBDB] border-[1px]  mt-2 rounded-[5px]"
                            : ""
                        }
                      >
                        {isRepeats && (
                          <>
                            <div className="flex items-center justify-between   pt-2 px-2">
                              <div className="flex items-center">
                                <div className="ml-2 flex">
                                  <img
                                    src={AddBtn}
                                    alt=""
                                    className="bg-[var(--light-grey)] px-2 text-[1.2rem] cursor-pointer rounded-[3px]"
                                    onClick={() => handleAddRepeat(index)}
                                  />

                                  <span className="text-[var(--blue)] mx-1">
                                    {steps.no_of_repeats} Repeat
                                  </span>
                                  <img
                                    src={SubBtn}
                                    alt=""
                                    className=" bg-[var(--light-grey)] px-2 text-[1.2rem] cursor-pointer rounded-[3px]"
                                    onClick={() => handleSubtractRepeat(index)}
                                  />
                                </div>
                              </div>

                              <div className="flex">
                                <img
                                  className="h-3 w-3  cursor-pointer mr-2"
                                  src={AddIcon}
                                  alt=""
                                  onClick={() => handleAddStepsRepeat(index)}
                                />
                                <img
                                  className="cursor-pointer"
                                  src={DeleteIcon}
                                  alt=""
                                  onClick={() => handleDelete(index)}
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <div
                          className={`flex flex-col w-full bg-[var(--white)]  rounded-[5px] ${
                            isRepeats ? "p-4" : ""
                          }`}
                        >
                          {/* Use this map for card info in the box e.g., wup, z1, time, distance */}
                          {steps.activity_steps &&
                            steps.activity_steps.map(
                              (ActivitySteps, ActivityStepsIndex) => {
                                const formattedActivity =
                                  activityNameShortToFull(
                                    ActivitySteps.activity
                                  );
                                let PrimaryTarget =
                                  ActivitySteps?.primaryTarget?.target;
                                let PrimaryValue =
                                  ActivitySteps?.primaryTarget?.value;

                                let SecondaryTarget =
                                  ActivitySteps?.secondaryTarget?.target;
                                let SecondaryValue =
                                  ActivitySteps?.secondaryTarget?.value;

                                const capWorkoutType =
                                  Props.submitSteps?.workout_type;

                                const PconvertedZones = Props.convertZones(
                                  capWorkoutType,
                                  PrimaryTarget,
                                  PrimaryValue
                                );

                                const SconvertedZones = Props.convertZones(
                                  capWorkoutType,
                                  SecondaryTarget,
                                  SecondaryValue
                                );

                                const formattedPrimaryTarget = ZonesShortToFull(
                                  ActivitySteps?.primaryTarget?.target
                                );

                                const formattedSecondaryTarget =
                                  ZonesShortToFull(
                                    ActivitySteps?.secondaryTarget?.target
                                  );

                                let res;
                                let km;
                                if (ActivitySteps.distance) {
                                  km = convertMetersToKilometers(
                                    ActivitySteps.distance
                                  );
                                }
                                if (
                                  ActivitySteps.time &&
                                  ActivitySteps.time.includes(":")
                                ) {
                                  res = formatTime(ActivitySteps.time);
                                }

                                let equipmentType = EquipmentTypeShortToFull(
                                  ActivitySteps?.equipmentType
                                );

                                let strokeType = StrokeTypeShortToFull(
                                  ActivitySteps?.strokeType
                                );

                                return (
                                  <div
                                    className="flex mt-2 "
                                    key={`activeStepss-${ActivityStepsIndex}`}
                                    ref={Props.stepsEndRef}
                                  >
                                    {isEdit &&
                                    currentEditIndex === index &&
                                    activityIndex === ActivityStepsIndex ? (
                                      <></>
                                    ) : (
                                      <>
                                        {/* Card heading color */}

                                        {ActivitySteps.activity === "WUP" ? (
                                          <div className="bg-[#FE5F55] py-[.2rem]  w-2 left_round"></div>
                                        ) : (
                                          <>
                                            {ActivitySteps.activity === "CD" ? (
                                              <div className="bg-[#bfd200] py-[.2rem]  w-2 left_round"></div>
                                            ) : (
                                              <>
                                                {steps.no_of_repeats === 1 ? (
                                                  <>
                                                    {index % 2 === 0 ? (
                                                      <div className=" bg-[#d1f7f3]  py-[.2rem]  w-2 left_round"></div>
                                                    ) : (
                                                      <div className=" bg-[#b2f7ef]  py-[.2rem]  w-2 left_round"></div>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {" "}
                                                    {steps.no_of_repeats > 1 &&
                                                    ActivityStepsIndex % 2 ===
                                                      0 ? (
                                                      <div className=" bg-[#d1f7f3]  py-[.2rem]  w-2 left_round"></div>
                                                    ) : (
                                                      <div className=" bg-[#b2f7ef]  py-[.2rem]  w-2 left_round"></div>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}

                                        {/* Card heading color */}
                                      </>
                                    )}

                                    <div className="flex  flex-col  w-full bg-[#F8F8F8] ">
                                      {isEdit &&
                                      currentEditIndex === index &&
                                      activityIndex === ActivityStepsIndex ? (
                                        <EditWorkoutSteps
                                          setEdit={setEdit}
                                          stepsIndex={index}
                                          activityStepsIndex={
                                            ActivityStepsIndex
                                          }
                                          WorkoutType={WorkoutType}
                                          setSubmitSteps={setSubmitSteps}
                                        />
                                      ) : (
                                        <>
                                          {/* drag edit delete menu */}
                                          <div className="flex justify-between items-center  p-2 ">
                                            <div className="flex items-center">
                                              <img
                                                src={DragIcon}
                                                alt=""
                                                className="pr-2"
                                              />
                                              <div className="p-2">
                                                {ActivitySteps.note && (
                                                  <span className="text-[var(--light-blue)] text-[0.8rem]">
                                                    Notes: {ActivitySteps.note}
                                                  </span>
                                                )}
                                              </div>
                                            </div>

                                            <div className="flex items-center">
                                              <span
                                                onClick={() =>
                                                  handleEdit(
                                                    index,
                                                    ActivityStepsIndex
                                                  )
                                                }
                                                className="text-[.8rem] text-[var(--light-blue)] mr-2 cursor-pointer"
                                              >
                                                Edit
                                              </span>
                                              <img
                                                className="cursor-pointer h-3 w-3"
                                                src={DeleteIcon}
                                                alt=""
                                                onClick={() =>
                                                  handleDelete(
                                                    index,
                                                    ActivityStepsIndex
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* Workout steps  Data*/}
                                          <div className="grid  grid-cols-5 justify-start gap-2  w-full px-4 text-[var(--blue)] font-medium  py-2 pt-0 text-[1rem]">
                                            {/* Activity Name */}
                                            <span className="px-1">
                                              {formattedActivity ||
                                                ActivitySteps?.activity}
                                            </span>

                                            {/* Distance and time */}
                                            <div className="flex flex-col  px-1">
                                              {ActivitySteps.time && (
                                                <span>
                                                  {res || ActivitySteps.time}
                                                </span>
                                              )}
                                              {ActivitySteps?.distance > 0 && (
                                                <>
                                                  <span className="">
                                                    {km ||
                                                      ActivitySteps.distance}
                                                  </span>
                                                </>
                                              )}
                                            </div>

                                            {/* primaryTarget */}
                                            {ActivitySteps?.primaryTarget ? (
                                              <div className="flex flex-col">
                                                <span className="">
                                                  {formattedPrimaryTarget ===
                                                  "No Target"
                                                    ? ""
                                                    : formattedPrimaryTarget ||
                                                      ActivitySteps
                                                        .primaryTarget.target}

                                                  <span className="pl-1 text-[0.8rem] italic">
                                                    {PconvertedZones ? (
                                                      <span>
                                                        {PconvertedZones.name}
                                                        <span>
                                                          {
                                                            PconvertedZones.limit
                                                          }
                                                        </span>
                                                      </span>
                                                    ) : (
                                                      ActivitySteps
                                                        .primaryTarget.value
                                                    )}
                                                  </span>
                                                </span>
                                                {ActivitySteps.primaryTarget
                                                  .value && (
                                                  <span className="text-[0.8rem] text-[var(--light-blue)]">
                                                    Primary Target
                                                  </span>
                                                )}
                                              </div>
                                            ) : (
                                              <>
                                                {/* strokeType */}
                                                {ActivitySteps?.strokeType ? (
                                                  <div className="flex flex-col">
                                                    <span>
                                                      {strokeType ===
                                                        "Any Stroke" ||
                                                      strokeType === "None"
                                                        ? ""
                                                        : strokeType ||
                                                          ActivitySteps?.strokeType}
                                                    </span>
                                                    <span className="text-[var(--light-blue)] text-[.7rem]">
                                                      {strokeType ===
                                                        "Any Stroke" ||
                                                      strokeType === "None"
                                                        ? ""
                                                        : "Stroke"}
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            )}
                                            {/* secondaryTarget */}
                                            {ActivitySteps?.secondaryTarget ? (
                                              <div className="flex flex-col">
                                                <span className="">
                                                  {formattedSecondaryTarget ===
                                                  "No Target"
                                                    ? ""
                                                    : formattedSecondaryTarget ||
                                                      ActivitySteps
                                                        ?.secondaryTarget
                                                        .target}
                                                  <span className="pl-1 text-[0.8rem] italic]">
                                                    {SconvertedZones ? (
                                                      <span>
                                                        {SconvertedZones.name}
                                                        <span>
                                                          {
                                                            SconvertedZones.limit
                                                          }
                                                        </span>
                                                      </span>
                                                    ) : (
                                                      ActivitySteps
                                                        .secondaryTarget.value
                                                    )}
                                                  </span>
                                                </span>
                                                {ActivitySteps.secondaryTarget
                                                  .value && (
                                                  <span className="text-[0.8rem] text-[var(--light-blue)]">
                                                    Secondary Target
                                                  </span>
                                                )}
                                              </div>
                                            ) : (
                                              <>
                                                {/* equipmentType */}
                                                {ActivitySteps?.equipmentType && (
                                                  <div className="flex flex-col">
                                                    <span>
                                                      {equipmentType === "None"
                                                        ? ""
                                                        : equipmentType ||
                                                          ActivitySteps?.equipmentType}
                                                    </span>
                                                    <span className=" text-[var(--light-blue)] text-[0.7rem]">
                                                      {equipmentType === "None"
                                                        ? ""
                                                        : "Equipment "}
                                                    </span>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default ViewStepsSection;
