import { clearLocalStorage } from "./basic";

export function throwError(error) {
  if (!error.response) {
    return "Network Error";
  }

  switch (error.response.status) {
    case 400:
      return "Bad Request (400)";
    case 401:
      clearLocalStorage(); // Clear storage for unauthorized users
      return "Unauthorized (401)";
    case 403:
      return "Forbidden (403)";
    case 404:
      return "Resource Not Found (404)";
    case 500:
      return "Internal Server Error (500)";
    default:
      return `Unexpected Error: ${error.response.status}`;
  }
}
