export const RunBaseline = {
  short_name: "Run",
  long_name: "Run-baseline",
  workout_type: "RUN",
  workout_template_label: "RB5",
  total_hours: null,
  total_distance: 5000.0,
  notes: "Run all out for 5km",
  sync_device: 1,
  steps: [
    {
      sequence: 1,
      no_of_repeats: 1,
      activity_steps: [
        {
          activity: "Run",
          distance: 5000.0,
          sequence: 1,
        },
      ],
    },
  ],
};

export const RunTempo = {
  short_name: "Run",
  long_name: "Run-tempo",
  workout_type: "RUN",
  workout_template_label: "RT1",
  total_hours: null,
  total_distance: 5000.0,
  notes: "Run all out for 5km",
  sync_device: 1,
  steps: [
    {
      sequence: 1,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 0.0,
          time: "00:10:00",
          activity: "WUP",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "2",
          },
          sequence: 1,
        },
      ],
    },

    {
      sequence: 2,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 0.0,
          time: "00:40:00",
          activity: "Run",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "4",
          },
          sequence: 1,
        },
      ],
    },
    {
      sequence: 2,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 0.0,
          time: "00:10:00",
          activity: "CD",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "1",
          },
          sequence: 1,
        },
      ],
    },
  ],
};

export const RunTT = {
  short_name: "Run",
  long_name: "Run-time_trial",
  workout_type: "RUN",
  workout_template_label: "RTT5",
  total_hours: null,
  total_distance: 5000.0,
  notes: "Run all out for 5km",
  sync_device: 1,
  steps: [
    {
      sequence: 1,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 5000.0,
          time: null,
          activity: "Run",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "3",
          },
          sequence: 1,
        },
      ],
    },
  ],
};

export const RunIntervals = {
  short_name: "Run",
  long_name: "Run-intervals",
  workout_type: "RUN",
  workout_template_label: "RI1",
  total_hours: null,
  total_distance: 5000.0,
  notes: "",
  sync_device: 1,
  steps: [
    {
      sequence: 1,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 0.0,
          time: "00:10:00",
          activity: "Run",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "2",
          },
          sequence: 1,
        },
      ],
    },

    {
      sequence: 2,
      no_of_repeats: 2,
      activity_steps: [
        {
          distance: 0.0,
          time: "00:03:00",
          activity: "Run",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "5",
          },
          sequence: 1,
        },
        {
          distance: 0.0,
          time: "00:01:00",
          activity: "RST",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "1",
          },
          sequence: 1,
        },
      ],
    },
    {
      sequence: 2,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 0.0,
          time: "00:10:00",
          activity: "CD",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "1",
          },
          sequence: 1,
        },
      ],
    },
  ],
};

export const RunCustom = {
  short_name: "Run",
  long_name: "Run-custom",
  workout_type: "RUN",
  workout_template_label: "RC5",
  total_hours: null,
  total_distance: 0.0,
  notes: "",
  sync_device: 1,
  steps: [],
};

export const SwimBaseline = {
  short_name: "Swim",
  long_name: "Swim-Baseline",
  workout_type: "SWIM",
  workout_template_label: "SB5",
  total_hours: null,
  total_distance: 1700,
  notes: "SWim all out for 1km",
  sync_device: 1,
  steps: [
    {
      sequence: 1,
      no_of_repeats: 1,
      activity_steps: [
        {
          activity: "Swim",
          distance: 1700,
          sequence: 1,
        },
      ],
    },
  ],
};

export const SwimTempo = {
  short_name: "Swim",
  long_name: "Swim-Tempo",
  workout_type: "SWIM",
  workout_template_label: "ST1",
  total_hours: null,
  total_distance: 1600.0,
  sync_device: 1,
  steps: [
    {
      sequence: 1,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 300,
          time: "",
          activity: "WUP",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "2",
          },
          sequence: 1,
        },
      ],
    },
    {
      sequence: 2,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 1000,
          time: "",
          activity: "Swim",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "4",
          },
          sequence: 1,
        },
      ],
    },
    {
      sequence: 2,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 300.0,
          time: "",
          activity: "CD",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "1",
          },
          sequence: 1,
        },
      ],
    },
  ],
};

export const SwimTT = {
  short_name: "Swim",
  long_name: "Swim-Time_trial",
  workout_type: "SWIM",
  workout_template_label: "STT5",
  total_hours: null,
  total_distance: 1700.0,
  sync_device: 1,
  steps: [
    {
      sequence: 1,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 1700.0,
          time: "",
          activity: "SWIM",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "3",
          },
          sequence: 1,
        },
      ],
    },
  ],
};

export const SwimIntervals = {
  short_name: "Swim",
  long_name: "Swim-Interval",
  workout_type: "SWIM",
  workout_template_label: "STT5",
  total_hours: "00:00:30",
  total_distance: 675.0,
  notes: "",
  sync_device: 1,
  steps: [
    {
      sequence: 1,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 300.0,
          time: "",
          activity: "WUP",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "2",
          },
          sequence: 1,
        },
      ],
    },

    {
      sequence: 2,
      no_of_repeats: 2,
      activity_steps: [
        {
          distance: 25.0,
          time: "",
          activity: "Swim",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "5",
          },
          sequence: 1,
        },
        {
          distance: 0.0,
          time: "00.00.10",
          activity: "RS",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "1",
          },
          sequence: 1,
        },
      ],
    },
    {
      sequence: 2,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 300.0,
          time: "",
          activity: "CD",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "1",
          },
          sequence: 1,
        },
      ],
    },
  ],
};

export const SwimCustom = {
  short_name: "Swim",
  long_name: "Swim-custom",
  workout_type: "SWIM",
  workout_template_label: "SC5",
  total_hours: "",
  total_distance: 0.0,
  notes: "",
  sync_device: 1,
  steps: [],
};

export const BikeBaseline = {
  short_name: "Bike",
  long_name: "Bike-Baseline",
  workout_type: "BIKE",
  workout_template_label: "BB5",
  total_hours: null,
  total_distance: 100000.0,
  notes: "Bike all out for 10km",
  sync_device: 1,
  steps: [
    {
      sequence: 1,
      no_of_repeats: 1,
      activity_steps: [
        {
          activity: "Bike",
          distance: 100000.0,
          sequence: 1,
        },
      ],
    },
  ],
};

export const BikeTempo = {
  short_name: "Bike",
  long_name: "Bike-Tempo",
  workout_type: "BIKE",
  workout_template_label: "BT1",
  total_hours: "01:35:00",
  total_distance: 0.0,
  sync_device: 1,
  steps: [
    {
      sequence: 1,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 0.0,
          time: "00:25:00",
          activity: "WUP",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "2",
          },
          sequence: 1,
        },
      ],
    },

    {
      sequence: 2,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 0.0,
          time: "00:45:00",
          activity: "Bike",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "2",
          },
          sequence: 1,
        },
      ],
    },
    {
      sequence: 2,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 0.0,
          time: "00:25:00",
          activity: "CD",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "2",
          },
          sequence: 1,
        },
      ],
    },
  ],
};

export const BikeTT = {
  short_name: "Bike",
  long_name: "Bike-Time_trial",
  workout_type: "BIKE",
  workout_template_label: "RTT5",
  total_hours: null,
  total_distance: 100000,
  sync_device: 1,
  steps: [
    {
      sequence: 1,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 100000,
          time: null,
          activity: "Bike",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "3",
          },
          sequence: 1,
        },
      ],
    },
  ],
};

export const BikeIntervals = {
  short_name: "Bike",
  long_name: "Bike-Interval",
  workout_type: "BIKE",
  workout_template_label: "BI1",
  total_hours: "00:49:00",
  total_distance: 0,
  sync_device: 1,
  steps: [
    {
      sequence: 1,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 0,
          time: "00:20:00",
          activity: "WUP",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "2",
          },
          sequence: 1,
        },
      ],
    },
    {
      sequence: 2,
      no_of_repeats: 2,
      activity_steps: [
        {
          distance: 0,
          time: "00:01:00",
          activity: "Bike",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "5",
          },
          sequence: 1,
        },
        {
          distance: 0,
          time: "00:02:00",
          activity: "RST",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "1",
          },
          sequence: 1,
        },
      ],
    },
    {
      sequence: 2,
      no_of_repeats: 1,
      activity_steps: [
        {
          distance: 0,
          time: "00:20:00",
          activity: "CD",
          sync_device: 1,
          primaryTarget: {
            target: "HR",
            value: "1",
          },
          sequence: 1,
        },
      ],
    },
  ],
};

export const BikeCustom = {
  short_name: "Bike",
  long_name: "Bike-custom",
  workout_type: "BIKE",
  workout_template_label: "BC5",
  total_hours: null,
  total_distance: 0.0,
  notes: "",
  sync_device: 1,
  steps: [],
};

export const MultiWorkoutStpesData = [
  {
    day: 0,
    date: "",
    name: "Tri - Swim",
    short_name: "Tri - Swim",
    long_name: "Tri - Swim",
    workout_type: "SWIM",
    total_distance: 100.0,
    notes: "",
    sync_device: 1,
    is_baseline: false,
    steps: [
      {
        sequence: 1,
        no_of_repeats: 1,
        activity_steps: [
          {
            time: null,
            distance: 100.0,
            activity: "Swim",
            tag1: null,
            tag2: null,
            note: null,
            sync_device: 1,
            primaryTarget: null,
            strokeType: "AS",
            equipmentType: "NA",
            secondaryTarget: null,
            sequence: 1,
          },
        ],
      },
    ],
  },
  {
    day: 0,
    date: "",
    name: "Tri - Bike",
    short_name: "Tri - Bike",
    long_name: "Tri - Bike",
    workout_type: "BIKE",
    total_hours: "00:40:00",
    total_distance: 0.0,
    notes: "",
    sync_device: 1,
    is_baseline: false,
    steps: [
      {
        sequence: 1,
        no_of_repeats: 1,
        activity_steps: [
          {
            time: "00:40:00",
            distance: 0.0,
            activity: "Bike",
            tag1: "1",
            tag2: "",
            note: null,
            sync_device: 1,
            primaryTarget: {
              target: "HR",
              value: "1",
            },
            secondaryTarget: {
              target: "No Target",
              value: null,
            },
            sequence: 1,
          },
        ],
      },
    ],
  },
  {
    day: null,
    date: "",
    name: "Tri - Run",
    short_name: "Tri - Run",
    long_name: "Tri - Run",
    workout_type: "RUN",
    total_hours: "00:40:00",
    total_distance: 0.0,
    notes: "",
    sync_device: 1,
    is_baseline: false,
    steps: [
      {
        sequence: 1,
        no_of_repeats: 1,
        activity_steps: [
          {
            time: "00:40:00",
            distance: 0.0,
            activity: "Run",
            tag1: "1",
            tag2: "",
            note: null,
            sync_device: 1,
            primaryTarget: {
              target: "HR",
              value: "1",
            },
            secondaryTarget: null,
            sequence: 1,
          },
        ],
      },
    ],
  },
];

export const BrickStepsData = [{}, {}];
