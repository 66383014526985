import React, { useContext, useEffect, useState } from "react";
import RepeatIcon from "assets/icons/repeat_icon.svg";
import { CalendarData } from "utility/constants/useContext";
import RunIcon from "assets/icons/RunIcon.svg";
import SwimICon from "assets/icons/SwimIcon.svg";
import BikeIcon from "assets/icons/BikeIcon.svg";
import { Header } from "api/Signin";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import axios from "axios";
import {
  ZonesShortToFull,
  activityNameShortToFull,
  StrokeTypeShortToFull,
  EquipmentTypeShortToFull,
  ZonesFullToShort,
  formatTime,
  convertMetersToKilometers,
} from "hooks/convertValues";

function TrainingSlider() {
  const Props = useContext(CalendarData);
  const stepsData = Props.stepsData;
  const [Zones, setZones] = useState();

  useEffect(() => {
    const getZones = async () => {
      const header = Header();
      const ref =
        localStorage.getItem("ref_id") ?? sessionStorage.getItem("ref_id");
      try {
        const res = await axios.get(API_URL_CONSTANTS.GET_ZONES, {
          headers: header,
          params: {
            ref: ref,
          },
        });

        if (res.status === 200) {
          setZones(res.data);
        } else {
          console.error(`Unexpected status code: ${res.status}`);
        }
      } catch (error) {
        console.error("Error fetching zones:", error);
      }
    };
    getZones();
  }, []);

  // get zones like Z1(189-200)
  function convertZones(workout_category, target_type, level) {
    const target = ZonesFullToShort(target_type);
    let workoutType;
    if (workout_category) {
      workoutType = workout_category.toUpperCase();
    }

    if (target === "HR" || target === "PZ") {
      const Level = parseInt(level, 10);
      const matchedCategory = Zones.content.find(
        (zone) =>
          zone.workout_category === workoutType && zone.target_type === target
      );

      // If a matching category is found, proceed to search for the level
      if (matchedCategory) {
        const matchedLevel = matchedCategory.zones.find(
          (zone) => zone.level === Level
        );

        // If a matching level is found, create the name and limit values
        if (matchedLevel) {
          const name = `${matchedLevel.name}`;
          const limit = `(${matchedLevel.lower_limit} - ${matchedLevel.upper_limit})`;
          return { name, limit };
        }
      }
    } else if (target === "PC") {
      const [minF, minS] = level.split("~").map(parseFloat);

      // Convert minF and minS
      const dividedMinF = 1000 / minF;
      const dividedMinS = 1000 / minS;

      // Process dividedMinF
      const minFMinutes = Math.floor(dividedMinF / 60); // Integer part for minutes
      const minFSeconds = Math.round(dividedMinF % 60); // Remainder for seconds
      const minFFormatted =
        minFSeconds < 10
          ? `${minFMinutes}:0${minFSeconds}`
          : `${minFMinutes}:${minFSeconds}`;

      // Process dividedMinS
      const minSMinutes = Math.floor(dividedMinS / 60); // Integer part for minutes
      const minSSeconds = Math.round(dividedMinS % 60); // Remainder for seconds
      const minSFormatted =
        minSSeconds < 10
          ? `${minSMinutes}:0${minSSeconds}`
          : `${minSMinutes}:${minSSeconds}`;

      // Use the formatted values
      const limit = `${minFFormatted} to ${minSFormatted}`;
      const name = "";
      return { name, limit };
    }

    // Return null if no match is found
    return null;
  }

  // Check if stepsData and stepsData.steps are defined before rendering
  if (!stepsData || !stepsData.steps) {
    return null;
    // <div className="min-w-[20%] min-h-full overflow-hidden z-20">
    //   <div className="flex flex-col h-full">
    //     <div className="calendar_border bg-[#F0F4F8] w-full h-full p-2 pr-6"></div>
    //   </div>
    // </div> // we should  return some  UI if data is not available (ask Maam)
  }

  if (Props.click && Props.refId) {
    return (
      <>
        <div className="min-w-[20%] min-h-full overflow-hidden z-20 py-8">
          <div className="flex flex-col h-full">
            <div className="calendar_border bg-[#F8F8F8] w-full h-full p-4">
              <div className="flex flex-col">
                <p className="text-right text-[var(--black)]">
                  {Props.trainigSliderDate}
                </p>
                <div className="flex items-center mt-2 text-[var(--black)] text-left text-[1.1rem]">
                  <div className="mr-1 ">
                    {stepsData.workout_type === "RUN" && (
                      <img className="h-4" src={RunIcon} alt="" />
                    )}
                    {stepsData.workout_type === "SWIM" && (
                      <img className="h-4" src={SwimICon} alt="" />
                    )}
                    {stepsData.workout_type === "BIKE" && (
                      <img className="h-4" src={BikeIcon} alt="" />
                    )}
                  </div>

                  <p>{stepsData.name}</p>
                  <span className="text-[var(--grey)]">#tof</span>
                </div>

                <div>
                  {stepsData?.notes && (
                    <p className="text-[var(--blue)]">
                      Notes:{" "}
                      <span className="text-[var(--light-blue)]">
                        {stepsData?.notes}
                      </span>
                    </p>
                  )}
                </div>

                {/* Training Steps Start */}
                {stepsData.steps.map((steps, index) => {
                  const isLastObject = stepsData.steps.length;

                  return (
                    <div key={`repeatsssss--${index}`}>
                      <div className="flex flex-col w-full bg-[var(--white)] border_top_radius calendar_border mt-2 pb-2">
                        {steps.no_of_repeats > 1 ? (
                          <>
                            {index % 2 === 0 ? (
                              <div className="flex items-center bg-[#b2f7ef]  mb-2 border_top_radius px-1">
                                <img className="h-3" src={RepeatIcon} alt="" />
                                <p>{steps.no_of_repeats}</p>
                              </div>
                            ) : (
                              <div className="flex items-center bg-[#d1f7f3]  mb-2 border_top_radius px-1">
                                <img className="h-3" src={RepeatIcon} alt="" />
                                <p>{steps.no_of_repeats}</p>
                              </div>
                            )}
                          </>
                        ) : null}

                        {/* Use this map for card info in the box e.g., wup, z1, time, distance */}
                        {steps.activity_steps &&
                          steps.activity_steps.map(
                            (ActivitySteps, ActivityStepsIndex) => {
                              const formattedActivity = activityNameShortToFull(
                                ActivitySteps.activity
                              );

                              let PconvertTarget = ZonesShortToFull(
                                ActivitySteps?.primaryTarget?.target
                              );

                              let SconvertTarget = ZonesShortToFull(
                                ActivitySteps?.secondaryTarget?.target
                              );

                              let PrimaryTarget =
                                ActivitySteps?.primaryTarget?.target;
                              let PrimaryValue =
                                ActivitySteps?.primaryTarget?.value;

                              let SecondaryTarget =
                                ActivitySteps?.secondaryTarget?.target;
                              let SecondaryValue =
                                ActivitySteps?.secondaryTarget?.value;

                              const capWorkoutType = stepsData.workout_type;

                              const PconvertedZones = convertZones(
                                capWorkoutType,
                                PrimaryTarget,
                                PrimaryValue
                              );

                              const SconvertedZones = convertZones(
                                capWorkoutType,
                                SecondaryTarget,
                                SecondaryValue
                              );

                              let res;
                              let km;
                              if (ActivitySteps.distance) {
                                km = convertMetersToKilometers(
                                  ActivitySteps.distance
                                );
                              }
                              if (
                                ActivitySteps.time &&
                                ActivitySteps.time.includes(":")
                              ) {
                                res = formatTime(ActivitySteps.time);
                              }

                              const equipment = EquipmentTypeShortToFull(
                                ActivitySteps.equipmentType
                              );

                              const stroke = StrokeTypeShortToFull(
                                ActivitySteps.strokeType
                              );

                              return (
                                <div key={`activeStepss-${ActivityStepsIndex}`}>
                                  {/* Card heading color */}

                                  {ActivitySteps.activity === "WUP" ? (
                                    <div className="bg-[#FE5F55] py-[.2rem] mb-2 border_top_radius"></div>
                                  ) : (
                                    <>
                                      {ActivitySteps.activity === "CD" ? (
                                        <div className="bg-[#bfd200] py-[.2rem] mb-2 border_top_radius"></div>
                                      ) : (
                                        <>
                                          {steps.no_of_repeats === 1 ? (
                                            <>
                                              {index % 2 === 0 ? (
                                                <div className=" bg-[#b2f7ef] py-[.2rem] mb-2 border_top_radius"></div>
                                              ) : (
                                                <div className=" bg-[#d1f7f3]  py-[.2rem] mb-2 border_top_radius"></div>
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}

                                  {/* Workout steps  */}
                                  <div className="flex justify-between w-full px-1 text-[var(--blue)] font-medium flex-warp">
                                    <span className="px-1">
                                      {formattedActivity ||
                                        ActivitySteps.activity}
                                    </span>

                                    {/* Ensure to safely access properties */}

                                    {stepsData?.workout_type === "SWIM" ? (
                                      <div className=" px-1 flex flex-col">
                                        <div>
                                          {ActivitySteps.equipmentType ? (
                                            <>
                                              <span>
                                                {equipment === "None"
                                                  ? ""
                                                  : equipment ||
                                                    ActivitySteps.equipmentType}
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div>
                                          {ActivitySteps.strokeType ? (
                                            <>
                                              <span>
                                                {stroke === "Any Stroke" ||
                                                stroke === "None"
                                                  ? ""
                                                  : stroke ||
                                                    ActivitySteps.strokeType}
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className=" px-1 flex flex-col">
                                        <div>
                                          {ActivitySteps.primaryTarget ? (
                                            <>
                                              <span>
                                                {PconvertTarget ||
                                                  ActivitySteps.primaryTarget
                                                    .target}
                                              </span>
                                              <span className="pl-1 text-[.7rem] italic">
                                                {PconvertedZones ? (
                                                  <span>
                                                    {PconvertedZones.name}
                                                    <span>
                                                      {PconvertedZones.limit}
                                                    </span>
                                                  </span>
                                                ) : (
                                                  ActivitySteps.primaryTarget
                                                    .value
                                                )}
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        <div>
                                          {ActivitySteps.secondaryTarget ? (
                                            <>
                                              <span>
                                                {SconvertTarget ||
                                                  ActivitySteps.secondaryTarget
                                                    .target}
                                              </span>
                                              <span className="pl-1 text-[.7rem] italic">
                                                {SconvertedZones ? (
                                                  <span>
                                                    {SconvertedZones.name}
                                                    <span>
                                                      {SconvertedZones.limit}
                                                    </span>
                                                  </span>
                                                ) : (
                                                  ActivitySteps.secondaryTarget
                                                    .value
                                                )}
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    )}

                                    {/* Distance and time */}
                                    <div className="flex flex-col items-end px-1">
                                      {ActivitySteps.time && (
                                        <span>
                                          {res || ActivitySteps.time || ""}
                                        </span>
                                      )}
                                      {ActivitySteps.distance > 0 && (
                                        <>
                                          <span className="text-right">
                                            {km || ActivitySteps.distance}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  );
                })}
                {/* Training Steps End */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null; // Return null if Props.click and Props.refId are not defined
}

export default TrainingSlider;
