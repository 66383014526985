import React, { useState, useContext, useEffect } from "react";
import AddIcon from "assets/icons/add.svg";
import EditIcon from "assets/icons/editIcon.svg";
import WarmupIcon from "assets/icons/warmup.svg";
import TempoIcon from "assets/icons/Tempo.svg";
import RepeatsIcon from "assets/icons/repeats.svg";
import CoolDownIcon from "assets/icons/coolDown.svg";
import StepsBox from "./components/StepsBox";
import WorkoutTemplates from "./components/WorkoutTemplates";
import CancelIcon from "assets/img/redCancel.png";
import Graph from "./components/Graph";
import { CoachCalendarData } from "utility/constants/useContext";
import {
  AddMultiWorkoutStpes,
  AddStepsData,
  EditStepsData,
} from "api/CoachCalendar";
import ViewStepsSection from "./components/editWorkout/ViewStepsSection";
import DisabledWup from "assets/icons/disabled_wup.svg";
import DisabledCd from "assets/icons/disabled_cd.svg";
import { format, getISODay } from "date-fns";
import MultiWorkoutSteps from "./components/editWorkout/MultiWorkoutSteps";
import ErrorPopup from "components/popups/ErrorPopup";

function MultiWorkout({ setSubmitSteps, submitSteps }) {
  const [isNameEdit, setNameEdit] = useState(false);
  const Props = useContext(CoachCalendarData);
  const [WorkoutType, setWorkoutType] = useState(
    Props.submitSteps?.workout_type
  );
  const [notes, setNotes] = useState(
    Props.submitSteps.notes ? Props.submitSteps.notes : ""
  );
  const [workoutName, setWorkoutName] = useState(
    Props.newWorkoutSelected
      ? Props.submitSteps?.long_name
      : Props.submitSteps?.name
  );
  // use have to select all the swim, run, bike button
  const [runTrue, setRunTrue] = useState(false); // this is used to disable save button
  const [bikeTrue, setBikeTrue] = useState(false); // this is used to disable save button

  const disabled = !(runTrue && bikeTrue);

  const [errorPopup, setErrorPopup] = useState(false); // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (submitSteps.workout_type) {
      const res = CapToSmall(submitSteps.workout_type);

      setWorkoutType(res);
    }
  }, [submitSteps.workout_type]);

  const stepsData = [
    {
      id: 1,
      name: "Warmup",
      icon: WarmupIcon,
      disabledIcon: DisabledWup,
      steps: {
        sequence: 1,
        no_of_repeats: 1,
        activity_steps: [
          {
            time: Props.submitSteps.workout_type === "SWIM" ? null : "00:10:00",
            distance: Props.submitSteps.workout_type === "SWIM" ? 100.0 : 0.0,
            activity: "WUP",
            sync_device: 1,
            sequence: 1,
          },
        ],
      },
    },
    {
      id: 2,
      name: "Step",
      icon: TempoIcon,
      disabledIcon: TempoIcon,
      steps: {
        sequence: 1,
        no_of_repeats: 1,
        activity_steps: [
          {
            time: Props.submitSteps.workout_type === "SWIM" ? null : "00:10:00",
            distance: Props.submitSteps.workout_type === "SWIM" ? 100.0 : 0.0,
            activity: WorkoutType,
            note: null,
            sync_device: 1,
            sequence: 1,
          },
        ],
      },
    },
    {
      id: 3,
      name: "Repeats",
      icon: RepeatsIcon,
      disabledIcon: RepeatsIcon,
      steps: {
        sequence: 1,
        no_of_repeats: 2,
        activity_steps: [
          {
            time: Props.submitSteps.workout_type === "SWIM" ? null : "00:10:00",
            distance: Props.submitSteps.workout_type === "SWIM" ? 100.0 : 0.0,
            activity: WorkoutType,
            note: "",
            sync_device: 1,
            sequence: 2,
          },
          {
            time: Props.submitSteps.workout_type === "SWIM" ? null : "00:10:00",
            distance: Props.submitSteps.workout_type === "SWIM" ? 100.0 : 0.0,
            activity:
              Props.submitSteps.workout_type === "SWIM" ? "RST" : WorkoutType,
            note: "",
            sync_device: 1,
            sequence: 3,
          },
        ],
      },
    },
    {
      id: 4,
      name: "CoolDown",
      icon: CoolDownIcon,
      disabledIcon: DisabledCd,
      steps: {
        sequence: 1,
        no_of_repeats: 1,
        activity_steps: [
          {
            time: Props.submitSteps.workout_type === "SWIM" ? null : "00:10:00",
            distance: Props.submitSteps.workout_type === "SWIM" ? 100.0 : 0.0,
            activity: "CD",
            sync_device: 1,
            sequence: 1,
          },
        ],
      },
    },
  ];

  useEffect(() => {
    if (Props.editWorkoutClicked && !Props.newWorkoutSelected) {
      setSubmitSteps((prevSteps) => {
        return {
          ...prevSteps, // Retain the rest of the state
          id: Props.refId,
        };
      });
    }
  }, [Props.editWorkoutClicked]);

  // update calendar list
  function updateCalendarList(workout_detail) {
    // Map through each workout detail and update its structure
    const updatedWorkoutDetails = workout_detail.map((workout) => ({
      id: workout.id,
      label: workout.label,
      workout_type: workout.workout_type,
      brief_information: workout.brief_information,
      synced_with_device: workout.synced_with_device,
      day: workout.day,
      is_completed: workout.activity_status,
      calender_event_type: 1, // Assuming this is a static value
      is_baseline: workout_detail.is_baseline,
      is_singlestep_dist: workout_detail.is_singlestep_dist,
    }));

    const formattedDate = format(new Date(Props.eventDate), "dd-MM-yyyy");
    const updatedCalendarData = { ...Props.calendarData }; // Create a copy

    updatedCalendarData.day_workouts = updatedCalendarData.day_workouts.map(
      (day) => {
        if (day.workout_date === formattedDate) {
          return {
            ...day,
            workouts: [...day.workouts, ...updatedWorkoutDetails], // Append all updated workouts
          };
        }
        return { ...day }; // Return day as is if the date doesn't match
      }
    );

    Props.setCalendarData(updatedCalendarData);
  }

  async function handleSave(e) {
    e.preventDefault();
    const formattedDate = format(new Date(Props.eventDate), "dd-MM-yyyy");
    const dayOfWeek = getISODay(new Date(Props.eventDate));
    const UpperCaseWorkoutType = Props.brickSelectionOne ? "BRICK " : " TRI";

    const res = await AddMultiWorkoutStpes(
      Props.athleteId,
      Props.submitMultiSteps,
      formattedDate,
      dayOfWeek,
      notes,
      UpperCaseWorkoutType
    );

    if (res.success === true) {
      updateCalendarList(res.data.data.workout_detail);
      // Props.setUpdateCalendar(res); // updates the calendar
      Props.setEditWorkout(false); // sets edit page to false
      Props.setClick(false); // sets right side silder to false
      Props.setSubmitSteps({}); // resets submitsteps to empty
      Props.setSubmitMultiSteps([]); // resets submitMultiSteps to empty
      Props.setAddWorkout(false); // addwrkout silder to false
      Props.setNewWorkoutSelected(false); // check if new workout is selected or not
      Props.setmultiWorkout(""); // resets multiworkout to empty
      setRunTrue(false);
      setBikeTrue(false);
      Props.setBrickSelectionOne(""); // brick seleted value
      Props.setBrickSelectionTwo("");
    } else {
      setErrorPopup(true);
      setErrorHeader("Something went wrong!");
      setErrorMessage(res?.data || "Unable to Add Workout");
    }
  }

  function CapToSmall(activity) {
    switch (activity) {
      case "RUN":
        return "Run";
      case "BIKE":
        return "Bike";
      case "SWIM":
        return "Swim";
      default:
        return activity;
    }
  }

  function handleAddName() {
    setNameEdit(false);
    setSubmitSteps((prev) => {
      return {
        ...prev,

        name: workoutName,
      };
    });
  }

  return (
    <div className="pt-16 w-[80%] mx-auto  ">
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}
      {/* --------------------------- header -----------------------*/}
      <div className="fixed top-[4rem] w-[80%] border-b border-[#DBDBDB] pb-2 bg-white z-50">
        <div className="flex flex-col py-2">
          <div className="flex">
            {isNameEdit ? (
              <div className="flex  items-center gap-4">
                <input
                  type="text"
                  placeholder="Workout Name"
                  value={workoutName}
                  onChange={(e) => setWorkoutName(e.target.value)}
                  className="flex-1 w-[30%] border border-gray-300 rounded px-3 py-2 focus:outline-none"
                />

                <div className="flex">
                  <img
                    className=" h-5 w-5 cursor-pointer mr-2"
                    src={CancelIcon}
                    alt=""
                    onClick={() => {
                      setNameEdit(false);
                      setNotes("");
                    }}
                  />
                  <img
                    className=" h-5 w-5 cursor-pointer"
                    src={AddIcon}
                    alt=""
                    onClick={() => handleAddName()}
                  />
                </div>
              </div>
            ) : (
              <div className="flex">
                <h3 className="text-[var(--blue)]">{workoutName} #tof</h3>
                <img
                  src={EditIcon}
                  className="ml-2 cursor-pointer"
                  alt="editIcon"
                  onClick={() => setNameEdit(true)}
                />
              </div>
            )}
          </div>

          <span>{Props.trainigSliderDate}</span>
        </div>
        <div className="flex flex-wrap justify-between items-center">
          <div>
            <MultiWorkoutSteps
              notes={notes}
              WorkoutType={WorkoutType}
              setRunTrue={setRunTrue}
              setBikeTrue={setBikeTrue}
              setWorkoutType={setWorkoutType}
            />
          </div>
          <div className="flex justify-end pt-4">
            <button
              className="cancelButton "
              onClick={() => {
                Props.setEditWorkout(false);
                Props.setClick(false);
                Props.setSubmitSteps({});
                Props.setmultiWorkout("");
                setRunTrue(false);
                setBikeTrue(false);
                Props.setBrickSelectionOne(""); // brick seleted value
                Props.setBrickSelectionTwo("");
                Props.setSubmitMultiSteps([]);
              }}
            >
              Cancel
            </button>
            <button
              disabled={disabled}
              onClick={(e) => handleSave(e)}
              className="saveButton"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div className="flex pt-[11rem] pb-12">
        <div className="flex ">
          {/*----------------- left section steps box, label box, graph ------------------*/}
          <div className="flex flex-col  overflow-y-scroll no-scrollbar ">
            <StepsBox stepsData={stepsData} />
            <WorkoutTemplates WorkoutType={WorkoutType} />
            <Graph />
          </div>
        </div>
        <div className="flex flex-col    w-full ml-4  overflow-y-scroll no-scrollbar">
          <ViewStepsSection
            setSubmitSteps={setSubmitSteps}
            submitSteps={submitSteps}
            WorkoutType={WorkoutType}
            notes={notes}
            setNotes={setNotes}
            stepsData={stepsData}
          />
        </div>
      </div>
    </div>
  );
}

export default MultiWorkout;
