import React, { useEffect, useRef, useState } from "react";

import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  formatDate,
} from "date-fns";
import RightArrow from "assets/icons/right-arrow.png";
import MonthIcon from "assets/icons/month_icon.svg";
import WeekIcon from "assets/icons/week_icon.svg";
import MonthView from "../../components/calendar/compo/MonthView";
import TrainingSlider from "../../components/calendar/compo/TrainingSlider";
import PhoneView from "components/calendar/compo/PhoneView";
import { CalendarData } from "utility/constants/useContext";
import SEO from "hooks/Seo";
import { HelmetProvider } from "react-helmet-async";
import { getApis } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
function TrainingCalendar() {
  const currentDate = new Date();
  const oneYearLater = addMonths(currentDate, 12); // Current date + 1 year
  const [FromDate, setFromDate] = useState();
  const [ToDate, setToDate] = useState();
  const [click, setClick] = useState(false);
  const [mView, setMview] = useState(true);
  const [refId, setRefId] = useState();
  const [calendarData, setCalendarData] = useState([]);
  const [stepsData, setStpesData] = useState([]);
  const [trainigSliderDate, setTrainingSilderDate] = useState("");
  const [currentMonth, setCurrentMonth] = useState(startOfMonth(currentDate));

  // get  calendar data from api
  useEffect(() => {
    const fetchCalendarList = async () => {
      // Calculate the start and end dates of the current month
      const startDate = startOfMonth(currentMonth);
      const endDate = addMonths(startOfMonth(currentMonth), 1); // 1st of next month

      // Format the dates in "dd-MM-yyyy" format
      const fromDate = format(startDate, "dd-MM-yyyy");
      const toDate = format(endDate, "dd-MM-yyyy");
      const res = await getApis(
        API_URL_CONSTANTS.GET_USER_CALENDAR_LIST + `${fromDate}&to=${toDate}`
      );
      // const res = await getApis(API_URL_CONSTANTS.GET_USER_CALENDAR_LIST);

      if (res && !res.error) {
        if (res.data.status_code === 1) {
          setCalendarData(res.data.day_workouts);
        } else if (res.data.status_code === 2) {
        }
      } else {
        alert("Error fetching calendar list:", res.error);
      }
    };

    fetchCalendarList();
  }, [currentMonth]);

  // UseContext object
  const calendarPoprs = {
    click,
    setClick,
    mView,
    setMview,
    calendarData,
    refId,
    setRefId,
    stepsData,
    setStpesData,
    FromDate,
    setFromDate,
    ToDate,
    setToDate,
    trainigSliderDate,
    setTrainingSilderDate,
    currentMonth,
    setCurrentMonth,
  };

  const currentDay = new Date();

  const handleNextMonth = () => {
    const nextMonth = addMonths(currentMonth, 1);
    if (nextMonth <= oneYearLater) {
      setCurrentMonth(nextMonth);
    }
  };

  const handlePrevMonth = () => {
    const prevMonth = subMonths(currentMonth, 1);
    if (prevMonth >= startOfMonth(currentDate)) {
      setCurrentMonth(prevMonth);
    }
  };

  return (
    <HelmetProvider>
      <SEO
        title="Calendar | Trackofit"
        descriptionName="Calendar"
        description="Training Calendar"
        canonicalUrl="https://www.trackofit.com"
      />
      <CalendarData.Provider value={calendarPoprs}>
        {/* Close Steps slider  */}
        {click && (
          <div
            className="fixed w-full h-[100vh] bg-transparent z-10"
            onClick={() => setClick(false)}
          ></div>
        )}

        {/* PHONE CALENDAR */}
        <div className="phone_calendar">
          <PhoneView />
        </div>
        {/* DEKSTOP CALENDAR */}
        <div className="pt-[4rem] md:hidden">
          <div className={`flex mx-auto my-0 ${click ? "w-full" : "w-[full"}`}>
            {/* <div className="w-20 h-auto bg-[#000000]"></div> */}
            {/* Calendar div with week and month selector */}
            <div className="flex flex-col w-full px-[1rem] py-8">
              <div className="flex justify-between mb-3">
                <div className="flex items-center border-[#DBDBDB] border-[.5px] border-solid p-1 rounded-[3px]">
                  {/* Month toggel */}
                  <div className="flex items-center">
                    <button
                      className=""
                      onClick={handlePrevMonth}
                      disabled={currentMonth <= startOfMonth(currentDate)} // Disable if the current month is the first month
                    >
                      <img
                        className="rotate-180 w-3 h-3"
                        src={RightArrow}
                        alt=""
                      />
                    </button>
                    <p className=" px-4">{format(currentMonth, "MMMM yyyy")}</p>
                    <button
                      onClick={handleNextMonth}
                      disabled={currentMonth >= oneYearLater} // Disable if the current month is beyond the limit
                    >
                      <img className="w-3 h-3" src={RightArrow} alt="" />
                    </button>
                  </div>
                </div>
                <div className=" flex items-center">
                  <div
                    className={`flex items-center gap-2 border-[#DBDBDB] border-[.5px] border-solid p-1 rounded-[3px] mr-2 cursor-pointer ${
                      mView ? " !border-[#2260FF]" : ""
                    }`}
                    // className="flex items-center gap-2 border-[#DBDBDB] border-[.5px] border-solid p-1 rounded-[3px] mr-2 cursor-pointer"
                    onClick={() => {
                      setMview(true);
                    }}
                  >
                    <img className="h-3" src={MonthIcon} alt="" />
                    <span>Month</span>
                  </div>
                  <div
                    className={`flex items-center gap-2 border-[#DBDBDB] border-[.5px] border-solid p-1 rounded-[3px]  cursor-pointer ${
                      mView ? "" : " !border-[#2260FF]"
                    }`}
                    onClick={() => {
                      setMview(false);
                    }}
                  >
                    <img className="h-3" src={WeekIcon} alt="" />
                    <span>Week</span>
                  </div>
                </div>
              </div>
              <MonthView />
            </div>
            {/* SLIDER  */}

            <TrainingSlider />
          </div>
        </div>
      </CalendarData.Provider>
    </HelmetProvider>
  );
}

export default TrainingCalendar;
