import React, { useState, useContext, useEffect } from "react";
import AddIcon from "assets/icons/add.svg";
import EditIcon from "assets/icons/editIcon.svg";
import WarmupIcon from "assets/icons/warmup.svg";
import TempoIcon from "assets/icons/Tempo.svg";
import RepeatsIcon from "assets/icons/repeats.svg";
import CoolDownIcon from "assets/icons/coolDown.svg";
import StepsBox from "./components/StepsBox";
import WorkoutTemplates from "./components/WorkoutTemplates";
import CancelIcon from "assets/img/redCancel.png";
import Tick from "assets/icons/CoachCalendar/tick.svg";
import Graph from "./components/Graph";
import { CoachCalendarData } from "utility/constants/useContext";
import { AddStepsData, EditStepsData } from "api/CoachCalendar";
import ViewStepsSection from "./components/editWorkout/ViewStepsSection";
import DisabledWup from "assets/icons/disabled_wup.svg";
import DisabledCd from "assets/icons/disabled_cd.svg";
import ErrorPopup from "components/popups/ErrorPopup";
import { format, getISODay } from "date-fns";

function EditWorkout({ setSubmitSteps, submitSteps }) {
  const [isNameEdit, setNameEdit] = useState(false);
  const Props = useContext(CoachCalendarData);
  const [WorkoutType, setWorkoutType] = useState(
    Props.submitSteps.workout_type
  );
  const [notes, setNotes] = useState(Props.submitSteps.notes);
  const [workoutName, setWorkoutName] = useState(
    Props.newWorkoutSelected
      ? Props.submitSteps.long_name
      : Props.submitSteps.name
  );

  const [errorPopup, setErrorPopup] = useState(false); // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (submitSteps.workout_type) {
      const res = CapToSmall(submitSteps.workout_type);
      setWorkoutType(res);
    }
  }, [submitSteps.workout_type]);

  const stepsData = [
    {
      id: 1,
      name: "Warmup",
      icon: WarmupIcon,
      disabledIcon: DisabledWup,
      steps: {
        sequence: 1,
        no_of_repeats: 1,
        activity_steps: [
          {
            time: Props.submitSteps.workout_type === "SWIM" ? null : "00:10:00",
            distance: Props.submitSteps.workout_type === "SWIM" ? 100.0 : 0.0,
            activity: "WUP",
            sync_device: 1,

            sequence: 1,
          },
        ],
      },
    },
    {
      id: 2,
      name: "Step",
      icon: TempoIcon,
      disabledIcon: TempoIcon,
      steps: {
        sequence: 1,
        no_of_repeats: 1,
        activity_steps: [
          {
            time: Props.submitSteps.workout_type === "SWIM" ? null : "00:10:00",
            distance: Props.submitSteps.workout_type === "SWIM" ? 100.0 : 0.0,
            activity: WorkoutType,
            note: null,
            sync_device: 1,
            sequence: 1,
          },
        ],
      },
    },
    {
      id: 3,
      name: "Repeats",
      icon: RepeatsIcon,
      disabledIcon: RepeatsIcon,
      steps: {
        sequence: 1,
        no_of_repeats: 2,
        activity_steps: [
          {
            time: Props.submitSteps.workout_type === "SWIM" ? null : "00:10:00",
            distance: Props.submitSteps.workout_type === "SWIM" ? 100.0 : 0.0,
            activity: WorkoutType,
            note: "",
            sync_device: 1,
            sequence: 2,
          },
          {
            time: Props.submitSteps.workout_type === "SWIM" ? null : "00:10:00",
            distance: Props.submitSteps.workout_type === "SWIM" ? 100.0 : 0.0,
            activity:
              Props.submitSteps.workout_type === "SWIM" ? "Rest" : WorkoutType,
            note: "",
            sync_device: 1,
            sequence: 3,
          },
        ],
      },
    },
    {
      id: 4,
      name: "CoolDown",
      icon: CoolDownIcon,
      disabledIcon: DisabledCd,
      steps: {
        sequence: 1,
        no_of_repeats: 1,
        activity_steps: [
          {
            time: Props.submitSteps.workout_type === "SWIM" ? null : "00:10:00",
            distance: Props.submitSteps.workout_type === "SWIM" ? 100.0 : 0.0,
            activity: "CD",
            sync_device: 1,
            sequence: 1,
          },
        ],
      },
    },
  ];

  useEffect(() => {
    if (Props.editWorkoutClicked && !Props.newWorkoutSelected) {
      setSubmitSteps((prevSteps) => {
        return {
          ...prevSteps, // Retain the rest of the state
          id: Props.refId,
        };
      });
    }
  }, [Props.editWorkoutClicked]);

  // Update Sequence
  function updateSequence(data) {
    let count = 1;

    // Map through the steps array and update sequences
    data.steps = data?.steps.map((step) => {
      // Update the step's sequence
      step.sequence = count;

      // Handle no_of_repeats logic
      if (step.no_of_repeats > 1) {
        step.no_of_repeats = step.activity_steps.length;
        count += 1;
        for (let i = 1; i < step.no_of_repeats; i++) {
          step.activity_steps.forEach((activityStep) => {
            activityStep.sequence = count;
            count += 1;
          });
        }
      } else {
        // Map through the activity_steps array within the step
        step.activity_steps = step.activity_steps.map((activityStep) => {
          activityStep.sequence = count;
          count += 1; // Increment count for each activity step
          return activityStep;
        });
      }

      return step;
    });

    return data;
  }

  const addTimeAndDistance = () => {
    let totalSeconds = 0;
    let totalDistanceSum = 0.0;

    // Ensure Props.submitSteps and steps array exist
    if (Props.submitSteps?.steps) {
      Props.submitSteps.steps.forEach((step) => {
        if (step?.activity_steps) {
          step.activity_steps.forEach((activity) => {
            // Parse activity time if it exists
            if (activity?.time) {
              const [hours, minutes, seconds] = activity.time
                .split(":")
                .map((value) => parseInt(value) || 0);
              totalSeconds += hours * 3600 + minutes * 60 + seconds;
            }

            // Parse activity distance if it exists
            totalDistanceSum += parseFloat(activity?.distance) || 0.0;
          });
        }
      });
    }

    // Convert total seconds back to "hh:mm:ss" format
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedTime = [
      hours.toString().padStart(2, "0"), // Ensure two-digit hours
      minutes.toString().padStart(2, "0"), // Ensure two-digit minutes
      seconds.toString().padStart(2, "0"), // Ensure two-digit seconds
    ].join(":");

    // Set formattedTime to null if it equals "00:00:00"
    const resultTime = formattedTime === "00:00:00" ? null : formattedTime;

    return { formattedTime: resultTime, totalDistanceSum };
  };

  function updateCalendarList(workout_detail) {
    if (!Props.newWorkoutSelected) {
      const updatedCalendarData = { ...Props.calendarData }; // Create a  copy

      updatedCalendarData.day_workouts = updatedCalendarData.day_workouts.map(
        (day) => {
          const updatedWorkouts = day.workouts.map((workout) => {
            if (workout.id === workout_detail.id) {
              // Replace the workout
              return { ...workout, ...workout_detail };
            }
            return workout; // Return unchanged workout
          });

          return { ...day, workouts: updatedWorkouts }; // Return updated wrkout
        }
      );

      Props.setCalendarData(updatedCalendarData);
    } else {
      const updateWorkout_detail = {
        id: workout_detail.id,
        label: workout_detail.label,
        workout_type: workout_detail.workout_type,
        brief_information: workout_detail.brief_information,
        synced_with_device: workout_detail.synced_with_device,
        day: workout_detail.day,
        is_completed: workout_detail.activity_status,
        calender_event_type: 1,
        is_baseline: workout_detail.is_baseline,
        is_singlestep_dist: workout_detail.is_singlestep_dist,
      };
      const formattedDate = format(new Date(Props.eventDate), "dd-MM-yyyy");
      const updatedCalendarData = { ...Props.calendarData }; // Create a  copy
      updatedCalendarData.day_workouts = updatedCalendarData.day_workouts.map(
        (day) => {
          if (day.workout_date === formattedDate) {
            return {
              ...day,
              workouts: [...day.workouts, updateWorkout_detail],
            };
          }

          return { ...day }; // Return updated wrkout
        }
      );

      Props.setCalendarData(updatedCalendarData);
    }
  }

  async function handleSave(e) {
    e.preventDefault();
    const UpdatedSubmitSteps = updateSequence(Props.submitSteps);
    const timeAndDistance = addTimeAndDistance();
    const time =
      timeAndDistance.formattedTime === "00.00.00"
        ? null
        : timeAndDistance.formattedTime;

    const distance = timeAndDistance.totalDistanceSum.toFixed(1);
    const formattedDate = format(new Date(Props.eventDate), "dd-MM-yyyy");
    const dayOfWeek = getISODay(new Date(Props.eventDate));
    const UpperCaseWorkoutType = WorkoutType.toUpperCase();
    const name = CapToSmall(UpdatedSubmitSteps.workout_type);

    if (!Props.newWorkoutSelected) {
      const res = await EditStepsData(
        Props.athleteId,
        Props.refId,
        UpdatedSubmitSteps.steps,
        formattedDate,
        dayOfWeek,
        UpperCaseWorkoutType,
        notes,
        name,
        UpdatedSubmitSteps.sync_device,
        time,
        distance,
        Props.isBaseline
      );
      if (res.success === true) {
        updateCalendarList(res.data);
        Props.setEditWorkout(false);
        Props.setClick(false);
        Props.setSubmitSteps({});
        Props.setAddWorkout(false);
        Props.setNewWorkoutSelected(false);
        Props.setmultiWorkout("");
        Props.setBaseline(false);
      } else {
        setErrorPopup(true);
        setErrorHeader("Something went wrong!");
        setErrorMessage(res?.data || "Unable to Add Workout");
      }
    } else {
      const res = await AddStepsData(
        Props.athleteId,
        UpdatedSubmitSteps.steps,
        formattedDate,
        dayOfWeek,
        UpperCaseWorkoutType,
        notes,
        UpdatedSubmitSteps.short_name,
        UpdatedSubmitSteps.sync_device,
        time,
        distance,
        Props.isBaseline
      );

      if (res.success === true) {
        updateCalendarList(res.data.data.workout_detail);
        Props.setEditWorkout(false);
        Props.setClick(false);
        Props.setSubmitSteps({});
        Props.setAddWorkout(false);
        Props.setNewWorkoutSelected(false);
        Props.setmultiWorkout("");
        Props.setBaseline(false);
      } else {
        setErrorPopup(true);
        setErrorHeader("Something went wrong!");
        setErrorMessage(res?.data || "Unable to Add Workout");
      }
    }
  }

  function CapToSmall(activity) {
    switch (activity) {
      case "RUN":
        return "Run";
      case "BIKE":
        return "Bike";
      case "SWIM":
        return "Swim";
      default:
        return activity;
    }
  }

  function handleAddName() {
    setNameEdit(false);
    setSubmitSteps((prev) => {
      return {
        ...prev,

        name: workoutName,
      };
    });
  }

  return (
    <div className="pt-16 w-[80%] mx-auto  ">
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}
      {/* --------------------------- header -----------------------*/}
      <div className="fixed top-[4rem] w-[80%] border-b border-[#DBDBDB] pb-2 bg-white z-30">
        <div className="flex flex-wrap justify-between items-end">
          <div className="flex flex-col py-2">
            <div className="flex">
              {isNameEdit ? (
                <div className="flex items-center gap-4">
                  <input
                    type="text"
                    placeholder="Workout Name"
                    value={workoutName}
                    onChange={(e) => setWorkoutName(e.target.value)}
                    className="flex-1 w-[30%] border border-gray-300 rounded px-3 py-2 focus:outline-none"
                  />

                  <div className="flex">
                    <img
                      className=" h-5 w-5 cursor-pointer mr-2"
                      src={CancelIcon}
                      alt=""
                      onClick={() => {
                        setNameEdit(false);
                        setNotes("");
                      }}
                    />
                    <img
                      className=" h-5 w-5 cursor-pointer"
                      src={Tick}
                      alt=""
                      onClick={() => handleAddName()}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex">
                  <h3 className="text-[var(--blue)]">{workoutName} #tof</h3>
                  <img
                    src={EditIcon}
                    className="ml-2 cursor-pointer"
                    alt="editIcon"
                    onClick={() => setNameEdit(true)}
                  />
                </div>
              )}
            </div>

            <span>{Props.trainigSliderDate}</span>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex justify-end">
              <button
                className="cancelButton "
                onClick={() => {
                  Props.setEditWorkout(false);
                  // Props.setClick(false);
                  Props.setSubmitSteps({});
                  Props.setmultiWorkout("");
                  Props.setBaseline(false);
                }}
              >
                Cancel
              </button>
              <button onClick={(e) => handleSave(e)} className="saveButton">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex pt-[8.5rem] pb-12">
        {!Props.isBaseline && (
          <div className="flex ">
            {/*----------------- left section steps box, label box, graph ------------------*/}
            <div className="flex flex-col  overflow-y-scroll no-scrollbar ">
              <StepsBox stepsData={stepsData} />
              <WorkoutTemplates WorkoutType={WorkoutType} />
              <Graph />
            </div>
          </div>
        )}

        <div className="flex flex-col    w-full ml-4  overflow-y-scroll no-scrollbar">
          <ViewStepsSection
            setSubmitSteps={setSubmitSteps}
            submitSteps={submitSteps}
            WorkoutType={WorkoutType}
            notes={notes}
            setNotes={setNotes}
            stepsData={stepsData}
          />
        </div>
      </div>
    </div>
  );
}

export default EditWorkout;
