import React, { useContext } from "react";
import { CoachCalendarData } from "utility/constants/useContext";
import AddIcon from "assets/icons/add.svg";

function StepsBox({ stepsData }) {
  const Props = useContext(CoachCalendarData);

  function handleAdd(AddSteps, activityType) {
    // Extract the activity type from activityType array
    const newActivity = activityType[0]?.activity;

    // Helper function to check if an activity already exists
    const isActivityAlreadyPresent = (activity) => {
      return Props.submitSteps.steps.some((step) =>
        step.activity_steps.some((actStep) => actStep.activity === activity)
      );
    };

    if (newActivity === "WUP" || newActivity === "CD") {
      // Prevent adding duplicate "WUP" or "CD"
      if (!isActivityAlreadyPresent(newActivity)) {
        Props.setSubmitSteps((prevSteps) => {
          const updatedSteps =
            newActivity === "WUP"
              ? [AddSteps, ...prevSteps.steps] // Add "WUP" at the start
              : [...prevSteps.steps, AddSteps]; // Add "CD" at the end
          return {
            ...prevSteps,
            steps: updatedSteps,
          };
        });
        Props.scrollToBottom();
      }
    } else {
      // For all other activities, add without restriction
      Props.setSubmitSteps((prevSteps) => {
        const updatedSteps = [...prevSteps.steps, AddSteps]; // Add at the end
        return {
          ...prevSteps,
          steps: updatedSteps,
        };
      });
      Props.scrollToBottom();
    }
  }

  // Helper function to determine if a step should be disabled
  const isDisabled = (activityType) => {
    const activity = activityType?.[0]?.activity; // Safe access
    if (activity === "WUP" || activity === "CD") {
      return (
        Array.isArray(Props.submitSteps?.steps) &&
        Props.submitSteps.steps.some(
          (step) =>
            Array.isArray(step?.activity_steps) &&
            step.activity_steps.some((actStep) => actStep.activity === activity)
        )
      );
    }
    return false;
  };

  return (
    <div>
      <div className="flex flex-col bg-[#F8F8F8] p-2 pb-0 rounded-[3px] ">
        <div className="flex justify-start mb-2">
          <span className="text-[.7rem] text-[var(--blue)]">Steps</span>
        </div>
        <div className="flex flex-col">
          {stepsData?.map((arr, index) => {
            const disabled = isDisabled(arr?.steps?.activity_steps);

            return (
              <div
                key={`steps-${index}`}
                className={`flex bg-white py-2 px-2 mb-2 items-center justify-between  rounded-[3px] ${
                  disabled
                    ? "text-[va(--grey)] cursor-not-allowed"
                    : "hover:bg-[#efefef] cursor-pointer"
                }`}
                onClick={
                  !disabled
                    ? () => handleAdd(arr.steps, arr.steps.activity_steps)
                    : undefined
                }
              >
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    {disabled ? (
                      <img src={arr.disabledIcon} className="mr-4" alt="" />
                    ) : (
                      <img src={arr.icon} className="mr-4" alt="" />
                    )}

                    <span
                      className={`ml-2 ${
                        disabled ? "text-gray-500" : "text-[var(--blue)]"
                      }`}
                    >
                      {arr.name}
                    </span>
                  </div>

                  <div>{!disabled && <img src={AddIcon} alt="" />}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default StepsBox;
