import React, { useContext, useEffect, useState } from "react";
import AddEventIcon from "assets/icons/AddEvent.svg";
import { getApis } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { CoachCalendarData } from "utility/constants/useContext";
import { format } from "date-fns";
import { Header } from "api/Signin";
import { throwError } from "hooks/throwErrors";
import axios from "axios";
import SaveBtn from "components/button/SaveBtn";
import CloseMenu from "assets/icons/CoachCalendar/close_menu (2).svg";

function AddEvent({ trainigSliderDate }) {
  const Props = useContext(CoachCalendarData);

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [distance, setDistance] = useState("");
  const [disvalue, setDisValue] = useState("km");
  const [categoryData, setCategoryData] = useState([]);
  const [load, setLoad] = useState(false);

  let isDisable;

  if (subCategory === "custom") {
    isDisable = !(name && category && subCategory && distance && disvalue);
  } else {
    isDisable = !(name && category && subCategory);
  }

  useEffect(() => {
    const fetchCalendarList = async () => {
      const res = await getApis(API_URL_CONSTANTS.GET_EVENT_CATEGORY);
      if (res && !res.error) {
        setCategoryData(res.data.categories);
      } else {
        alert("Error fetching Event list:", res.error);
      }
    };

    fetchCalendarList();
  }, []);

  const handleAddEvent = async () => {
    const header = Header();
    const formattedDate = format(Props.eventDate, "dd-MM-yyyy");

    if (subCategory === "custom") {
      return axios
        .post(
          API_URL_CONSTANTS.Add_EVENT,
          {
            event_name: name,
            event_date: formattedDate,
            category: category,
            sub_category: subCategory,
            us_ref_id: Props.athleteId,
            distance_unit: disvalue,
            distance_value: distance,
          },
          {
            headers: header,
          }
        )

        .then((response) => {
          setLoad(true);
          if (response.data.status_code === 1) {
            Props.setUpdateCalendar(response);
            setName("");
            setCategory("");
            setSubCategory("");
            setLoad(false);
            Props.setAddEvent(false);
            return true;
          } else {
            Props.setErrorPopup(true);
            Props.setErrorHeader("Something went wrong!");
            Props.setErrorMessage("Unable to Add Event");
            return false;
          }
        })
        .catch((error) => {
          const errorMessage = throwError(error);
          Props.setErrorPopup(true);
          Props.setErrorHeader("Something went wrong!");
          Props.setErrorMessage(errorMessage || "Unable to Delete Workout");
          setLoad(false);
          return false;
        });
    } else {
      return axios
        .post(
          API_URL_CONSTANTS.Add_EVENT,
          {
            event_name: name,
            event_date: formattedDate,
            category: category,
            sub_category: subCategory,
            us_ref_id: Props.athleteId,
          },
          {
            headers: header,
          }
        )

        .then((response) => {
          if (response.data.status_code === 1) {
            Props.setUpdateCalendar(response);
            setName("");
            setCategory("");
            setSubCategory("");
            setLoad(false);
            Props.setAddEvent(false);
            return true;
          } else {
            Props.setErrorPopup(true);
            Props.setErrorHeader("Something went wrong!");
            Props.setErrorMessage("Unable to Add Event");
            return false;
          }
        })
        .catch((error) => {
          const errorMessage = throwError(error);
          Props.setErrorPopup(true);
          Props.setErrorHeader("Something went wrong!");
          Props.setErrorMessage(errorMessage || "Unable to Delete Workout");
          setLoad(false);
          return false;
        });
    }
  };

  // Filter subcategories based on the selected category
  const selectedCategory =
    categoryData.find((item) => item.name === category) || {};

  return (
    <div className="min-w-[25%] min-h-full overflow-hidden z-20 py-8">
      <div className="flex flex-col h-full">
        <div className="calendar_border bg-[#F8F8F8] w-full h-full ">
          <div className="flex justify-between items-center px-4 py-2 cursor-pointer">
            <div
              className="flex items-center"
              onClick={() => Props.setAddEvent(false)}
            >
              <p className="text-[var(--black)] mr-1">Close</p>
              <img src={CloseMenu} alt="" />
            </div>
            <p className="text-right text-[var(--black)]">
              {trainigSliderDate}
            </p>
          </div>

          <div className=" px-4">
            <div className="flex items-center">
              <img
                src={AddEventIcon}
                className="h-[1.2rem] w-auto mr-2"
                alt=""
              />
              <p className="text-[var(--blue)] text-[1.2rem]">Add Event</p>
            </div>

            <div className="">
              {/* Name Field */}
              <div className="flex flex-col mb-4">
                <label className="text-[var(--blue)] pl-1 pb-1" htmlFor="name">
                  Name
                </label>
                <input
                  id="name"
                  className="border-[#DBDBDB] border-[1px] py-2 px-1 rounded-[3px] outline-none text-[var(--blue)]"
                  type="text"
                  placeholder="e.g., Half Ironman"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              {/* Category Dropdown */}
              <div className="flex flex-col mb-4">
                <label
                  className="text-[var(--blue)] pl-1 pb-1"
                  htmlFor="category"
                >
                  Category
                </label>
                <select
                  id="category"
                  className="px-2 border-[#DBDBDB] border-[1px] py-2 rounded-[3px] outline-none text-[var(--blue)] appearance-none"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setSubCategory("");
                  }}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {categoryData.map((item) => (
                    <option key={item.name} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Subcategory Dropdown */}
              <div className="flex flex-col mb-4">
                <label
                  className="text-[var(--blue)] pl-1 pb-1"
                  htmlFor="subCategory"
                >
                  Sub Category
                </label>
                <select
                  id="subCategory"
                  className="px-2 border-[#DBDBDB] border-[1px] py-2 rounded-[3px] outline-none text-[var(--blue)] appearance-none"
                  value={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                  disabled={!selectedCategory.sub_categories?.length} // Disable if no subcategories
                >
                  <option value="">Select</option>
                  {selectedCategory.sub_categories?.map((sub, index) => (
                    <option key={index} value={sub}>
                      {sub}
                    </option>
                  ))}
                </select>
              </div>

              {subCategory === "custom" && (
                <div className="flex flex-col w-full">
                  <input
                    id="name"
                    className="border-[#DBDBDB] border-[1px] py-2 px-1 rounded-[3px] outline-none text-[var(--blue)] w-full"
                    type="number"
                    placeholder="Distance"
                    onChange={(e) => setDistance(e.target.value)}
                  />
                  <div className="flex justify-end">
                    <select
                      onChange={(e) => setDisValue(e.target.value)}
                      id="subCategory"
                      className="px-2 border-[#DBDBDB] border-[1px] py-1 rounded-[3px] outline-none text-[var(--blue)] appearance-none"
                    >
                      <option value="km">km</option>
                      <option value="mil">mil</option>
                      <option value="mtr">m</option>
                    </select>
                  </div>
                </div>
              )}

              {/* Buttons */}
              <div className="flex justify-end mt-5">
                <button
                  className="cancelButton"
                  onClick={() => {
                    setName(""); // Reset the name input
                    setCategory(""); // Reset the category dropdown
                    setSubCategory(""); // Reset the subcategory dropdown
                    Props.setAddEvent(false);
                  }}
                >
                  Cancel
                </button>
                <SaveBtn
                  load={load}
                  handleSave={handleAddEvent}
                  action="Save"
                  isDisable={isDisable}
                  className="saveButton"
                />
              </div>
              <div className="flex justify-end">
                {/* {isDisable && (
                <p className="text-red-500 text-sm mt-2">
                  Please fill out all fields.
                </p>
              )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEvent;
