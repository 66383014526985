import React, { useContext, useState } from "react";
import { CoachCalendarData } from "utility/constants/useContext";
import { format } from "date-fns";
import { ReschduleWokout } from "api/CoachCalendar";
import SaveBtn from "components/button/SaveBtn";
import RescheduleIcon from "assets/icons/CoachCalendar/Reschedule.svg";

function Reschedule() {
  const Props = useContext(CoachCalendarData);
  const [rescheduleDate, setRescheduleDate] = useState("");
  const [load, setLoad] = useState(false);

  let isDisable = !rescheduleDate;

  function updateCalendarList(workout_detail, rescheduleDate) {
    let calender_event_type;
    Props.calendarData.day_workouts.map((data) => {
      data.workouts.map((workouts) => {
        if (workouts.id === workout_detail.id) {
          calender_event_type = workouts.calender_event_type;
        }
      });
    });
    const updateWorkout_detail = {
      id: workout_detail.id,
      label: workout_detail.label,
      workout_type: workout_detail.workout_type,
      brief_information: workout_detail.brief_information,
      synced_with_device: workout_detail.synced_with_device,
      day: workout_detail.day,
      is_completed: workout_detail.activity_status,
      calender_event_type: calender_event_type,
      is_baseline: workout_detail.is_baseline,
      is_singlestep_dist: workout_detail.is_singlestep_dist,
    };

    const formattedDate = format(rescheduleDate, "dd-MM-yyyy");
    const updatedCalendarData = { ...Props.calendarData }; // Create a copy of calendar data

    updatedCalendarData.day_workouts = updatedCalendarData.day_workouts.map(
      (day) => {
        // If the workout exists on this date detele the wkout
        if (day.workouts.some((workout) => workout.id === workout_detail.id)) {
          return {
            ...day,
            workouts: day.workouts.filter(
              (workout) => workout.id !== workout_detail.id
            ),
          };
        }

        //  reschedule workout
        if (day.workout_date === formattedDate) {
          return {
            ...day,
            workouts: [...day.workouts, updateWorkout_detail],
          };
        }

        return day;
      }
    );

    // If the reschedule date doesn't exist in `day_workouts`, add it
    const rescheduleDayExists = updatedCalendarData.day_workouts.some(
      (day) => day.workout_date === formattedDate
    );

    if (!rescheduleDayExists) {
      updatedCalendarData.day_workouts.push({
        workout_date: formattedDate,
        training_plan_week: "", // Add the appropriate value if needed
        workouts: [updateWorkout_detail],
        training_plan_name: "", // Add the appropriate value if needed
      });
    }

    Props.setCalendarData(updatedCalendarData);
  }

  async function handleSave() {
    setLoad(true);
    const formattedDate = format(rescheduleDate, "dd-MM-yyyy");

    try {
      const res = await ReschduleWokout(
        Props.refId,
        Props.athleteId,
        formattedDate
      );

      if (res.success) {
        setLoad(false);
        updateCalendarList(res.data.data.workout_detail, rescheduleDate);
        Props.setReschedule(false);
      } else {
        setLoad(false);
        Props.setErrorPopup(true);
        Props.setErrorHeader("Something went wrong!");
        Props.setErrorMessage(res?.data || "Unable to Reschdule Workout");
      }
    } catch (error) {
      setLoad(false);
      Props.setErrorPopup(true);
      Props.setErrorHeader("Something went wrong!");
      Props.setErrorMessage("Unable to Reschdule Workout sxcsac");
    }
  }

  return (
    <div className="min-w-[20%]  overflow-hidden z-20 py-8">
      <div className="flex flex-col h-full">
        <div className="calendar_border bg-[#F8F8F8] w-full h-full p-4">
          <div className="flex justify-end">
            <p className="text-right text-[var(--black)]">
              {Props.trainigSliderDate}
            </p>
          </div>
          <div className="flex items-center pt-4">
            <img className="w-4 h-4 mr-2" src={RescheduleIcon} alt="" />
            <p className="text-[var(--blue)] text-[1.2rem]">Reschedule</p>
          </div>

          <div className="mt-4">
            {/* Name Field */}
            <div className="flex flex-col mb-4">
              <label className="text-[var(--blue)] pl-1 pb-1" htmlFor="name">
                Date
              </label>
              <input
                id="name"
                className="border-[#DBDBDB] border-[1px] py-2 px-1 rounded-[3px] outline-none !text-[var(--blue)]"
                type="date"
                placeholder="e.g., Half Ironman"
                onChange={(e) => setRescheduleDate(e.target.value)}
              />
            </div>

            {/* Buttons */}
            <div className="flex justify-end mt-5">
              <button
                className="cancelButton"
                onClick={() => Props.setReschedule(false)}
              >
                Cancel
              </button>
              {/* <button
                disabled={isDisable}
                onClick={handleSave}
                className="saveButton"
              >
                Save
              </button> */}

              <SaveBtn
                load={load}
                handleSave={handleSave}
                action="Save"
                isDisable={isDisable}
                className="saveButton"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reschedule;
