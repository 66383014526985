import React, { useContext, useEffect, useState } from "react";
import RepeatIcon from "assets/icons/repeat_icon.svg";
import RunIcon from "assets/icons/RunIcon.svg";
import SwimICon from "assets/icons/SwimIcon.svg";
import BikeIcon from "assets/icons/BikeIcon.svg";
import AddEvent from "./AddEvent";
import AddWorkout from "./AddWorkout";
import { CoachCalendarData } from "utility/constants/useContext";
import Reschedule from "./Reschedule";
import ActionPopup from "components/popups/ActionPopup";
import { DeleteWorkout } from "api/CoachCalendar";
import { isBefore, isToday } from "date-fns";
import CloseMenu from "assets/icons/CoachCalendar/close_menu (2).svg";
import {
  activityNameShortToFull,
  ZonesShortToFull,
  StrokeTypeShortToFull,
  EquipmentTypeShortToFull,
  convertMetersToKilometers,
  formatTime,
} from "hooks/convertValues";

function StepsSlider({
  stepsData,
  click,
  refId,
  trainigSliderDate,
  showMenu,
  setShowMenu,
  isAddWorkout,
  isAddEvent,
}) {
  const Props = useContext(CoachCalendarData);
  const [deletePopup, setDeletePopup] = useState(false);
  const [showEdit, setShowEdit] = useState(true);
  const [showDelete, setShowDelete] = useState(true);
  const [showReschdule, setShowReschdule] = useState(true);
  const [load, setLoad] = useState(false);
  const [sliderSubMenu, setSliderSubMenu] = useState(false);

  useEffect(() => {
    if (Props.planEventDate) {
      // Parse Event Date in "dd-MM-yyyy" format
      // const eventDate = parse(Props.planEventDate, "dd-MM-yyyy", new Date());
      const eventDate = Props.eventDate;
      const currentDate = new Date(); // Automatically uses today's date

      // Check if the event date is today
      if (isToday(eventDate)) {
        if (Props.isWorkoutCompleted) {
          setShowDelete(false);
          setShowReschdule(false);
        } else {
          setShowEdit(true);
          setShowDelete(true);
          setShowReschdule(true);
        }
        return; // Exit the effect to avoid overwriting conditions below
      }

      // Check if the event date is in the past
      if (isBefore(eventDate, currentDate)) {
        setShowEdit(false);
        if (!Props.isWorkoutCompleted) {
          setShowDelete(true);
          setShowReschdule(true);
        } else {
          setShowDelete(false);
          setShowReschdule(false);
        }
        return; // Exit since past condition is handled
      }

      // If the event date is in the future

      setShowEdit(true);
      setShowDelete(true);
      setShowReschdule(true);
    }
  }, [Props.planEventDate, Props.isWorkoutCompleted]);

  // Toggle menu visibility
  const toggleMenu = () => {
    setShowMenu(!showMenu);

    if (showMenu) {
      setSliderSubMenu(true);
    } else {
      setSliderSubMenu(true);
    }
  };

  // Delete popup
  function handleDelete() {
    setShowMenu(false); // Close menu after clicking
    setDeletePopup(true);
  }

  async function handleRemove() {
    if (load === true) {
      return;
    }
    setLoad(true);
    const res = await DeleteWorkout(refId);
    if (res.success) {
      setLoad(false);
      setDeletePopup(false);
      Props.setClick(false);
      Props.setUpdateCalendar(res);
    } else {
      Props.setErrorPopup(true);
      Props.setErrorHeader("Something went wrong!");
      Props.setErrorMessage(res?.data || "Unable to Delete Workout");
    }
  }

  if (Props.isReschedule) {
    return <Reschedule />;
  }

  if (isAddWorkout) {
    return <AddWorkout trainigSliderDate={trainigSliderDate} />;
  }

  if (isAddEvent) {
    return <AddEvent trainigSliderDate={trainigSliderDate} />;
  }

  if (click && refId) {
    return (
      <>
        {sliderSubMenu && (
          <div
            className="fixed  w-full h-[100vh] z-20"
            onClick={() => {
              setSliderSubMenu(false);
              setShowMenu(false);
            }}
          ></div>
        )}
        {/* DELETE POPUP */}
        {deletePopup && (
          <>
            <div className="delete_blur"></div>
            <ActionPopup
              message={{
                heading: `${
                  Props.calenderEventType === 1
                    ? "Delete Workout"
                    : "Delete Event"
                }`,
                message: `Do you really want to delete this ${
                  Props.calenderEventType === 1 ? " Workout" : " Event"
                } ?`,
              }}
              setDeletePopup={setDeletePopup}
              handleRemove={handleRemove}
              load={load}
            />
          </>
        )}
        <div className="min-w-[20%] min-h-full overflow-hidden z-20 py-8">
          <div className="flex flex-col h-full">
            <div className="calendar_border bg-[#F8F8F8] w-full h-full ">
              {sliderSubMenu && (
                <div
                  className="fixed  w-full h-[100vh] "
                  onClick={() => {
                    setSliderSubMenu(false);
                    setShowMenu(false);
                  }}
                ></div>
              )}
              <div className="flex justify-between items-center px-4 py-2 cursor-pointer">
                <div
                  className="flex items-center"
                  onClick={() => Props.setClick(false)}
                >
                  <p className="text-[var(--black)] mr-1">Close</p>
                  <img src={CloseMenu} alt="" />
                </div>

                <p className="text-right text-[var(--black)]">
                  {trainigSliderDate}
                </p>
              </div>
              <div className="flex flex-col px-4 pb-4">
                <div className="relative flex justify-between items-center mt-2">
                  {/* Workout Type and Name */}
                  <div className="flex items-center text-[var(--black)] text-left text-[1.1rem]">
                    <div className="mr-1">
                      {stepsData.workout_type === "RUN" && (
                        <img className="h-4" src={RunIcon} alt="Run Icon" />
                      )}
                      {stepsData.workout_type === "SWIM" && (
                        <img className="h-4" src={SwimICon} alt="Swim Icon" />
                      )}
                      {stepsData.workout_type === "BIKE" && (
                        <img className="h-4" src={BikeIcon} alt="Bike Icon" />
                      )}
                    </div>
                    <p>{stepsData.name}</p>
                    <span className="text-[var(--grey)] ml-2">#tof</span>
                  </div>
                  {/* Submenu Icon
                  {!showDelete && !showEdit && !showReschdule ? (
                    <></>
                  ) : (
                    <div>
                      {showMenu ? (
                        <img
                          src={SubMenu}
                          className="cursor-pointer"
                          onClick={toggleMenu}
                          alt=""
                        />
                      ) : (
                        <img
                          src={SubMenu}
                          alt="Submenu Icon"
                          className="cursor-pointer pl-3 h-full"
                          onClick={toggleMenu}
                        />
                      )}
                    </div>
                  )}
                  {showMenu && (
                    <div className="absolute top-8 right-0 bg-white shadow-lg border rounded-md w-40 ">
                      <ul className="text-[var(--black)] text-left m-0 z-50">
                        <li
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            setShowMenu(false); // Close menu after clicking
                            Props.setReschedule(true);
                          }}
                        >
                          Reschedule
                        </li>

                        {showEdit && (
                          <>
                            {Props.calenderEventType === 1 && (
                              <li
                                className="p-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => {
                                  setShowMenu(false);
                                  Props.setEditWorkout(true);
                                  Props.setEditWorkoutClicked(true);
                                }}
                              >
                                Edit Workout
                              </li>
                            )}
                          </>
                        )}

                        <li
                          className="p-2 hover:bg-gray-100 text-red-600 cursor-pointer"
                          onClick={handleDelete}
                        >
                          {Props.calenderEventType === 3
                            ? "Delete Event"
                            : "Delete Workout"}
                        </li>
                      </ul>
                    </div>
                  )} */}
                </div>

                {/* Notes Div */}
                <div>
                  {stepsData?.notes && (
                    <p className="text-[var(--blue)]">
                      Notes:{" "}
                      <span className="text-[var(--light-blue)]">
                        {stepsData?.notes}
                      </span>
                    </p>
                  )}
                </div>
                {/* Training Steps Start */}
                {stepsData?.steps?.map((steps, index) => {
                  const isLastObject = stepsData.steps.length;

                  return (
                    <div key={`repeatsssss--${index}`}>
                      <div className="flex flex-col w-full bg-[var(--white)] border_top_radius calendar_border mt-2 pb-2">
                        {steps.no_of_repeats > 1 ? (
                          <>
                            {index % 2 === 0 ? (
                              <div className="flex items-center bg-[#b2f7ef]  mb-2 border_top_radius px-1">
                                <img className="h-3" src={RepeatIcon} alt="" />
                                <p>{steps.no_of_repeats}</p>
                              </div>
                            ) : (
                              <div className="flex items-center bg-[#d1f7f3]  mb-2 border_top_radius px-1">
                                <img className="h-3" src={RepeatIcon} alt="" />
                                <p>{steps.no_of_repeats}</p>
                              </div>
                            )}
                          </>
                        ) : null}

                        {/* Use this map for card info in the box e.g., wup, z1, time, distance */}
                        {steps.activity_steps &&
                          steps.activity_steps.map(
                            (ActivitySteps, ActivityStepsIndex) => {
                              const formattedActivity = activityNameShortToFull(
                                ActivitySteps.activity
                              );

                              let PconvertTarget = ZonesShortToFull(
                                ActivitySteps?.primaryTarget?.target
                              );

                              let SconvertTarget = ZonesShortToFull(
                                ActivitySteps?.secondaryTarget?.target
                              );

                              let PrimaryTarget =
                                ActivitySteps?.primaryTarget?.target;
                              let PrimaryValue =
                                ActivitySteps?.primaryTarget?.value;

                              let SecondaryTarget =
                                ActivitySteps?.secondaryTarget?.target;
                              let SecondaryValue =
                                ActivitySteps?.secondaryTarget?.value;

                              const capWorkoutType =
                                Props.submitSteps?.workout_type;

                              const PconvertedZones = Props.convertZones(
                                capWorkoutType,
                                PrimaryTarget,
                                PrimaryValue
                              );

                              const SconvertedZones = Props.convertZones(
                                capWorkoutType,
                                SecondaryTarget,
                                SecondaryValue
                              );

                              let res;
                              let km;
                              if (ActivitySteps.distance) {
                                km = convertMetersToKilometers(
                                  ActivitySteps.distance
                                );
                              }
                              if (
                                ActivitySteps.time &&
                                ActivitySteps.time.includes(":")
                              ) {
                                res = formatTime(ActivitySteps.time);
                              }

                              const equipment = EquipmentTypeShortToFull(
                                ActivitySteps.equipmentType
                              );

                              const stroke = StrokeTypeShortToFull(
                                ActivitySteps.strokeType
                              );

                              return (
                                <div key={`activeStepss-${ActivityStepsIndex}`}>
                                  {/* Card heading color */}

                                  {ActivitySteps.activity === "WUP" ? (
                                    <div className="bg-[#FE5F55] py-[.2rem] mb-2 border_top_radius"></div>
                                  ) : (
                                    <>
                                      {ActivitySteps.activity === "CD" ? (
                                        <div className="bg-[#bfd200] py-[.2rem] mb-2 border_top_radius"></div>
                                      ) : (
                                        <>
                                          {steps.no_of_repeats === 1 ? (
                                            <>
                                              {index % 2 === 0 ? (
                                                <div className=" bg-[#b2f7ef] py-[.2rem] mb-2 border_top_radius"></div>
                                              ) : (
                                                <div className=" bg-[#d1f7f3]  py-[.2rem] mb-2 border_top_radius"></div>
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}

                                  {/* Workout steps  */}
                                  <div className="flex justify-between w-full px-1 text-[var(--blue)] font-medium flex-warp">
                                    <span className="px-1">
                                      {formattedActivity ||
                                        ActivitySteps.activity}
                                    </span>

                                    {/* Ensure to safely access properties */}

                                    {stepsData?.workout_type === "SWIM" ? (
                                      <div className=" px-1 flex flex-col">
                                        <div>
                                          {ActivitySteps.equipmentType ? (
                                            <>
                                              <span>
                                                {equipment === "None"
                                                  ? ""
                                                  : equipment ||
                                                    ActivitySteps.equipmentType}
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div>
                                          {ActivitySteps.strokeType ? (
                                            <>
                                              <span>
                                                {stroke === "Any Stroke" ||
                                                stroke === "None"
                                                  ? ""
                                                  : stroke ||
                                                    ActivitySteps.strokeType}
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className=" px-1 flex flex-col">
                                        <div>
                                          {ActivitySteps.primaryTarget ? (
                                            <>
                                              <span>
                                                {PconvertTarget ||
                                                  ActivitySteps.primaryTarget
                                                    .target}
                                              </span>
                                              <span className="pl-1 text-[.7rem] italic">
                                                {PconvertedZones ? (
                                                  <span>
                                                    {PconvertedZones.name}
                                                    <span>
                                                      {PconvertedZones.limit}
                                                    </span>
                                                  </span>
                                                ) : (
                                                  ActivitySteps.primaryTarget
                                                    .value
                                                )}
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        <div>
                                          {ActivitySteps.secondaryTarget ? (
                                            <>
                                              <span>
                                                {SconvertTarget ||
                                                  ActivitySteps.secondaryTarget
                                                    .target}
                                              </span>
                                              <span className="pl-1 text-[.7rem] italic">
                                                {SconvertedZones ? (
                                                  <span>
                                                    {SconvertedZones.name}
                                                    <span>
                                                      {SconvertedZones.limit}
                                                    </span>
                                                  </span>
                                                ) : (
                                                  ActivitySteps.secondaryTarget
                                                    .value
                                                )}
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    )}

                                    {/* Distance and time */}
                                    <div className="flex flex-col items-end px-1">
                                      {ActivitySteps.time && (
                                        <span>
                                          {res || ActivitySteps.time || ""}
                                        </span>
                                      )}
                                      {ActivitySteps.distance > 0 && (
                                        <>
                                          <span className="text-right">
                                            {km || ActivitySteps.distance}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  );
                })}
                {/* Training Steps End */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default StepsSlider;
