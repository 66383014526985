export function ShortToFull(activity) {
  switch (activity) {
    case "SWIM":
      return "Swim";
    case "BIKE":
      return "Bike";
    case "RUN":
      return "Run";
    case "WUP":
      return "Warmup";
    case "CD":
      return "CoolDown";
    case "RST":
      return "Rest";
    case "RC":
      return "Recover";
    case "PC":
      return "Pace";
    case "PZ":
      return "Power Zone";
    case "CA":
      return "Cadence";
    case "HR":
      return "Heart Rate";
    case null:
      return "No Target";
    case "FR":
      return "Free Style";
    case "FS":
      return "Free Style";
    case "BS":
      return "BackStroke";
    case "BR":
      return "BreastStroke";
    case "BR":
      return "Drill";
    case "FL":
      return "Fly";
    case "MX":
      return "Mixed";
    case "PB":
      return "Pull Buoy";
    case "KB":
      return "Kickboard";
    case "FN":
      return "Fins";
    case "PD":
      return "Paddles";
    case "SN":
      return "Snorkel";
    case "AS":
      return "Any Stroke";
    case "NA":
      return "None";
    default:
      return activity;
  }
}

export function FullToShort(activity) {
  switch (activity) {
    case "SWIM":
      return "Swim";
    case "BIKE":
      return "Bike";
    case "RUN":
      return "Run";
    case "Warmup":
      return "WUP";
    case "CoolDown":
      return "CD";
    case "Rest":
      return "RST";
    case "Recover":
      return "RC";
    case "Pace":
      return "PC";
    case "Power Zone":
      return "PZ";
    case "Cadence":
      return "CA";
    case "Heart Rate":
      return "HR";
    case "No Target":
      return null;
    case "Free Style":
      return "FS";
    case "BackStroke":
      return "BS";
    case "BreastStroke":
      return "BR";
    case "Drill":
      return "DR";
    case "Fly":
      return "FL";
    case "Mixed":
      return "MX";
    case "Pull Buoy":
      return "PB";
    case "Kickboard":
      return "KB";
    case "Fins":
      return "FN";
    case "Paddles":
      return "PD";
    case "Snorkel":
      return "SN";
    case "None":
      return "NA";
    default:
      return activity;
  }
}

export function workoutTypeCapsToSmall(activity) {
  switch (activity) {
    case "SWIM":
      return "Swim";
    case "BIKE":
      return "Bike";
    case "RUN":
      return "Run";
    case "NA":
      return "None";
    default:
      return activity;
  }
}

export function activityNameShortToFull(activity) {
  switch (activity) {
    case "WUP":
      return "Warmup";
    case "CD":
      return "CoolDown";
    case "RST":
      return "Rest";
    case "RC":
      return "Recover";
    case "SWIM":
      return "Swim";
    case "BIKE":
      return "Bike";
    case "RUN":
      return "Run";
    case "NA":
      return "None";
    default:
      return activity;
  }
}

export function activityNameFullToShort(activity) {
  switch (activity) {
    case "SWIM":
      return "Swim";
    case "BIKE":
      return "Bike";
    case "RUN":
      return "Run";
    case "Warmup":
      return "WUP";
    case "CoolDown":
      return "CD";
    case "Rest":
      return "RST";
    case "Recover":
      return "RC";
    case "None":
      return "NA";
    default:
      return activity;
  }
}

export function ZonesFullToShort(activity) {
  switch (activity) {
    case "Heart Rate":
      return "HR";
    case "Power Zone":
      return "PZ";
    case "Pace":
      return "PC";
    case "Cadence":
      return "CA";
    case "None":
      return "NA";
    default:
      return activity;
  }
}

export function ZonesShortToFull(activity) {
  switch (activity) {
    case "PC":
      return "Pace";
    case "PZ":
      return "Power Zone";
    case "CA":
      return "Cadence";
    case "HR":
      return "Heart Rate";
    case null:
      return "No Target";
    case "NA":
      return "None";
    default:
      return activity;
  }
}

export function StrokeTypeShortToFull(activity) {
  switch (activity) {
    case "FR":
      return "Free Style";
    case "FS":
      return "Free Style";
    case "BS":
      return "BackStroke";
    case "BR":
      return "BreastStroke";
    case "DR":
      return "Drill";
    case "FL":
      return "Fly";
    case "MX":
      return "Mixed";
    case "AS":
      return "Any Stroke";
    case "NA":
      return "None";
    default:
      return activity;
  }
}

export function StrokeTypeFullToShort(activity) {
  switch (activity) {
    case "Free Style":
      return "FS";
    case "BackStroke":
      return "BS";
    case "BreastStroke":
      return "BR";
    case "Drill":
      return "DR";
    case "Fly":
      return "FL";
    case "Mixed":
      return "MX";
    case "Any Stroke":
      return "AS";
    case "NA":
      return "None";
    default:
      return activity;
  }
}

export function EquipmentTypeShortToFull(activity) {
  switch (activity) {
    case "PB":
      return "Pull Buoy";
    case "KB":
      return "Kickboard";
    case "FN":
      return "Fins";
    case "PD":
      return "Paddles";
    case "SN":
      return "Snorkel";
    case "NA":
      return "None";
    default:
      return activity;
  }
}

export function EquipmentTypeFulltToShort(activity) {
  switch (activity) {
    case "Pull Buoy":
      return "PB";
    case "Kickboard":
      return "KB";
    case "Fins":
      return "FN";
    case "Paddles":
      return "PD";
    case "Snorkel":
      return "SN";
    case "None":
      return "NA";
    default:
      return activity;
  }
}

export function convertMetersToKilometers(meters) {
  if (typeof meters !== "number" || meters < 0) {
    return "Please provide a valid number of meters.";
  }

  if (meters < 1000) {
    return `${meters} m`;
  }

  return `${meters / 1000} km`;
}

// if m is there
export function convertMetersToKilometersIfM(meters) {
  function removeMetersSuffix(m) {
    if (typeof m === "string") {
      return m.replace(" m", ""); // Remove the " m" part
    }
    return "Please provide a valid string.";
  }

  const m = removeMetersSuffix(meters);

  if (m < 1000) {
    return `${m} m`;
  }

  return `${m / 1000} km`; // Keeping 2 decimal places for km
}

// export function formatTime(timeString) {
//   const [hours, minutes, seconds] = timeString.split(":").map(Number);

//   // Handle different cases
//   if (hours > 0 && minutes > 0 && seconds === 0) {
//     return `${hours}:${minutes} hr`;
//   } else if (hours === 0 && minutes > 0 && seconds === 0) {
//     return `${minutes} mins`;
//   } else if (hours === 0 && minutes === 0 && seconds > 0) {
//     return `${seconds} secs`;
//   } else if (hours > 0 && minutes === 0 && seconds === 0) {
//     return `${hours} hr`;
//   } else if (hours > 0 && minutes > 0 && seconds > 0) {
//     return `${hours}:${minutes}:${seconds} hr`;
//   } else {
//     return `${minutes}:${seconds} min`;
//   }
// }

export function formatTime(timeString) {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  // Handle different cases
  if (hours > 0 && minutes > 0 && seconds === 0) {
    if (minutes < 10) {
      return `${hours}:0${minutes} hr`;
    } else {
      return `${hours}:${minutes} hr`;
    }
  } else if (hours === 0 && minutes > 0 && seconds === 0) {
    return `${minutes} mins`;
  } else if (hours === 0 && minutes === 0 && seconds > 0) {
    return `${seconds} secs`;
  } else if (hours > 0 && minutes === 0 && seconds === 0) {
    return `${hours} hr`;
  } else if (hours > 0 && minutes > 0 && seconds > 0) {
    return `${hours}:${minutes}:${seconds} hr`;
  } else {
    return `${hours}:${minutes}:${seconds} `;
  }
}

const body = {
  keep_workout: "0", // 0 for cut 1 for copy
  from: {
    date: "09-01-2025",
    day: 1, // 1 for copy and cut 7 for week
    id: 7223232323, // workout_id if week copy = null
  },
  to: {
    user: 3232323, // user_ref
    date: "10-01-2025",
  },
};
