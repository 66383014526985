import React, { useContext, useState, useEffect } from "react";
import {
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  subDays,
  addMonths,
  subMonths,
  startOfMonth,
} from "date-fns";
import CoachEventCalendar from "./CoachEventCalendar";
import MoreIcon from "assets/icons/subMenu.svg";
import { CoachCalendarData } from "utility/constants/useContext";
import { isBefore, isToday } from "date-fns";
import CoachCalendarWeek from "./CoachCalendarWeek";
import ActionPopup from "components/popups/ActionPopup";
import { DeleteWorkout } from "api/CoachCalendar";
import Tooltip from "./Tooltip";
import CopyWeekPopup from "./CopyWeekPopup";

function CalendarYearView({
  currentMonth,
  calendarData,
  setRefId,
  setStpesData,
  setTrainingSilderDate,
  click,
  setClick,
  setAddWorkout,
  setAddEvent,
}) {
  let pressTimer;
  const currentDate = new Date();
  const Props = useContext(CoachCalendarData);
  const [isRightClick, setIsRightClick] = useState(false);
  const [showEdit, setShowEdit] = useState(true);
  const [showDelete, setShowDelete] = useState(true);
  const [showReschdule, setShowReschdule] = useState(true);
  const [load, setLoad] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [closePopup, setClosePopup] = useState(false);

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [tooltip, setTooltip] = useState({
    visible: false,
    content: "",
    position: { top: 0, left: 0 },
  });

  const [copyWeek, setCopyWeek] = useState({
    visible: false,
    content: "",
    position: { top: 0, left: 0 },
  });

  // ------------------------------------- Handle tooltip ---------------------------------------- //
  const handleDateClick = (event, date) => {
    Props.setEventDate(date);

    const TooltipDate = format(date, "dd-MM-yyyy"); // 11-01-2025
    Props.setPlanEventDate(TooltipDate);

    Props.setReschedule(false);

    const formattedPlanDate = format(date, "EEEE, d MMM");

    Props.setTrainingSilderDate(formattedPlanDate);

    const isCoachEventClicked = event.target.closest(".coach-event");

    const isMoreClicked = event.target.closest(".more-option");

    // Get viewport dimensions
    const tooltipWidth = 150; // Approximate width of the tooltip (adjust as needed)
    const tooltipHeight = 50; // Approximate height of the tooltip (adjust as needed)
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate initial tooltip position
    let top = event.clientY + window.scrollY;
    let left = event.clientX + window.scrollX;

    // Adjust for overflow on the right
    if (left + tooltipWidth > viewportWidth + window.scrollX) {
      left = viewportWidth + window.scrollX - tooltipWidth - 10; // Add some padding
    }

    // Adjust for overflow on the bottom
    if (top + tooltipHeight > viewportHeight + window.scrollY) {
      top = viewportHeight + window.scrollY - tooltipHeight - 10; // Add some padding
    }

    // Adjust for overflow on the left
    if (left < window.scrollX) {
      left = window.scrollX + 10; // Add some padding
    }

    // Adjust for overflow on the top
    if (top < window.scrollY) {
      top = window.scrollY + 10; // Add some padding
    }

    const eventDate = date;
    const currentDate = new Date();

    if (!isCoachEventClicked && !isMoreClicked) {
      // Check if it a right click or not
      if (event.type === "contextmenu" || event.type === "longpress") {
        event.preventDefault();
        if (Props.isCopy || Props.isCut) {
          if (isBefore(eventDate, currentDate)) {
            // Date is in the past, hide tooltip
            setTooltip({
              visible: false,
            });
          } else {
            // Date is today or in the future, show tooltip
            if (event.type === "longpress") {
              const rect = event.target.getBoundingClientRect();
              const tooltipPosition = {
                top: rect.top + window.scrollY + rect.height / 2,
                left: rect.left + window.scrollX,
              };

              setTooltip({ visible: true, position: tooltipPosition });
            } else {
              setTooltip({
                visible: true,
                position: { top, left },
              });
            }

            Props.setPaste(true);
            setIsRightClick(true);
          }

          if (isToday(eventDate)) {
            if (event.type === "longpress") {
              const rect = event.target.getBoundingClientRect();
              const tooltipPosition = {
                top: rect.top + window.scrollY + rect.height / 2,
                left: rect.left + window.scrollX,
              };

              setTooltip({ visible: true, position: tooltipPosition });
            } else {
              setTooltip({
                visible: true,
                position: { top, left },
              });
            }
            Props.setPaste(true);
            setIsRightClick(true);
          }
        }

        // add event and workout popup
      } else {
        setClosePopup(true);
        if (!Props.athleteId) {
          setTooltip({
            visible: false,
          });
          return;
        }

        // Compare dates
        if (isBefore(eventDate, currentDate)) {
          // Date is in the past, hide tooltip
          setTooltip({
            visible: false,
          });
        } else {
          // Date is today or in the future, show tooltip

          setTooltip({
            visible: true,
            position: { top, left },
          });
        }

        if (isToday(eventDate)) {
          setTooltip({
            visible: true,
            position: { top, left },
          });
        }
      }
    } else {
      if (isMoreClicked) {
        setClosePopup(true);

        // Compare dates
        if (isBefore(eventDate, currentDate) && Props.isPasteWeek) {
          // Date is in the past, hide tooltip
          setCopyWeek({
            visible: false,
          });
        } else {
          // Date is today or in the future, show tooltip

          setCopyWeek({
            visible: true,
            position: { top, left },
          });
        }

        if (isToday(eventDate) && Props.isPasteWeek) {
          setCopyWeek({
            visible: true,
            position: { top, left },
          });
        }
      }
    }
  };

  const handleLongPress = (event, date) => {
    event.type = "longpress"; // Add custom event type
    handleDateClick(event, date);
  };

  const handleTouchStart = (event, date) => {
    pressTimer = setTimeout(() => {
      handleLongPress(event, date);
    }, 600); // Trigger long press after 600ms
  };

  const handleTouchEnd = (event) => {
    clearTimeout(pressTimer); // Clear timer on touch end
  };

  const hideTooltip = () => {
    setTooltip({ ...tooltip, visible: false });
    setCopyWeek({ ...copyWeek, visible: false });
    setIsRightClick(false);
    setClosePopup(false);
  };

  const firstDayofMonth = startOfMonth(currentMonth);
  const lastDayOfMonth = endOfMonth(currentMonth);

  const daysInInterval = eachDayOfInterval({
    start: firstDayofMonth,
    end: lastDayOfMonth,
  });

  const startingDayIndex = (getDay(firstDayofMonth) + 6) % 7;

  const lastDayOfPrevMonth = endOfMonth(subMonths(currentMonth, 1));
  const prevMonthDays = Array.from({ length: startingDayIndex }, (_, i) =>
    format(subDays(lastDayOfPrevMonth, startingDayIndex - i - 1), "d")
  );

  const totalDaysRendered = prevMonthDays.length + daysInInterval.length;
  const remainingDays = (7 - (totalDaysRendered % 7)) % 7;

  const nextMonthDays = Array.from({ length: remainingDays }, (_, i) => i + 1);

  async function handleRemove() {
    if (load === true) {
      return;
    }
    setLoad(true);
    const res = await DeleteWorkout(Props.refId);
    if (res.success) {
      setLoad(false);
      setDeletePopup(false);
      Props.setClick(false);
      Props.setUpdateCalendar(res);
    } else {
      Props.setErrorPopup(true);
      Props.setErrorHeader("Something went wrong!");
      Props.setErrorMessage(res?.data || "Unable to Delete Workout");
    }
  }

  const handleMoreOption = (event, date) => {
    handleDateClick(event, date);
  };

  //--------------------------------------------------------------- week view ----------------------------------------------------------------
  if (Props.mView === false) {
    return (
      <CoachCalendarWeek
        calendarData={calendarData}
        setRefId={setRefId}
        setStpesData={setStpesData}
        setTrainingSilderDate={setTrainingSilderDate}
        setClick={setClick}
        setAddWorkout={setAddWorkout}
        setAddEvent={setAddEvent}
        handleDateClick={handleDateClick}
        isRightClick={isRightClick}
        setIsRightClick={setIsRightClick}
        tooltip={tooltip}
        setTooltip={setTooltip}
        setShowEdit={setShowEdit}
        setShowDelete={setShowDelete}
        setShowReschdule={setShowReschdule}
        hideTooltip={hideTooltip}
        showEdit={showEdit}
        showDelete={showDelete}
        showReschdule={showReschdule}
        setClosePopup={setClosePopup}
        closePopup={closePopup}
        handleTouchStart={handleTouchStart}
        handleTouchEnd={handleTouchEnd}
      />
    );
  }

  return (
    <div>
      {closePopup && (
        <div
          onClick={() => {
            setTooltip({ ...tooltip, visible: false });
            setCopyWeek({ ...copyWeek, visible: false });

            setIsRightClick(false);
            setClosePopup(false);
          }}
          className="fixed w-full h-full bg-transparent z-40"
        ></div>
      )}

      {deletePopup && (
        <>
          <div className="delete_blur"></div>
          <ActionPopup
            message={{
              heading: `${
                Props.calenderEventType === 1
                  ? "Delete Workout"
                  : "Delete Event"
              }`,
              message: `Do you really want to delete this ${
                Props.calenderEventType === 1 ? " Workout" : " Event"
              } ?`,
            }}
            setDeletePopup={setDeletePopup}
            handleRemove={handleRemove}
            load={load}
          />
        </>
      )}
      <div className="bg-[#DBDBDB] grid grid-cols-7 border gap-[0.8px] border-[#DBDBDB] border-solid overflow-hidden">
        {weekdays.map((week, index) => (
          <p
            className="text-center text-[var(--blue)] m-0 text-[1.2rem] bg-[#EAEAEA]"
            key={index}
          >
            {week}
          </p>
        ))}

        {/*------------------------------------------ Render previous month days -------------------------------------------------------*/}
        {prevMonthDays.map((day, index) => {
          const date = subDays(
            lastDayOfPrevMonth,
            startingDayIndex - index - 1
          );

          return (
            <div
              key={`prev-${index}`}
              onClick={(e) => handleDateClick(e, date)}
              onContextMenu={(e) => handleDateClick(e, date)}
              className=" bg-[var(--white)] text-left min-h-[8rem] m-0 text-[1.2rem] text-[#808080] font-thin"
            >
              <div className=" flex justify-between items-center p-2 pb-1">
                <p className="">{day}</p>
                {getDay(date) === 1 && Props.athleteId && (
                  <img
                    src={MoreIcon}
                    className="z-20 cursor-pointer w-3 h-3 more-option ml-3"
                    onClick={(e) => handleMoreOption(e, date)}
                    alt="More options"
                  />
                )}
              </div>

              <div className="px-1 min-h-[70%] w-full">
                <div className="coach-event">
                  {calendarData?.day_workouts?.length === 0 ? (
                    <></>
                  ) : (
                    <CoachEventCalendar
                      calendarDay={format(date, "dd-MM-yyyy")}
                      calendarData={calendarData}
                      setRefId={setRefId}
                      setStpesData={setStpesData}
                      setTrainingSilderDate={setTrainingSilderDate}
                      setClick={setClick}
                      setAddWorkout={setAddWorkout}
                      setAddEvent={setAddEvent}
                      isRightClick={isRightClick}
                      setIsRightClick={setIsRightClick}
                      tooltip={tooltip}
                      setTooltip={setTooltip}
                      setShowEdit={setShowEdit}
                      setShowDelete={setShowDelete}
                      setShowReschdule={setShowReschdule}
                      setClosePopup={setClosePopup}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}

        {/*------------------------------------------------------ Render days from the current month --------------------------------*/}
        {daysInInterval.map((date, index) => (
          <div
            className={` bg-[var(--white)] text-left min-h-[8rem] m-0 text-[1.2rem] relative ${
              format(date, "dd-MM-yyyy") === format(currentDate, "dd-MM-yyyy")
                ? "border-[1px] border-solid border-[var(--blue)] "
                : ""
            }`}
            key={`daysInMonth-${index}`}
            onClick={(e) => handleDateClick(e, date)}
            onContextMenu={(e) => handleDateClick(e, date)}
            onTouchStart={(e) => handleTouchStart(e, date)} // Handle long press start
            onTouchEnd={(e) => handleTouchEnd(e, date)} // Clear long press
          >
            <div className="flex justify-between items-center p-2 pb-1">
              <p className="">{format(date, "d")}</p>
              {getDay(date) === 1 && Props.athleteId && (
                <img
                  src={MoreIcon}
                  className="z-20 cursor-pointer w-3 h-3 more-option ml-3"
                  onClick={(e) => handleMoreOption(e, date)}
                  alt="More options"
                />
              )}
            </div>

            <div className="  px-1 min-h-[70%] w-full ">
              <div className="coach-event">
                {calendarData?.day_workouts?.length === 0 ? (
                  <></>
                ) : (
                  <CoachEventCalendar
                    calendarDay={format(date, "dd-MM-yyyy")}
                    calendarData={calendarData}
                    setRefId={setRefId}
                    setStpesData={setStpesData}
                    setTrainingSilderDate={setTrainingSilderDate}
                    setClick={setClick}
                    setAddWorkout={setAddWorkout}
                    setAddEvent={setAddEvent}
                    isRightClick={isRightClick}
                    setIsRightClick={setIsRightClick}
                    tooltip={tooltip}
                    setTooltip={setTooltip}
                    setShowEdit={setShowEdit}
                    setShowDelete={setShowDelete}
                    setShowReschdule={setShowReschdule}
                    setClosePopup={setClosePopup}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
        {/*----------------------------------------------------------- Render next month days -------------------------------------------*/}

        {nextMonthDays.map((_, index) => {
          const date = addMonths(currentMonth, 1); // Get next month's base date
          const fullDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            index + 1 // Start from the 1st of next month
          );

          return (
            <div
              key={`next-${index}`}
              onClick={(e) => handleDateClick(e, fullDate)}
              onContextMenu={(e) => handleDateClick(e, fullDate)}
              onTouchStart={(e) => handleTouchStart(e, fullDate)} // Handle long press start
              onTouchEnd={(e) => handleTouchEnd(e, fullDate)} // Clear long press
              className=" bg-[var(--white)] text-left min-h-[8rem] m-0 text-[1.2rem] text-[#808080] font-thin"
            >
              <div className="flex justify-between items-center p-2 pb-1">
                <p className="">
                  {index + 1}
                  {index === 0
                    ? format(addMonths(currentMonth, 1), " MMM")
                    : ""}
                </p>
                {getDay(fullDate) === 1 && (
                  <img
                    src={MoreIcon}
                    className="z-20 cursor-pointer w-3 h-3 more-option ml-3"
                    onClick={() => handleMoreOption(fullDate)}
                    alt="More options"
                  />
                )}
              </div>

              <div className="px-1 min-h-[70%] w-full">
                <div className="coach-event">
                  {calendarData?.day_workouts?.length === 0 ? (
                    <></>
                  ) : (
                    <CoachEventCalendar
                      calendarDay={format(fullDate, "dd-MM-yyyy")}
                      calendarData={calendarData}
                      setRefId={setRefId}
                      setStpesData={setStpesData}
                      setTrainingSilderDate={setTrainingSilderDate}
                      setClick={setClick}
                      setAddWorkout={setAddWorkout}
                      setAddEvent={setAddEvent}
                      isRightClick={isRightClick}
                      setIsRightClick={setIsRightClick}
                      tooltip={tooltip}
                      setTooltip={setTooltip}
                      setShowEdit={setShowEdit}
                      setShowDelete={setShowDelete}
                      setShowReschdule={setShowReschdule}
                      setClosePopup={setClosePopup}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}

        <CopyWeekPopup
          copyWeek={copyWeek}
          setCopyWeek={setCopyWeek}
          hideTooltip={hideTooltip}
          setClosePopup={setClosePopup}
        />
        {/* Tooltip */}
        <Tooltip
          tooltip={tooltip}
          setTooltip={setTooltip}
          setDeletePopup={setDeletePopup}
          hideTooltip={hideTooltip}
          isRightClick={isRightClick}
          setIsRightClick={setIsRightClick}
          showEdit={showEdit}
          showDelete={showDelete}
          showReschdule={showReschdule}
          setClick={setClick}
          setAddEvent={setAddEvent}
          setAddWorkout={setAddWorkout}
          setClosePopup={setClosePopup}
        />
      </div>
    </div>
  );
}

export default CalendarYearView;
