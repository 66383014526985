import React, { useContext, useEffect } from "react";
import WeekView from "./WeekView";
import CalendarEvents from "./CalendarEvents";
import {
  parse,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  subDays,
  addMonths,
  subMonths,
  startOfMonth,
} from "date-fns";
import { CalendarData } from "utility/constants/useContext";

function MonthView() {
  const Props = useContext(CalendarData);
  const currentDate = new Date();
  // Fallback for missing calendar data
  const EventData = Props.calendarData || [];

  const firstDayofMonth = startOfMonth(Props.currentMonth);

  // Ensure FromDate exists and is valid
  let fromDate = firstDayofMonth; // Default to first day of current month

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const lastDayOfMonth = endOfMonth(Props.currentMonth); // End of the current month

  useEffect(() => {
    Props.setFromDate(firstDayofMonth);
    Props.setToDate(lastDayOfMonth);
  }, [Props.currentMonth]);

  // Create an array of days from FromDate to the end of the current month
  let daysInInterval = [];

  try {
    daysInInterval = eachDayOfInterval({
      start: fromDate,
      end: lastDayOfMonth,
    });
  } catch (error) {
    console.error("Error generating days in interval:", error);
  }

  const startingDayIndex = (getDay(fromDate) + 6) % 7; // Adjust for Monday start

  // Previous month details
  const lastDayOfPrevMonth = endOfMonth(subMonths(fromDate, 1));

  // Correctly calculate previous month days
  const prevMonthDays = Array.from({ length: startingDayIndex }, (_, i) =>
    format(subDays(lastDayOfPrevMonth, startingDayIndex - i - 1), "d")
  );

  const totalDaysRendered = prevMonthDays.length + daysInInterval.length;
  const remainingDays = (7 - (totalDaysRendered % 7)) % 7;

  // Next month days
  const nextMonthDays = Array.from({ length: remainingDays }, (_, i) => i + 1);

  // Week View ---------------------------------------------------------------
  if (Props.mView === false) {
    return <WeekView />;
  }

  // Month View -------------------------------------------------------------
  return (
    <div>
      <div className="bg-[#DBDBDB] grid grid-cols-7 border gap-[0.8px] border-[#DBDBDB] border-solid overflow-hidden">
        {weekdays.map((week, index) => (
          <p
            className="text-center text-[var(--blue)] m-0 text-[1.2rem] bg-[#EAEAEA]"
            key={index}
          >
            {week}
          </p>
        ))}

        {/* Render previous month days */}
        {prevMonthDays.map((day, index) => (
          <div
            key={`prev-${index}`}
            className="bg-[var(--white)] text-left min-h-[8rem] m-0 text-[1.2rem] text-[#808080] font-thin"
          >
            <p className="p-2 pb-1">{day}</p>
          </div>
        ))}

        {/* Render days from FromDate to the end of the current month */}
        {daysInInterval.map((date, index) => (
          <div
            className={`bg-[var(--white)] text-left min-h-[8rem] m-0 text-[1.2rem] ${
              format(date, "dd-MM-yyyy") === format(currentDate, "dd-MM-yyyy")
                ? "border-[1px] border-solid border-[var(--blue)]"
                : ""
            }`}
            key={`daysInMonth-${index}`}
          >
            <p className="p-2 pb-1">{format(date, "d")}</p>
            <div className="flex flex-col justify-center px-1">
              <div>
                {EventData?.length === 0 ? (
                  <></>
                ) : (
                  <CalendarEvents calendarDay={format(date, "dd-MM-yyyy")} />
                )}
              </div>
            </div>
          </div>
        ))}

        {/* Render next month days */}
        {nextMonthDays.map((day, index) => (
          <div
            className="bg-[var(--white)] text-left min-h-[8rem] m-0 text-[1.2rem] text-[#808080] font-thin"
            key={`next-${index}`}
          >
            <p className="p-2 pb-1">
              {day}{" "}
              {index === 0
                ? format(addMonths(Props.currentMonth, 1), "MMM")
                : ""}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MonthView;
