import axios from "axios";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { Header } from "api/Signin";
import { throwError } from "hooks/throwErrors";

export async function DeleteWorkout(refId) {
  const header = Header();

  return axios
    .post(
      API_URL_CONSTANTS.DELETE_WORKOUT + `${refId}`,
      {},
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        return { success: true, data: res };
      }
    })
    .catch((error) => {
      const errorMessage = throwError(error);
      return { success: false, data: errorMessage };
    });
}

export async function ReschduleWokout(refId, athleteId, date) {
  const header = Header();

  return axios
    .post(
      API_URL_CONSTANTS.RESCHEDULE_WORKOUT,
      {
        id: refId,
        rescheduled_date: date,
        user_ref_id: athleteId,
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status_code === 1) {
          return { success: true, data: res };
        }
      }
    })
    .catch((error) => {
      const errorMessage = throwError(error);
      return { success: false, data: errorMessage };
    });
}

export async function AddTemplateWorkout(
  athleteId,
  date,
  templateId,
  dayOfWeek,
  syncDevice
) {
  const header = Header();

  return axios
    .post(
      API_URL_CONSTANTS.ADD_WORKOUTS,
      {
        user: athleteId,
        workout: {
          day: dayOfWeek,
          date: date,
          workout_template_id: templateId,
          sync_device: syncDevice,
        },
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status_code === 1) {
          return { success: true, data: res };
        }
      }
    })
    .catch((error) => {
      const errorMessage = throwError(error);
      return { success: false, data: errorMessage };
    });
}

export async function AddTemplateMultiWorkout(
  athleteId,
  date,
  templateId,
  dayOfWeek,
  syncDevice
) {
  const header = Header();

  return axios
    .post(
      API_URL_CONSTANTS.ADD_MULTI_WORKOUTS,
      {
        user: athleteId,
        workout: {
          day: dayOfWeek,
          date: date,
          id: templateId,
          sync_device: syncDevice,
        },
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status_code === 1) {
          return { success: true, data: res };
        }
      }
    })
    .catch((error) => {
      const errorMessage = throwError(error);
      return { success: false, data: errorMessage };
    });
}

export async function EditStepsData(
  athleteId,
  refId,
  workoutSteps,
  formattedDate,
  dayOfWeek,
  WorkoutType,
  notes,
  name,
  syncDevice,
  totaltime,
  totalDistance,
  isBaseline
) {
  const header = Header();

  return axios
    .post(
      API_URL_CONSTANTS.ADD_WORKOUTS,
      {
        user: athleteId,
        workout: {
          id: refId,
          day: dayOfWeek,
          date: formattedDate,
          name: name,
          workout_type: WorkoutType,
          total_hours: totaltime,
          total_distance: totalDistance,
          notes: notes,
          sync_device: syncDevice,
          is_baseline: isBaseline,
          steps: workoutSteps,
        },
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status_code === 1) {
          return { success: true, data: res.data.workout_detail };
        }
      }
    })
    .catch((error) => {
      const errorMessage = throwError(error);
      return { success: false, data: errorMessage };
    });
}

export async function AddStepsData(
  athleteId,
  workoutSteps,
  formattedDate,
  dayOfWeek,
  WorkoutType,
  notes,
  name,
  syncDevice,
  totaltime,
  totalDistance,
  isBaseline
) {
  const header = Header();

  return axios
    .post(
      API_URL_CONSTANTS.ADD_WORKOUTS,
      {
        user: athleteId,
        workout: {
          day: dayOfWeek,
          date: formattedDate,
          name: name,
          workout_type: WorkoutType,
          total_hours: totaltime,
          total_distance: totalDistance,
          notes: notes,
          sync_device: syncDevice,
          is_baseline: isBaseline,
          steps: workoutSteps,
        },
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status_code === 1) {
          return { success: true, data: res };
        }
      }
    })
    .catch((error) => {
      const errorMessage = throwError(error);
      return { success: false, data: errorMessage };
    });
}

export async function AddMultiWorkoutStpes(
  athleteId,
  workouts,
  formattedDate,
  dayOfWeek,
  notes,
  UpperCaseWorkoutType
) {
  const header = Header();

  return axios
    .post(
      API_URL_CONSTANTS.ADD_MULTI_WORKOUTS,
      {
        user: athleteId,
        workout: {
          date: formattedDate,
          day: dayOfWeek,
          workout_type: UpperCaseWorkoutType,
          notes: notes,
          short_name: UpperCaseWorkoutType,
          long_name: UpperCaseWorkoutType,
          sync_device: 1,
          workouts: workouts,
        },
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status_code === 1) {
          return { success: true, data: res };
        }
      }
    })
    .catch((error) => {
      const errorMessage = throwError(error);
      return { success: false, data: errorMessage };
    });
}

export async function cutCopy(submitCopy) {
  const header = Header();

  return axios
    .post(API_URL_CONSTANTS.CUT_COPY, submitCopy, {
      headers: header,
    })
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status_code === 1) {
          return { success: true, data: res.data };
        }
      }
    })
    .catch((error) => {
      const errorMessage = throwError(error);
      return { success: false, data: errorMessage };
    });
}

export async function shiftWeek(submitWeek) {
  const header = Header();

  return axios
    .post(API_URL_CONSTANTS.SHIFT_WEEK, submitWeek, {
      headers: header,
    })
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status_code === 1) {
          return { success: true, data: res.data };
        } else {
          return { success: true, data: res.data };
        }
      }
    })
    .catch((error) => {
      const errorMessage = throwError(error);
      return { success: false, data: errorMessage };
    });
}

export async function tagAsBaseline(id) {
  const header = Header();

  return axios
    .post(
      API_URL_CONSTANTS.TAG_BASELINE + `${id}`,
      {},
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status_code === 1) {
          return { success: true, data: res.data };
        } else {
          return { success: true, data: res.data };
        }
      }
    })
    .catch((error) => {
      const errorMessage = throwError(error);
      return { success: false, data: errorMessage };
    });
}

export async function removeBaseline(id) {
  const header = Header();

  return axios
    .post(
      API_URL_CONSTANTS.REMOVE_BASELINE + `${id}`,
      {},
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status_code === 1) {
          return { success: true, data: res.data };
        } else {
          return { success: true, data: res.data };
        }
      }
    })
    .catch((error) => {
      const errorMessage = throwError(error);
      return { success: false, data: errorMessage };
    });
}
