import React, { useContext, useEffect, useState } from "react";
import { CoachCalendarData } from "utility/constants/useContext";
import RepeatIcon from "assets/icons/repeat_icon.svg";
import SaveBtn from "components/button/SaveBtn";
import { AddTemplateMultiWorkout, AddTemplateWorkout } from "api/CoachCalendar";
import { format, getISODay } from "date-fns";
import { formatTime, activityNameShortToFull } from "hooks/convertValues";

function TemplateSteps({ setExistingWorkout }) {
  const Props = useContext(CoachCalendarData);
  const [load, setLoad] = useState(false);
  const [stepsData, setStepsData] = useState(Props.templateSteps);
  const [showSteps, setShowSteps] = useState(false);

  useEffect(() => {
    setStepsData(Props.templateSteps);
  }, [Props.templateSteps]);

  function updateCalendarList(workout_detail) {
    const updateWorkout_detail = {
      id: workout_detail.id,
      label: workout_detail.label,
      workout_type: workout_detail.workout_type,
      brief_information: workout_detail.brief_information,
      synced_with_device: workout_detail.synced_with_device,
      day: workout_detail.day,
      is_completed: workout_detail.activity_status,
      calender_event_type: 1,
      is_baseline: workout_detail.is_baseline,
      is_singlestep_dist: workout_detail.is_singlestep_dist,
    };
    const formattedDate = format(new Date(Props.eventDate), "dd-MM-yyyy");
    const updatedCalendarData = { ...Props.calendarData }; // Create a  copy
    updatedCalendarData.day_workouts = updatedCalendarData.day_workouts.map(
      (day) => {
        if (day.workout_date === formattedDate) {
          return {
            ...day,
            workouts: [...day.workouts, updateWorkout_detail],
          };
        }

        return { ...day }; // Return updated wrkout
      }
    );

    Props.setCalendarData(updatedCalendarData);
  }

  function updateCalendarListMulti(workout_detail) {
    // Map through each workout detail and update its structure
    const updatedWorkoutDetails = workout_detail.map((workout) => ({
      id: workout.id,
      label: workout.label,
      workout_type: workout.workout_type,
      brief_information: workout.brief_information,
      synced_with_device: workout.synced_with_device,
      day: workout.day,
      is_completed: workout.activity_status,
      calender_event_type: 1, // Assuming this is a static value
    }));

    const formattedDate = format(new Date(Props.eventDate), "dd-MM-yyyy");
    const updatedCalendarData = { ...Props.calendarData }; // Create a copy

    updatedCalendarData.day_workouts = updatedCalendarData.day_workouts.map(
      (day) => {
        if (day.workout_date === formattedDate) {
          return {
            ...day,
            workouts: [...day.workouts, ...updatedWorkoutDetails], // Append all updated workouts
          };
        }
        return { ...day }; // Return day as is if the date doesn't match
      }
    );

    Props.setCalendarData(updatedCalendarData);
  }

  async function handleAddWorkout() {
    if (
      Props.templateSteps.workout_type === "BRICK" ||
      Props.templateSteps.workout_type === "TRI"
    ) {
      try {
        setLoad(true); // Show loader
        if (!Props.eventDate) throw new Error("Invalid event date");
        const formattedDate = format(new Date(Props.eventDate), "dd-MM-yyyy");
        const dayOfWeek = getISODay(new Date(Props.eventDate));
        const syncDevice = stepsData.sync_device;
        // API Call
        const res = await AddTemplateMultiWorkout(
          Props.athleteId,
          formattedDate,
          Props.templateId,
          dayOfWeek,
          syncDevice
        );
        // Handle Response
        if (res?.success) {
          Props.setAddWorkout(false); // Close the modal or UI section
          updateCalendarListMulti(res.data.data.workout_detail);
          Props.setTemplateId("");
          setExistingWorkout(false);
        } else {
          Props.setErrorPopup(true);
          Props.setErrorHeader("Something went wrong!");
          Props.setErrorMessage(res?.data || "Unable to Add Workout");
        }
      } catch (error) {
        Props.setErrorPopup(true);
        Props.setErrorHeader("Something went wrong!");
        Props.setErrorMessage("Something went wrong. Please try again.");
      } finally {
        setLoad(false); // Hide loader
      }
    } else {
      try {
        setLoad(true); // Show loader
        if (!Props.eventDate) throw new Error("Invalid event date");
        const formattedDate = format(new Date(Props.eventDate), "dd-MM-yyyy");
        const dayOfWeek = getISODay(new Date(Props.eventDate));
        const syncDevice = stepsData.sync_device;
        // API Call
        const res = await AddTemplateWorkout(
          Props.athleteId,
          formattedDate,
          Props.templateId,
          dayOfWeek,
          syncDevice
        );
        // Handle Response
        if (res?.success) {
          updateCalendarList(res.data.data.workout_detail);
          Props.setAddWorkout(false); // Close the modal or UI section
          Props.setTemplateId("");
          setExistingWorkout(false);
        } else {
          Props.setErrorPopup(true);
          Props.setErrorHeader("Something went wrong!");
          Props.setErrorMessage(res?.data || "Unable to Add Workout");
        }
      } catch (error) {
        Props.setErrorPopup(true);
        Props.setErrorHeader("Something went wrong!");
        Props.setErrorMessage("Something went wrong. Please try again.");
      } finally {
        setLoad(false); // Hide loader
      }
    }
  }

  const targetMapping = {
    PC: "Pace",
    PZ: "Power Zone",
    CA: "Cadence",
    HR: "HR -",
  };

  if (stepsData === undefined || stepsData === null) {
    return;
  }
  if (stepsData.length <= 0) {
    return;
  }

  if (stepsData?.steps === undefined) {
    return (
      <div className="mt-2 ckmsdkcmsdiocmsdoicmsdoicmsdoicmoic">
        {stepsData.workouts.map((workout, index) => (
          <div key={`wokout${index}`}>
            <div className="bg-white py-2 mb-2 px-1 rounded-[3px] cursor-pointer ">
              <p className="text-[var(--blue)]">{workout.long_name}</p>
              <div>
                {workout.steps.map((steps, index) => {
                  const isLastObject = workout.steps.length;

                  return (
                    <div key={`repeatsssss--${index}`}>
                      <div className="flex flex-col w-full bg-[var(--white)] border_top_radius calendar_border mt-2 pb-2">
                        {steps.no_of_repeats > 1 ? (
                          <>
                            {index % 2 === 0 ? (
                              <div className="flex items-center bg-[#b2f7ef]  mb-2 border_top_radius px-1">
                                <img className="h-3" src={RepeatIcon} alt="" />
                                <p>{steps.no_of_repeats}</p>
                              </div>
                            ) : (
                              <div className="flex items-center bg-[#d1f7f3]  mb-2 border_top_radius px-1">
                                <img className="h-3" src={RepeatIcon} alt="" />
                                <p>{steps.no_of_repeats}</p>
                              </div>
                            )}
                          </>
                        ) : null}

                        {/* Use this map for card info in the box e.g., wup, z1, time, distance */}
                        {steps.activity_steps &&
                          steps.activity_steps.map(
                            (ActivitySteps, ActivityStepsIndex) => {
                              const formattedActivity = activityNameShortToFull(
                                ActivitySteps.activity
                              );

                              let res;
                              if (
                                ActivitySteps.time &&
                                ActivitySteps.time.includes(":")
                              ) {
                                res = formatTime(ActivitySteps.time);
                              }

                              return (
                                <div key={`activeStepss-${ActivityStepsIndex}`}>
                                  {/* Card heading color */}

                                  {ActivitySteps.activity === "WUP" ? (
                                    <div className="bg-[#FE5F55] py-[.2rem] mb-2 border_top_radius"></div>
                                  ) : (
                                    <>
                                      {ActivitySteps.activity === "CD" ? (
                                        <div className="bg-[#bfd200] py-[.2rem] mb-2 border_top_radius"></div>
                                      ) : (
                                        <>
                                          {steps.no_of_repeats === 1 ? (
                                            <>
                                              {index % 2 === 0 ? (
                                                <div className=" bg-[#b2f7ef] py-[.2rem] mb-2 border_top_radius"></div>
                                              ) : (
                                                <div className=" bg-[#d1f7f3]  py-[.2rem] mb-2 border_top_radius"></div>
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}

                                  {/* Workout steps  */}
                                  <div className="flex justify-between w-full px-1 text-[var(--blue)] font-medium flex-warp">
                                    <span className="px-1">
                                      {formattedActivity ||
                                        ActivitySteps.activity}
                                    </span>

                                    {/* Ensure to safely access properties */}
                                    <span className="px-1">
                                      {(targetMapping[
                                        ActivitySteps.primaryTarget?.target
                                      ] ?? "") +
                                        " " +
                                        (ActivitySteps.primaryTarget?.value ??
                                          "")}
                                    </span>

                                    {/* Distance and time */}
                                    <div className="flex flex-col items-end px-1">
                                      {ActivitySteps.time && (
                                        <span>
                                          {res || ActivitySteps.time || ""}
                                        </span>
                                      )}
                                      {ActivitySteps.distance > 0 && (
                                        <>
                                          <span className="text-right">
                                            {ActivitySteps.distance} m
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ))}

        {/* Buttons */}
        <div className="flex justify-end mt-5">
          <button
            className="cancelButton "
            onClick={() => {
              Props.setAddWorkout(false);
              Props.setTemplateId("");
            }}
          >
            Cancel
          </button>
          <SaveBtn
            load={load}
            handleSave={handleAddWorkout}
            action="Add "
            //   isDisable={isDisable}
            className="saveButton"
          />
        </div>
      </div>
    );
  }
  // for brick and tri workouts
  if (stepsData?.steps) {
    return (
      <div>
        {/* Training Steps Start */}
        {stepsData?.steps?.map((steps, index) => {
          const isLastObject = stepsData.steps.length;

          return (
            <div key={`repeatsssss--${index}`}>
              <div className="flex flex-col w-full bg-[var(--white)] border_top_radius calendar_border mt-2 pb-2">
                {steps.no_of_repeats > 1 ? (
                  <>
                    {index % 2 === 0 ? (
                      <div className="flex items-center bg-[#b2f7ef]  mb-2 border_top_radius px-1">
                        <img className="h-3" src={RepeatIcon} alt="" />
                        <p>{steps.no_of_repeats}</p>
                      </div>
                    ) : (
                      <div className="flex items-center bg-[#d1f7f3]  mb-2 border_top_radius px-1">
                        <img className="h-3" src={RepeatIcon} alt="" />
                        <p>{steps.no_of_repeats}</p>
                      </div>
                    )}
                  </>
                ) : null}

                {/* Use this map for card info in the box e.g., wup, z1, time, distance */}
                {steps.activity_steps &&
                  steps.activity_steps.map(
                    (ActivitySteps, ActivityStepsIndex) => {
                      const formattedActivity = activityNameShortToFull(
                        ActivitySteps.activity
                      );

                      let res;
                      if (
                        ActivitySteps.time &&
                        ActivitySteps.time.includes(":")
                      ) {
                        res = formatTime(ActivitySteps.time);
                      }

                      return (
                        <div key={`activeStepss-${ActivityStepsIndex}`}>
                          {/* Card heading color */}

                          {ActivitySteps.activity === "WUP" ? (
                            <div className="bg-[#FE5F55] py-[.2rem] mb-2 border_top_radius"></div>
                          ) : (
                            <>
                              {ActivitySteps.activity === "CD" ? (
                                <div className="bg-[#bfd200] py-[.2rem] mb-2 border_top_radius"></div>
                              ) : (
                                <>
                                  {steps.no_of_repeats === 1 ? (
                                    <>
                                      {index % 2 === 0 ? (
                                        <div className=" bg-[#b2f7ef] py-[.2rem] mb-2 border_top_radius"></div>
                                      ) : (
                                        <div className=" bg-[#d1f7f3]  py-[.2rem] mb-2 border_top_radius"></div>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {/* Workout steps  */}
                          <div className="flex justify-between w-full px-1 text-[var(--blue)] font-medium flex-warp">
                            <span className="px-1">
                              {formattedActivity || ActivitySteps.activity}
                            </span>

                            {/* Ensure to safely access properties */}
                            <span className="px-1">
                              {(targetMapping[
                                ActivitySteps.primaryTarget?.target
                              ] ?? "") +
                                " " +
                                (ActivitySteps.primaryTarget?.value ?? "")}
                            </span>

                            {/* Distance and time */}
                            <div className="flex flex-col items-end px-1">
                              {ActivitySteps.time && (
                                <span>{res || ActivitySteps.time || ""}</span>
                              )}
                              {ActivitySteps.distance > 0 && (
                                <>
                                  <span className="text-right">
                                    {ActivitySteps.distance} m
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          );
        })}
        {/* Training Steps End */}

        {/* Buttons */}
        <div className="flex justify-end mt-5">
          <button
            className="cancelButton"
            onClick={() => {
              Props.setAddWorkout(false);
              Props.setTemplateId("");
            }}
          >
            Cancel
          </button>
          <SaveBtn
            load={load}
            handleSave={handleAddWorkout}
            action="Add "
            //   isDisable={isDisable}
            className="saveButton"
          />
        </div>
      </div>
    );
  }
}

export default TemplateSteps;
