import React, { useContext, useState } from "react";

import {
  eachDayOfInterval,
  endOfISOWeek,
  startOfISOWeek,
  format,
} from "date-fns";
import { CoachCalendarData } from "utility/constants/useContext";
import CoachEventCalendar from "./CoachEventCalendar";
import ActionPopup from "components/popups/ActionPopup";
import Tooltip from "./Tooltip";
import { DeleteWorkout } from "api/CoachCalendar";

function CoachCalendarWeek({
  calendarDay,
  calendarData,
  setRefId,
  setStpesData,
  setTrainingSilderDate,
  setClick,
  setAddWorkout,
  setAddEvent,
  handleDateClick,
  isRightClick,
  setIsRightClick,
  tooltip,
  setTooltip,
  setShowEdit,
  setShowDelete,
  setShowReschdule,
  hideTooltip,
  showEdit,
  showDelete,
  showReschdule,
  setClosePopup,
  closePopup,
  handleTouchStart,
  handleTouchEnd,
}) {
  const currentDate = new Date();
  const Props = useContext(CoachCalendarData);
  const [load, setLoad] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  // const EventDate = Props.eventDate; // event date
  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const firstDayOfWeek = startOfISOWeek(currentDate);
  const lastDayOfWeek = endOfISOWeek(currentDate);

  const dayesInWeek = eachDayOfInterval({
    start: firstDayOfWeek,
    end: lastDayOfWeek,
  }); // Array of days in the current Week

  async function handleRemove() {
    if (load === true) {
      return;
    }
    setLoad(true);
    const res = await DeleteWorkout(Props.refId);
    if (res.success) {
      setLoad(false);
      setDeletePopup(false);
      Props.setClick(false);
      Props.setUpdateCalendar(res);
    } else {
      Props.setErrorPopup(true);
      Props.setErrorHeader("Something went wrong!");
      Props.setErrorMessage(res?.data || "Unable to Delete Workout");
    }
  }

  return (
    <div className="">
      {closePopup && (
        <div
          onClick={() => {
            setTooltip({ ...tooltip, visible: false });
            setIsRightClick(false);
            setClosePopup(false);
          }}
          className="fixed w-full h-full bg-transparent z-40"
        ></div>
      )}
      {deletePopup && (
        <>
          <div className="delete_blur"></div>
          <ActionPopup
            message={{
              heading: `${
                Props.calenderEventType === 1
                  ? "Delete Workout"
                  : "Delete Event"
              }`,
              message: `Do you really want to delete this ${
                Props.calenderEventType === 1 ? " Workout" : " Event"
              } ?`,
            }}
            setDeletePopup={setDeletePopup}
            handleRemove={handleRemove}
            load={load}
          />
        </>
      )}
      <div className="bg-transparent grid grid-cols-7 border border-[#DBDBDB] border-solid ">
        {weekdays.map((week, index) => (
          <p
            className="text-center text-[var(--blue)] m-0 text-[1.2rem] bg-[#EAEAEA] border-b-[.5px] border-r-[.5px] border-[#DBDBDB] border-solid"
            key={index}
          >
            {week}
          </p>
        ))}
        {dayesInWeek.map((date, index) => (
          <div
            className={`disable-long-press  text-left h-[8rem] m-0 text-[1.2rem]  border-b-[.5px] border-r-[.5px] border-[#DBDBDB] border-solid min-h-[70vh] ${
              format(date, "d") === format(currentDate, "d")
                ? "border-[1px] border-solid border-[var(--blue)]"
                : ""
            }`}
            key={`date-${index}`}
            onClick={(e) => handleDateClick(e, date)}
            onContextMenu={(e) => handleDateClick(e, date)}
            onTouchStart={(e) => handleTouchStart(e, date)} // Handle long press start
            onTouchEnd={(e) => handleTouchEnd(e, date)} // Clear long press
          >
            <p className="p-2 pb-1">{format(date, "d")}</p>
            <div className="flex-grow  flex flex-col justify-center px-1 coach-event">
              <CoachEventCalendar
                calendarDay={format(date, "dd-MM-yyyy")}
                calendarData={calendarData}
                setRefId={setRefId}
                setStpesData={setStpesData}
                setTrainingSilderDate={setTrainingSilderDate}
                setClick={setClick}
                setAddWorkout={setAddWorkout}
                setAddEvent={setAddEvent}
                isRightClick={isRightClick}
                setIsRightClick={setIsRightClick}
                tooltip={tooltip}
                setTooltip={setTooltip}
                setShowEdit={setShowEdit}
                setShowDelete={setShowDelete}
                setShowReschdule={setShowReschdule}
                setClosePopup={setClosePopup}
              />
            </div>
          </div>
        ))}
      </div>
      {/* Tooltip */}
      <Tooltip
        tooltip={tooltip}
        setTooltip={setTooltip}
        setDeletePopup={setDeletePopup}
        hideTooltip={hideTooltip}
        isRightClick={isRightClick}
        setIsRightClick={setIsRightClick}
        showEdit={showEdit}
        showDelete={showDelete}
        showReschdule={showReschdule}
        setClick={setClick}
        setAddEvent={setAddEvent}
        setAddWorkout={setAddWorkout}
        setClosePopup={setClosePopup}
      />
    </div>
  );
}

export default CoachCalendarWeek;
