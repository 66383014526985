import React, { useContext } from "react";
import { CoachCalendarData } from "utility/constants/useContext";
import AddWorkoutIcon from "assets/icons/CoachCalendar/AddWorkout.svg";
import AddEventIcon from "assets/icons/CoachCalendar/AddEvent.svg";
import PasteIcon from "assets/icons/CoachCalendar/Paste.svg";
import EditIcon from "assets/icons/editIcon.svg";
import Reschedule from "assets/icons/CoachCalendar/Reschedule.svg";
import CopyIcon from "assets/icons/CoachCalendar/Copy.svg";
import CutIcon from "assets/icons/CoachCalendar/Cut.svg";
import DeleteIcon from "assets/icons/CoachCalendar/RedDelete.svg";
import { cutCopy, tagAsBaseline, removeBaseline } from "api/CoachCalendar";
import BlueBaselineIcon from "assets/icons/CoachCalendar/BlueBaseline.svg";
import BlueEvent from "assets/icons/CoachCalendar/BlueEvent.svg";

function Tooltip({
  tooltip,
  setTooltip,
  setDeletePopup,
  hideTooltip,
  isRightClick,
  setIsRightClick,
  showEdit,
  showDelete,
  showReschdule,
  setClick,
  setAddEvent,
  setAddWorkout,
  setClosePopup,
}) {
  const Props = useContext(CoachCalendarData);

  //------------------------------------------------ update the calendar list
  function updateCalendarList(workout_detail) {
    const updateWorkout_detail = {
      id: workout_detail.id,
      label: workout_detail.label,
      workout_type: workout_detail.workout_type,
      brief_information: workout_detail.brief_information,
      synced_with_device: workout_detail.synced_with_device,
      day: workout_detail.day,
      is_completed: workout_detail.activity_status,
      calender_event_type: Props.calenderEventType,
      is_baseline: workout_detail.is_baseline,
      is_singlestep_dist: workout_detail.is_singlestep_dist,
    };

    // const Props.planEventDate = format(Props.planEventDate, "dd-MM-yyyy");
    const updatedCalendarData = { ...Props.calendarData }; // Create a copy of calendar data

    if (Props.isCut) {
      updatedCalendarData.day_workouts = updatedCalendarData.day_workouts.map(
        (day) => {
          // If the workout exists on this date detele the wkout
          if (
            day.workouts.some((workout) => workout.id === workout_detail.id)
          ) {
            return {
              ...day,
              workouts: day.workouts.filter(
                (workout) => workout.id !== workout_detail.id
              ),
            };
          }

          //  reschedule workout
          if (day.workout_date === Props.planEventDate) {
            return {
              ...day,
              workouts: [...day.workouts, updateWorkout_detail],
            };
          }

          return day;
        }
      );

      // If the reschedule date doesn't exist in `day_workouts`, add it
      const rescheduleDayExists = updatedCalendarData.day_workouts.some(
        (day) => day.workout_date === Props.planEventDate
      );

      if (!rescheduleDayExists) {
        updatedCalendarData.day_workouts.push({
          workout_date: Props.planEventDate,
          training_plan_week: "", // Add the appropriate value if needed
          workouts: [updateWorkout_detail],
          training_plan_name: "", // Add the appropriate value if needed
        });
      }

      Props.setCalendarData(updatedCalendarData);
    } else {
      updatedCalendarData.day_workouts = updatedCalendarData.day_workouts.map(
        (day) => {
          //  reschedule workout
          if (day.workout_date === Props.planEventDate) {
            return {
              ...day,
              workouts: [...day.workouts, updateWorkout_detail],
            };
          }

          return day;
        }
      );

      // If the reschedule date doesn't exist in `day_workouts`, add it
      const rescheduleDayExists = updatedCalendarData.day_workouts.some(
        (day) => day.workout_date === Props.planEventDate
      );

      if (!rescheduleDayExists) {
        updatedCalendarData.day_workouts.push({
          workout_date: Props.planEventDate,
          training_plan_week: "", // Add the appropriate value if needed
          workouts: [updateWorkout_detail],
          training_plan_name: "", // Add the appropriate value if needed
        });
      }

      Props.setCalendarData(updatedCalendarData);
    }
  }

  // Delete popup
  function handleDelete() {
    setClosePopup(false);
    setTooltip({ ...tooltip, visible: false });
    setIsRightClick(false);
    setDeletePopup(true);
  }

  // -------------------------------------------------------- handle copy --------------------------------------
  const handleCopy = (keep_original) => {
    const CutCopyData = {
      keep_original: `${keep_original}`, // 0 for cut 1 for copy
      from: {
        user: Props.athleteId,
        date: Props.planEventDate,
        days: 1, // 1 for copy and cut 7 for week
        id: Props.refId, // workout_id if week copy = null
      },
      to: {
        user: 0, // user_ref
        date: "",
      },
    };

    setClosePopup(false);
    setTooltip({ ...tooltip, visible: false });
    setIsRightClick(false);
    if (keep_original === 0) {
      Props.setCut(CutCopyData);
    } else {
      Props.setCopy(CutCopyData);
    }
  };

  // -------------------------------------------- handle paste ---------------------------------------------------
  const handlePaste = async () => {
    let data;
    if (Props.isCopy) {
      data = Props.isCopy;
    } else {
      data = Props.isCut;
    }

    const submitCopy = {
      keep_original: data.keep_original,
      from: {
        user: data.from.user,
        date: data.from.date,
        days: data.from.days,
        id: data.from.id,
      },
      to: {
        user: Props.athleteId, // user_ref
        date: Props.planEventDate,
      },
    };

    const res = await cutCopy(submitCopy);
    if (res.success === true) {
      setClosePopup(false);
      setTooltip({ ...tooltip, visible: false });
      setIsRightClick(false);
      Props.setPaste(false);
      Props.setCopy();
      Props.setCut();
      updateCalendarList(res.data.workout_detail);
      // Props.setUpdateCalendar(res.data);
    } else {
      Props.setErrorPopup(true);
      Props.setErrorHeader("Something went wrong!");
      Props.setErrorMessage(res?.data || "Try again later");
    }
  };

  // ----------------------------- set baseline

  const handleSetBaseline = async () => {
    const res = await tagAsBaseline(Props.refId);
    if (res.success === true) {
      Props.setUpdateCalendar(res.data);
      setClosePopup(false);
      setTooltip({ ...tooltip, visible: false });
      setIsRightClick(false);
    }
  };

  const handleRemoveBaseline = async () => {
    const res = await removeBaseline(Props.refId);

    if (res.success === true) {
      Props.setUpdateCalendar(res.data);
      setClosePopup(false);
      setTooltip({ ...tooltip, visible: false });
      setIsRightClick(false);
    }
  };

  return (
    <div className=" ">
      {tooltip.visible && (
        <div
          className="absolute bg-[#ffe8ace4] Shadow rounded-[3px] tooltip-container  py-1"
          style={{
            position: "absolute",
            top: `${tooltip.position.top}px`,
            left: `${tooltip.position.left}px`,
            zIndex: 50,
          }}
          onMouseLeave={hideTooltip}
        >
          <div className="flex flex-col text-[var(--blue)] ">
            {isRightClick ? (
              <>
                {Props.isPaste ? (
                  <div
                    className="flex items-center cursor-pointer px-2  rounded-[3px]"
                    onClick={() => handlePaste()}
                  >
                    <img className="w-3 h-3 mr-2" src={PasteIcon} alt="" />
                    <p className="hover:underline">Paste</p>
                  </div>
                ) : (
                  <>
                    <div className="py-2 px-2 border_bottom">
                      {showEdit && (
                        <>
                          {Props.calenderEventType === 1 && (
                            <div
                              className="flex items-center cursor-pointer  rounded-[3px]"
                              onClick={() => {
                                setClosePopup(false);
                                Props.setEditWorkout(true);
                                setClick(false);
                                setAddEvent(false);
                                setTooltip({ ...tooltip, visible: false });
                                setIsRightClick(false);
                                window.scrollTo(0, 0);
                              }}
                            >
                              <img
                                className="w-3 h-3 mr-4"
                                src={EditIcon}
                                alt=""
                              />
                              <p className="hover:underline">Edit</p>
                            </div>
                          )}
                        </>
                      )}

                      <div
                        onClick={() => {
                          setClosePopup(false);
                          Props.setReschedule(true);
                          setTooltip({ ...tooltip, visible: false });
                          setIsRightClick(false);
                        }}
                        className="flex items-center cursor-pointer   rounded-[3px]"
                      >
                        <img className="w-3 h-3 mr-4" src={Reschedule} alt="" />
                        <p className="hover:underline">Reschedule</p>
                      </div>
                    </div>
                    <div className="py-2 px-2 border_bottom">
                      <div
                        onClick={() => handleCopy(1)}
                        className="flex items-center cursor-pointer   rounded-[3px]"
                      >
                        <img className="w-3 h-3 mr-4" src={CopyIcon} alt="" />
                        <p className="hover:underline">Copy</p>
                      </div>
                      <div
                        onClick={() => handleCopy(0)}
                        className="flex items-center cursor-pointer   rounded-[3px]"
                      >
                        <img className="w-3 h-3 mr-4" src={CutIcon} alt="" />
                        <p className="hover:underline">Cut</p>
                      </div>
                    </div>
                    {Props.isSingleStepDist && (
                      <div className=" py-2 px-2  border_bottom">
                        {/* <p>Mark a workout as Baselin or Event</p> */}
                        {Props.isBaseline && (
                          <div
                            onClick={() => handleRemoveBaseline()}
                            className="flex items-center cursor-pointer   rounded-[3px]"
                          >
                            <img
                              className="w-3 h-3 mr-4"
                              src={BlueBaselineIcon}
                              alt=""
                            />
                            <p className="hover:underline">Remove Baseline</p>
                          </div>
                        )}

                        {!Props.isBaseline && (
                          <div
                            onClick={() => handleSetBaseline()}
                            className="flex items-center cursor-pointer   rounded-[3px]"
                          >
                            <img
                              className="w-3 h-3 mr-4"
                              src={BlueBaselineIcon}
                              alt=""
                            />
                            <p className="hover:underline">Set Baseline</p>
                          </div>
                        )}

                        {/* <div
                        onClick={() => {
                          setClosePopup(false);
                          setTooltip({ ...tooltip, visible: false });
                          setIsRightClick(false);
                        }}
                        className="flex items-center cursor-pointer   rounded-[3px]"
                      >
                        <img className="w-3 h-3 mr-2" src={BlueEvent} alt="" />
                        <p className="hover:underline">Mark Event</p>
                      </div> */}
                      </div>
                    )}

                    <div
                      onClick={() => {
                        handleDelete();
                      }}
                      className="flex items-center cursor-pointer py-2 px-2   rounded-[3px]"
                    >
                      <img className="w-3 h-3 mr-4" src={DeleteIcon} alt="" />
                      <p className="hover:underline text-[#C71F37] ">
                        {Props.calenderEventType === 3 ? "Delete " : "Delete "}
                      </p>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div
                  className="flex items-center cursor-pointer p-2 rounded-[3px]"
                  onClick={() => {
                    setClosePopup(false);
                    setAddWorkout(true);
                    setClick(false);
                    setAddEvent(false);
                    setTooltip({ ...tooltip, visible: false });
                    window.scrollTo(0, 0);
                  }}
                >
                  <img className="w-4 h-4" src={AddWorkoutIcon} alt="" />
                  <p className="pl-2 hover:underline">Add Workout</p>
                </div>
                <div
                  onClick={() => {
                    setClosePopup(false);
                    setAddEvent(true);
                    setClick(false);
                    setAddWorkout(false);
                    setTooltip({ ...tooltip, visible: false });
                    window.scrollTo(0, 0);
                  }}
                  className="flex items-center cursor-pointer p-2  rounded-[3px]"
                >
                  <img className="w-4 h-4" src={AddEventIcon} alt="" />
                  <p className="pl-2 hover:underline">Add Event</p>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Tooltip;
