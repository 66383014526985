import React, { useContext } from "react";
import CalendarEvents from "./CalendarEvents";
import {
  eachDayOfInterval,
  endOfISOWeek,
  startOfISOWeek,
  format,
} from "date-fns";
import { CalendarData } from "utility/constants/useContext";

function WeekView() {
  const currentDate = new Date();
  const Props = useContext(CalendarData);
  // const EventDate = Props.eventDate; // event date
  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const firstDayOfWeek = startOfISOWeek(currentDate);
  const lastDayOfWeek = endOfISOWeek(currentDate);

  const dayesInWeek = eachDayOfInterval({
    start: firstDayOfWeek,
    end: lastDayOfWeek,
  }); // Array of days in the current Week

  return (
    <div className="">
      <div className="bg-transparent grid grid-cols-7 border border-[#DBDBDB] border-solid ">
        {weekdays.map((week, index) => (
          <p
            className="text-center text-[var(--blue)] m-0 text-[1.2rem] bg-[#EAEAEA] border-b-[.5px] border-r-[.5px] border-[#DBDBDB] border-solid"
            key={index}
          >
            {week}
          </p>
        ))}
        {dayesInWeek.map((date, index) => (
          <div
            className={`text-left h-[8rem] m-0 text-[1.2rem]  border-b-[.5px] border-r-[.5px] border-[#DBDBDB] border-solid min-h-[70vh] ${
              format(date, "d") === format(currentDate, "d")
                ? "border-[1px] border-solid border-[var(--blue)]"
                : ""
            }`}
            key={`date-${index}`}
          >
            <p className="p-2 pb-1">{format(date, "d")}</p>
            <div className="flex-grow  flex flex-col justify-center px-1 ">
              {/*--------------- EVENTS -----------------*/}
              <CalendarEvents calendarDay={format(date, "dd-MM-yyyy")} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WeekView;
