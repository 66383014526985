import React, { useContext, useEffect, useState } from "react";
import NewWorkoutMenu from "./AddWorkout/NewWorkoutMenu";
import WorkoutTemplates from "./WorkoutTemplates";
import AddWorkoutIcon from "assets/icons/AddWorkout.svg";
import { CoachCalendarData } from "utility/constants/useContext";
import RightIcon from "assets/icons/right-arrow.png";
import TemplateSteps from "./AddWorkout/TemplateSteps";
import CloseMenu from "assets/icons/CoachCalendar/close_menu (2).svg";

function AddWorkout({ trainigSliderDate }) {
  const Props = useContext(CoachCalendarData);
  const [isNewWorkout, setNewWorkout] = useState(false);
  const [isExistingWorkout, setExistingWorkout] = useState(false);

  if (isNewWorkout && !isExistingWorkout) {
    return <NewWorkoutMenu setNewWorkout={setNewWorkout} />;
  }

  if (isExistingWorkout && !isNewWorkout) {
    return (
      <div className="min-w-[20%] min-h-full overflow-hidden z-20 py-8">
        <div className="flex flex-col h-full">
          <div className="calendar_border bg-[#F8F8F8] w-full h-full p-4">
            <div className="flex justify-end">
              <p className="text-right text-[var(--black)]">
                {trainigSliderDate}
              </p>
            </div>

            <div
              className="flex items-center px-4 cursor-pointer"
              onClick={() => {
                setExistingWorkout(false);
                Props.setTemplateId("");
              }}
            >
              <img src={RightIcon} className="w-4 h-4 -rotate-180" alt="" />
              <p className="text-[var(--blue)] text-[1.2rem]">
                Existing Workout
              </p>
            </div>

            {Props.templateId ? (
              <TemplateSteps setExistingWorkout={setExistingWorkout} />
            ) : (
              <WorkoutTemplates fullHeight={true} />
            )}
          </div>
        </div>
      </div>
    );
  }

  // Select new workout and existingWorout
  if (!isNewWorkout && !isExistingWorkout) {
    return (
      <>
        <div className="min-w-[20%] min-h-full overflow-hidden z-20 py-8">
          <div className="flex flex-col h-full">
            <div className="calendar_border bg-[#F8F8F8] w-full h-full ">
              <div className="flex justify-between items-center px-4 py-2 cursor-pointer">
                <div
                  className="flex items-center"
                  onClick={() => Props.setAddWorkout(false)}
                >
                  <p className="text-[var(--black)] mr-1">Close</p>
                  <img src={CloseMenu} alt="" />
                </div>
                <p className="text-right text-[var(--black)]">
                  {trainigSliderDate}
                </p>
              </div>

              <div className="flex px-4">
                <img
                  src={AddWorkoutIcon}
                  className="h-[1.2rem] w-auto mr-2"
                  alt=""
                />
                <p className="text-[var(--blue)] text-[1.2rem]">Add Workout</p>
              </div>

              <div className="pt-4 px-4">
                <div
                  onClick={() => setNewWorkout(true)}
                  className="flex flex-col cursor-pointer border-[#ABA9A9] border-[1.5px] p-2 hover:border-[#2260FF] rounded-[3px] mb-2"
                >
                  <p className="text-[var(--blue)] text-[1.1rem]">
                    New Workout
                  </p>
                  <span className="text-[#ABA9A9]">Create your workout </span>
                </div>

                <div
                  onClick={() => setExistingWorkout(true)}
                  className="flex flex-col cursor-pointer border-[#ABA9A9] border-[1.5px] p-2 hover:border-[#2260FF] rounded-[3px]"
                >
                  <p className="text-[var(--blue)] text-[1.1rem]">
                    Existing workout
                  </p>
                  <span className="text-[#ABA9A9]">
                    Add workout from your workout template
                  </span>
                </div>
              </div>

              <div></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddWorkout;
