import axios from "axios";
import { Header } from "api/Signin";
import { clearLocalStorage } from "./basic";
import { throwError } from "./throwErrors";

// export async function getApis(urlPath) {
//   const header = Header();
//   return axios
//     .get(urlPath, {
//       headers: header,
//     })
//     .then((res) => {
//       // log specific url res only => path in string eg:- /files/get/2
//       if (urlPath.includes("change_path_to_log")) {
//         console.log(res);
//       }

//       if (res.data.status_code === 1) {
//         return res;
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//       return error;
//     });
// }

export async function getApis(urlPath) {
  const header = Header();

  try {
    const res = await axios.get(urlPath, { headers: header });

    // log specific url res only => path in string eg:- /files/get/2
    if (urlPath.includes("")) {
    }

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    // const errorMessage = throwError(error);
    // return errorMessage;
    return false;
  }
}

export async function getApisHandledError(urlPath) {
  const header = Header();

  try {
    const res = await axios.get(urlPath, { headers: header });

    // Check for valid response and success condition
    if (res.status === 200) {
      if (res.data.status_code === 1) {
        // Handle successful response
        return { success: true, data: res.data };
      } else {
        // If status_code is not 1, it's considered an error
        return {
          success: false,
          error: `Unexpected response: status_code = ${
            res.data?.status_code || "unknown"
          }`,
        };
      }
    } else {
      // Handle unexpected statuses
      return {
        success: false,
        error: `Unexpected status: ${res.status || "unknown"} - ${
          res.statusText || "No status text"
        }`,
      };
    }
  } catch (error) {
    // Error handling for various cases
    if (error.response) {
      const { status, data } = error.response;
      let errorMessage;

      switch (status) {
        case 400:
          errorMessage =
            "400 Bad Request: " + (data.message || "Invalid input.");
          break;
        case 401:
          clearLocalStorage();
          errorMessage = "401 Unauthorized: Access denied.";
          break;
        case 403:
          errorMessage = "403 Forbidden: You do not have permission.";
          break;
        case 404:
          errorMessage =
            "404 Not Found: The requested resource could not be found.";
          break;
        case 500:
          errorMessage =
            "500 Internal Server Error: " +
            (data.message || "Server is unavailable.");
          break;
        default:
          errorMessage = `Error ${status}: ${
            data.message || "An unknown error occurred."
          }`;
          break;
      }

      return { success: false, error: errorMessage };
    } else if (error.request) {
      // No response received
      return {
        success: false,
        error:
          "No response received from the server. Please check your connection.",
      };
    } else {
      // Error during request setup or other unknown error
      return { success: false, error: `Request error: ${error.message}` };
    }
  }
}
