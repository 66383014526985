import React, { useEffect, useRef, useState } from "react";
import RightArrow from "assets/icons/right-arrow.png";
import MonthIcon from "assets/icons/month_icon.svg";
import WeekIcon from "assets/icons/week_icon.svg";
import { CoachCalendarData } from "utility/constants/useContext";
import SEO from "hooks/Seo";
import { HelmetProvider } from "react-helmet-async";
import CalendarYearView from "./components/CalendarYearView";
import { format, addMonths, subMonths, startOfMonth } from "date-fns";
import AthtleteSearchBox from "./components/AthtleteSearchBox";
import { getApis, getApisHandledError } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import ErrorPopup from "components/popups/ErrorPopup";
import StepsSlider from "./components/StepsSlider";
import EditWorkout from "./EditWorkout";
import MultiWorkout from "./MultiWorkout";
import RightIcon from "assets/icons/right-arrow.png";
import axios from "axios";
import { Header } from "api/Signin";
import { ZonesFullToShort } from "hooks/convertValues";

function CoachCalendarView() {
  const currentDate = new Date();
  const oneYearLater = addMonths(currentDate, 12); // Current date + 1 year
  const FromDate = useRef("");
  const ToDate = useRef("");
  const [click, setClick] = useState(false); // Check the slider is clicked or not
  const [mView, setMview] = useState(true); // month view or week view
  const [refId, setRefId] = useState(); // refId for particular workout
  const [stepsData, setStpesData] = useState({}); // steps data
  const [trainigSliderDate, setTrainingSilderDate] = useState(""); // formated date to display
  const [showAthleteSearch, setShowAthleteSearch] = useState(false); // athlete select box
  const [currentMonth, setCurrentMonth] = useState(startOfMonth(currentDate));
  const [athletedata, setAthleteData] = useState([]);
  const [athleteId, setAthleteId] = useState(""); // athlete id for particular athlete
  const [calendarData, setCalendarData] = useState([]); // calendar data
  const [athleteName, setAthleteName] = useState(""); // athlete name for particular athlete
  const [showMenu, setShowMenu] = useState(false); // State to toggle the menu
  const [isAddWorkout, setAddWorkout] = useState(false); // State to toggle the addworkout popup
  const [isAddEvent, setAddEvent] = useState(false); // State to toggle the add event popup
  const [isEditWorkout, setEditWorkout] = useState(false); // set editworkout page
  const [eventDate, setEventDate] = useState("");
  const [updateCalendar, setUpdateCalendar] = useState(""); // update calendar data
  const [calenderEventType, setCalenderEventType] = useState();
  const [isReschedule, setReschedule] = useState(false); // check reschedule or not
  const [planEventDate, setPlanEventDate] = useState(""); // plan date for event
  const [isWorkoutCompleted, setWorkoutCompleted] = useState(false); // check if workout is completed or not
  const [templateId, setTemplateId] = useState(""); // template id for particular template
  const [templateSteps, setTemplateSteps] = useState([]);
  const [submitSteps, setSubmitSteps] = useState({}); // copy to edit steps
  const [submitMultiSteps, setSubmitMultiSteps] = useState([]); // copy to edit multi steps
  const [editWorkoutClicked, setEditWorkoutClicked] = useState(false); // check edit workout is clicked or not
  const [newWorkoutSelected, setNewWorkoutSelected] = useState(false); //check if new workout is selected or not (baseline, tempo etc..)
  const [multiWorkout, setmultiWorkout] = useState(false);
  const [brickSelectionOne, setBrickSelectionOne] = useState(""); // Track the selected value for Brick
  const [brickSelectionTwo, setBrickSelectionTwo] = useState(""); // Track the selected value for Brick
  const [multiWorkoutType, setMultiWorkoutType] = useState(); // workout type for Multi workout
  const [Zones, setZones] = useState();
  const [isCopy, setCopy] = useState();
  const [isCut, setCut] = useState();
  const [isPaste, setPaste] = useState(false);
  const [isCopyWeek, setCopyWeek] = useState();
  const [isShiftWeek, setShiftWeek] = useState();
  const [isPasteWeek, setPasteWeek] = useState(false);
  const [weekDateOpacity, setWeekDateOpacity] = useState("");
  const [isBaseline, setBaseline] = useState(false);
  const [isSingleStepDist, setSingleStepDist] = useState(false);

  const stepsEndRef = useRef(null);

  const [errorPopup, setErrorPopup] = useState(false); // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Function to scroll to steps added
  const scrollToBottom = () => {
    if (stepsEndRef.current) {
      stepsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // UseContext object
  const CoachcalendarPoprs = {
    click,
    setClick,
    mView,
    setMview,
    calendarData,
    refId,
    setRefId,
    stepsData,
    setStpesData,
    FromDate,
    ToDate,
    trainigSliderDate,
    setTrainingSilderDate,
    showAthleteSearch,
    setShowAthleteSearch,
    currentMonth,
    setCurrentMonth,
    athletedata,
    setAthleteData,
    athleteId,
    setAthleteId,
    athleteName,
    setAthleteName,
    showMenu,
    setShowMenu,
    isAddWorkout,
    setAddWorkout,
    isAddEvent,
    setAddEvent,
    isEditWorkout,
    setEditWorkout,
    eventDate,
    setEventDate,
    setUpdateCalendar,
    calenderEventType,
    setCalenderEventType,
    isReschedule,
    setReschedule,
    planEventDate,
    setPlanEventDate,
    isWorkoutCompleted,
    setWorkoutCompleted,
    templateId,
    setTemplateId,
    templateSteps,
    setTemplateSteps,
    submitSteps,
    setSubmitSteps,
    editWorkoutClicked,
    setEditWorkoutClicked,
    stepsEndRef,
    scrollToBottom,
    newWorkoutSelected,
    setNewWorkoutSelected,
    multiWorkout,
    setmultiWorkout,
    submitMultiSteps,
    setSubmitMultiSteps,
    brickSelectionOne,
    setBrickSelectionOne,
    brickSelectionTwo,
    setBrickSelectionTwo,
    multiWorkoutType,
    setMultiWorkoutType,
    calendarData,
    setCalendarData,
    errorPopup,
    setErrorPopup,
    errorHeader,
    setErrorHeader,
    errorMessage,
    setErrorMessage,
    Zones,
    setZones,
    convertZones,
    isCopy,
    setCopy,
    isCut,
    setCut,
    isPaste,
    setPaste,
    isCopyWeek,
    setCopyWeek,
    isPasteWeek,
    setPasteWeek,
    weekDateOpacity,
    setWeekDateOpacity,
    isShiftWeek,
    setShiftWeek,
    isBaseline,
    setBaseline,
    isSingleStepDist,
    setSingleStepDist,
  };

  // set past true when copy and cut have data
  useEffect(() => {
    if (isCopy || isCut) {
      setPaste(true);
    }
  }, [isCopy, isCut]);

  // Get Athlete list
  useEffect(() => {
    const fetchCalendarList = async () => {
      const res = await getApisHandledError(
        API_URL_CONSTANTS.GET_ATHLETES_LIST
      );

      if (res.success) {
        setAthleteData(res.data.personal);
      } else {
        // Handle the error and trigger the popup
        setErrorPopup(true);
        setErrorHeader("Something went wrong!");
        setErrorMessage(res.error || "Unable to fetch Athlete list.");
      }
    };

    fetchCalendarList();
  }, [showAthleteSearch]);

  useEffect(() => {
    const getZones = async () => {
      const header = Header();
      try {
        const res = await axios.get(API_URL_CONSTANTS.GET_ZONES, {
          headers: header,
          params: {
            ref: athleteId,
          },
        });

        if (res.status === 200) {
          setZones(res.data);
        } else {
          console.error(`Unexpected status code: ${res.status}`);
        }
      } catch (error) {
        console.error("Error fetching zones:", error);
      }
    };
    getZones();
  }, [athleteId]);

  // Get Calendar data
  useEffect(() => {
    setUpdateCalendar("");
    const fetchCalendarList = async () => {
      const res = await getApisHandledError(
        API_URL_CONSTANTS.GET_COACH_CALENDAR_DATA + `${athleteId}`
      );
      if (res.success) {
        setCalendarData(res.data);
      } else {
        setErrorPopup(true);
        setErrorHeader("Something went wrong!");
        setErrorMessage(res.error || "Error fetching calendar list.");
      }
    };
    if (athleteId) {
      fetchCalendarList();
    }
  }, [athleteId, updateCalendar]);

  const handleNextMonth = () => {
    const nextMonth = addMonths(currentMonth, 1);
    if (nextMonth <= oneYearLater) {
      setCurrentMonth(nextMonth);
    }
  };

  const handlePrevMonth = () => {
    const prevMonth = subMonths(currentMonth, 1);
    if (prevMonth >= startOfMonth(currentDate)) {
      setCurrentMonth(prevMonth);
    }
  };

  function convertZones(workout_category, target_type, level) {
    // Search for the matching workout category and target type

    const target = ZonesFullToShort(target_type);
    let workoutType;
    if (workout_category) {
      workoutType = workout_category.toUpperCase();
    }

    if (target === "HR" || target === "PZ") {
      const Level = parseInt(level, 10);
      const matchedCategory = Zones.content.find(
        (zone) =>
          zone.workout_category === workoutType && zone.target_type === target
      );

      // If a matching category is found, proceed to search for the level
      if (matchedCategory) {
        const matchedLevel = matchedCategory.zones.find(
          (zone) => zone.level === Level
        );

        // If a matching level is found, create the name and limit values
        if (matchedLevel) {
          const name = `${matchedLevel.name}`;
          const limit = `(${matchedLevel.lower_limit} - ${matchedLevel.upper_limit})`;
          return { name, limit };
        }
      }
    } else if (target === "PC") {
      const [minF, minS] = level.split("~").map(parseFloat);

      // Convert minF and minS
      const dividedMinF = 1000 / minF;
      const dividedMinS = 1000 / minS;

      // Process dividedMinF
      const minFMinutes = Math.floor(dividedMinF / 60); // Integer part for minutes
      const minFSeconds = Math.round(dividedMinF % 60); // Remainder for seconds
      const minFFormatted =
        minFSeconds < 10
          ? `${minFMinutes}:0${minFSeconds}`
          : `${minFMinutes}:${minFSeconds}`;

      // Process dividedMinS
      const minSMinutes = Math.floor(dividedMinS / 60); // Integer part for minutes
      const minSSeconds = Math.round(dividedMinS % 60); // Remainder for seconds
      const minSFormatted =
        minSSeconds < 10
          ? `${minSMinutes}:0${minSSeconds}`
          : `${minSMinutes}:${minSSeconds}`;

      // Use the formatted values
      const limit = `${minFFormatted} to ${minSFormatted}`;
      const name = "";
      return { name, limit };
    }

    // Return null if no match is found
    return null;
  }

  return (
    <HelmetProvider>
      <SEO
        title="Calendar | Trackofit"
        descriptionName="Calendar"
        description="Training Calendar"
        canonicalUrl="https://www.trackofit.com"
      />
      <CoachCalendarData.Provider value={CoachcalendarPoprs}>
        {errorPopup && (
          <>
            <div className="blur"></div>
            <ErrorPopup
              message={{
                heading: errorHeader,
                message: errorMessage,
              }}
              setErrorPopup={setErrorPopup}
            />
          </>
        )}
        {isEditWorkout ? (
          <>
            {multiWorkout ? (
              <MultiWorkout
                setSubmitSteps={setSubmitSteps}
                submitSteps={submitSteps}
              />
            ) : (
              <EditWorkout
                setSubmitSteps={setSubmitSteps}
                submitSteps={submitSteps}
              />
            )}
          </>
        ) : (
          <>
            {/*------------------------- close athlete search box -------------------------------------*/}
            {showAthleteSearch && (
              <div
                className="fixed w-full h-[100vh] bg-transparent z-10"
                onClick={() => setShowAthleteSearch(false)}
              ></div>
            )}

            {/*------------------------------------ Close Steps slider ------------------------------*/}
            {/* {click || isAddWorkout || isAddEvent || isReschedule ? (
              <div
                className="fixed w-full h-[100vh] bg-transparent z-10"
                onClick={() => {
                  setClick(false);
                  setAddWorkout(false);
                  setAddEvent(false);
                  setShowMenu(false);
                  setReschedule(false);
                  setTemplateId("");
                  setSubmitSteps({});
                }}
              ></div>
            ) : (
              <></>
            )} */}

            <div className="fixed w-full mt-16 z-30 flex justify-between py-[1rem] px-4 bg-white shadow-sm ">
              <div className="flex items-center border-[#DBDBDB] border-[.5px] border-solid p-1 rounded-[3px]">
                {/* Month toggel */}
                <div className="flex items-center">
                  <button
                    className=""
                    onClick={handlePrevMonth}
                    disabled={currentMonth <= startOfMonth(currentDate)} // Disable if the current month is the first month
                  >
                    <img
                      className="rotate-180 w-3 h-3"
                      src={RightArrow}
                      alt=""
                    />
                  </button>
                  <p className=" px-4">{format(currentMonth, "MMMM yyyy")}</p>
                  <button
                    onClick={handleNextMonth}
                    disabled={currentMonth >= oneYearLater} // Disable if the current month is beyond the limit
                  >
                    <img className="w-3 h-3" src={RightArrow} alt="" />
                  </button>
                </div>
              </div>

              {/*--------------------- SELECT ATHLETE --------------------*/}
              <div className="relative">
                <div
                  className="flex items-center calendar_border rounded-[3px] p-1 cursor-pointer"
                  onClick={() => setShowAthleteSearch(!showAthleteSearch)}
                >
                  <div className="flex px-4 items-center ">
                    <p className="text-[var(--blue)] ">
                      {" "}
                      {athleteName ? athleteName : "Select Athlete"}'s Workouts
                    </p>
                    <img
                      src={RightArrow}
                      className={`ml-2 w-2 h-2 ${
                        showAthleteSearch ? "-rotate-90" : "rotate-90"
                      }`}
                      alt=""
                    />
                  </div>
                </div>

                {/*-------------------------- Search athlete popup ----------------------------*/}
                {showAthleteSearch && (
                  <AthtleteSearchBox
                    setShowAthleteSearch={setShowAthleteSearch}
                    athletedata={athletedata}
                    setAthleteId={setAthleteId}
                    setAthleteName={setAthleteName}
                  />
                )}
              </div>

              {/*------------------------ Week/Month Toggle --------------------*/}
              <div className=" flex items-center">
                <div
                  className={`flex items-center gap-2 border-[#DBDBDB] border-[.5px] border-solid p-1 rounded-[3px] mr-2 cursor-pointer ${
                    mView ? " !border-[#2260FF]" : ""
                  }`}
                  // className="flex items-center gap-2 border-[#DBDBDB] border-[.5px] border-solid p-1 rounded-[3px] mr-2 cursor-pointer"
                  onClick={() => {
                    setMview(true);
                    setClick(false);
                    setAddWorkout(false);
                    setAddEvent(false);
                    setShowMenu(false);
                    setReschedule(false);
                  }}
                >
                  <img className="h-3" src={MonthIcon} alt="" />
                  <span>Month</span>
                </div>
                <div
                  className={`flex items-center gap-2 border-[#DBDBDB] border-[.5px] border-solid p-1 rounded-[3px]  cursor-pointer ${
                    mView ? "" : " !border-[#2260FF]"
                  }`}
                  onClick={() => {
                    setMview(false);
                    setClick(false);
                    setAddWorkout(false);
                    setAddEvent(false);
                    setShowMenu(false);
                    setReschedule(false);
                  }}
                >
                  <img className="h-3" src={WeekIcon} alt="" />
                  <span>Week</span>
                </div>
              </div>

              {/* {mView ? (
                  <img className="h-3" src={MonthIcon} alt="" />
                ) : (
                  <img className="h-3" src={WeekIcon} alt="" />
                )}
                <div className="relative ">
                  <select
                    onChange={handleSelectChange}
                    className="pl-2 appearance-none outline-none"
                    name=""
                    id=""
                  >
                    <option value="Month">Month</option>
                    <option value="Week">Week</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 -right-5 flex items-center pr-2">
                    <img className="w-2 h-2 rotate-90" src={RightIcon} alt="" />
                  </div>
                </div> */}
            </div>

            {/*---------------- DESKTOP CALENDAR ---------------------------*/}
            <div className="pt-[6.5rem] ">
              <div className={`flex mx-auto my-0 pb-[4rem] w-full `}>
                <div className="flex flex-col w-full px-[1rem] pt-8 ">
                  <CalendarYearView
                    currentMonth={currentMonth}
                    calendarData={calendarData}
                    setRefId={setRefId}
                    setStpesData={setStpesData}
                    setTrainingSilderDate={setTrainingSilderDate}
                    click={click}
                    setClick={setClick}
                    setAddWorkout={setAddWorkout}
                    setAddEvent={setAddEvent}
                  />
                </div>
                <StepsSlider
                  stepsData={stepsData}
                  click={click}
                  refId={refId}
                  trainigSliderDate={trainigSliderDate}
                  showMenu={showMenu}
                  setShowMenu={setShowMenu}
                  isAddWorkout={isAddWorkout}
                  isAddEvent={isAddEvent}
                />
              </div>
            </div>
          </>
        )}
      </CoachCalendarData.Provider>
    </HelmetProvider>
  );
}

export default CoachCalendarView;
