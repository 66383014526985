import React, { useContext, useEffect, useState } from "react";
import RunIcon from "assets/icons/RunIcon.svg";
import BikeIcon from "assets/icons/BikeIcon.svg";
import SwimIcon from "assets/icons/SwimIcon.svg";
import { CoachCalendarData } from "utility/constants/useContext";
import { format, getISODay } from "date-fns";

function MultiWorkoutSteps({ notes, setRunTrue, setBikeTrue, setWorkoutType }) {
  const Props = useContext(CoachCalendarData);
  const [swimClicked, setSwimClicked] = useState(true); // Checks if swim is clicked
  const [bikeClicked, setBikeClicked] = useState(false);
  const [runClicked, setRunClicked] = useState(false);

  const [brickOneClicked, setBrickOneClicked] = useState(true);
  const [brickTwoClicked, setBrickTwoClicked] = useState(false);

  //--------------------------------------------------- calculate total time and distance
  const addTimeAndDistance = () => {
    let totalSeconds = 0;
    let totalDistanceSum = 0.0;

    // Ensure Props.submitSteps and steps array exist
    if (Props.submitSteps?.steps) {
      Props.submitSteps.steps.forEach((step) => {
        if (step?.activity_steps) {
          step.activity_steps.forEach((activity) => {
            // Parse activity time if it exists
            if (activity?.time) {
              const [hours, minutes, seconds] = activity.time
                .split(":")
                .map((value) => parseInt(value) || 0);
              totalSeconds += hours * 3600 + minutes * 60 + seconds;
            }

            // Parse activity distance if it exists
            totalDistanceSum += parseFloat(activity?.distance) || 0.0;
          });
        }
      });
    }

    // Convert total seconds back to "hh:mm:ss" format
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedTime = [
      hours.toString().padStart(2, "0"), // Ensure two-digit hours
      minutes.toString().padStart(2, "0"), // Ensure two-digit minutes
      seconds.toString().padStart(2, "0"), // Ensure two-digit seconds
    ].join(":");

    // Set formattedTime to null if it equals "00:00:00"
    const resultTime = formattedTime === "00:00:00" ? null : formattedTime;

    return { formattedTime: resultTime, totalDistanceSum };
  };

  //--------------------------------------------------- set the first workout
  useEffect(() => {
    if (Props.brickSelectionOne) {
      if (brickOneClicked) {
        setBikeTrue(true); // this is used to set the save to enable
        Props.setSubmitSteps(Props.submitMultiSteps[0]);
      } else if (brickTwoClicked) {
        Props.setSubmitSteps(Props.submitMultiSteps[1]);
      }
    }
  }, []);

  // ------------------------------------------------- update the multisteps array
  useEffect(() => {
    // if (!Props.brickSelectionOne) {
    if (Props.submitSteps) {
      AddData();
    }

    // }
  }, [Props.submitSteps]); // Empty dependency array to prevent unnecessary re-renders

  // Update Sequence
  function updateSequence(data) {
    let count = 1;

    // Map through the steps array and update sequences
    data.steps = data.steps.map((step) => {
      // Update the step's sequence
      step.sequence = count;

      // Handle no_of_repeats logic
      if (step.no_of_repeats > 1) {
        step.no_of_repeats = step.activity_steps.length;
        count += 1;
        for (let i = 1; i < step.no_of_repeats; i++) {
          step.activity_steps.forEach((activityStep) => {
            activityStep.sequence = count;
            count += 1;
          });
        }
      } else {
        // Map through the activity_steps array within the step
        step.activity_steps = step.activity_steps.map((activityStep) => {
          activityStep.sequence = count;
          count += 1; // Increment count for each activity step
          return activityStep;
        });
      }

      return step;
    });

    return data;
  }
  // add data to submit Steps array
  const AddData = () => {
    const updateSubmitSteps = updateSequence(Props.submitSteps);
    const timeAndDistance = addTimeAndDistance();
    const time =
      timeAndDistance.formattedTime === "00.00.00"
        ? null
        : timeAndDistance.formattedTime;

    const distance = timeAndDistance.totalDistanceSum;
    const formattedDate = format(new Date(Props.eventDate), "dd-MM-yyyy");
    const dayOfWeek = getISODay(new Date(Props.eventDate));
    const UpperCaseWorkoutType = Props.submitSteps.workout_type?.toUpperCase();

    let name;
    let indexToUpdate;

    // Determine name and index
    if (!Props.brickSelectionOne) {
      if (swimClicked) {
        name = "Tri - Swim";
        indexToUpdate = 0;
      } else if (bikeClicked) {
        name = "Tri - Bike";
        indexToUpdate = 1;
      } else if (runClicked) {
        name = "Tri - Run";
        indexToUpdate = 2;
      }
    } else {
      if (brickOneClicked) {
        name = Props.submitMultiSteps.name;
        indexToUpdate = 0;
      } else if (brickTwoClicked) {
        name = Props.submitMultiSteps.name;
        indexToUpdate = 1;
      }
    }

    // Update the multi-step array
    Props.setSubmitMultiSteps((prevSteps) => {
      const updatedSteps = Array.isArray(prevSteps) ? [...prevSteps] : [];

      if (indexToUpdate !== undefined) {
        // Update the specific step at indexToUpdate
        updatedSteps[indexToUpdate] = {
          ...updatedSteps[indexToUpdate], // Preserve existing values
          day: dayOfWeek,
          date: formattedDate,
          name: name,
          short_name: name,
          long_name: name,
          workout_type: UpperCaseWorkoutType,
          total_hours: time,
          total_distance: distance,
          notes: notes,
          sync_device: 0,
          steps: updateSubmitSteps.steps, // Use the `steps` from Props.submitSteps
        };
      }

      return updatedSteps; // Return the updated array
    });
  };

  return (
    <div>
      {Props.multiWorkout && (
        <>
          {Props.brickSelectionTwo ? (
            <>
              <div className="flex items-center">
                {/* brick one */}
                {brickOneClicked ? (
                  <div className="flex justify-center items-center bg-[var(--light-blue)] w-[4rem] h-[2rem] rounded-[3px] cursor-pointer">
                    <p className="text-white">{Props.brickSelectionOne}</p>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      Props.setSubmitSteps(Props.submitMultiSteps[0]);
                      Props.setMultiWorkoutType(Props.brickSelectionOne);
                      setBrickOneClicked(true);
                      setBrickTwoClicked(false);
                    }}
                    className="flex justify-center items-center bg-[#F0F4F8] w-[2rem] h-[2rem] rounded-full cursor-pointer"
                  >
                    <img
                      className="w-[70%] h-[70%]"
                      src={
                        Props.brickSelectionOne === "Swim"
                          ? SwimIcon
                          : Props.brickSelectionOne === "Bike"
                          ? BikeIcon
                          : RunIcon
                      }
                      alt=""
                    />
                  </div>
                )}

                <div className="w-[10rem] h-[.2rem] bg-[var(--light-grey)] mx-4 rounded-[5px]"></div>

                {/* Brick Two */}

                {brickTwoClicked ? (
                  <div className="flex justify-center items-center bg-[var(--light-blue)] w-[4rem] h-[2rem] rounded-[3px] cursor-pointer">
                    <p className="text-white">{Props.brickSelectionTwo}</p>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setRunTrue(true);
                      Props.setSubmitSteps(Props.submitMultiSteps[1]);
                      Props.setMultiWorkoutType(Props.brickSelectionTwo);
                      setBrickOneClicked(false);
                      setBrickTwoClicked(true);
                    }}
                    className="flex justify-center items-center bg-[#F0F4F8] w-[2rem] h-[2rem] rounded-full cursor-pointer"
                  >
                    <img
                      className="w-[70%] h-[70%]"
                      src={
                        Props.brickSelectionTwo === "Swim"
                          ? SwimIcon
                          : Props.brickSelectionTwo === "Bike"
                          ? BikeIcon
                          : RunIcon
                      }
                      alt=""
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center">
                {/* Swim */}

                {swimClicked ? (
                  <div className="flex justify-center items-center bg-[var(--light-blue)] w-[4rem] h-[2rem] rounded-[3px] cursor-pointer">
                    <p className="text-white">Swim</p>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      Props.setSubmitSteps(Props.submitMultiSteps[0]);
                      setSwimClicked(true);
                      setBikeClicked(false);
                      setRunClicked(false);
                    }}
                    className="flex justify-center items-center bg-[#F0F4F8] w-[2rem] h-[2rem] rounded-full cursor-pointer"
                  >
                    <img className="w-[70%] h-[70%]" src={SwimIcon} alt="" />
                  </div>
                )}
                {/* Swim end */}

                <div className="w-[10rem] h-[.2rem] bg-[var(--light-grey)] mx-4 rounded-[5px]"></div>

                {/* Bike */}

                {bikeClicked ? (
                  <div className="flex justify-center items-center bg-[var(--light-blue)] w-[4rem] h-[2rem] rounded-[3px] cursor-pointer">
                    <p className="text-white">Bike</p>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setBikeTrue(true);
                      Props.setSubmitSteps(Props.submitMultiSteps[1]);
                      setBikeClicked(true);
                      setSwimClicked(false);
                      setRunClicked(false);
                    }}
                    className="flex justify-center items-center bg-[#F0F4F8] w-[2rem] h-[2rem] rounded-full cursor-pointer"
                  >
                    <img className="w-[70%] h-[70%]" src={BikeIcon} alt="" />
                  </div>
                )}

                <div className="w-[10rem] h-[.2rem] bg-[var(--light-grey)] mx-4 rounded-[5px]"></div>

                {/* Run */}
                {runClicked ? (
                  <div className="flex justify-center items-center bg-[var(--light-blue)] w-[4rem] h-[2rem] rounded-[3px] cursor-pointer">
                    <p className="text-white">Run</p>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setRunTrue(true);
                      Props.setSubmitSteps(Props.submitMultiSteps[2]);
                      setRunClicked(true);
                      setBikeClicked(false);
                      setSwimClicked(false);
                    }}
                    className="flex justify-center items-center bg-[#F0F4F8] w-[2rem] h-[2rem] rounded-full cursor-pointer"
                  >
                    <img className="w-[70%] h-[70%]" src={RunIcon} alt="" />
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default MultiWorkoutSteps;
