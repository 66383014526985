import React, { useContext, useEffect, useState } from "react";
import { getApis } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { CoachCalendarData } from "utility/constants/useContext";
import AddEventIcon from "assets/icons/AddEvent.svg";
import BaselineIcon from "assets/icons/CoachCalendar/Baseline.svg";
import WatchIcon from "assets/icons/CoachCalendar/GreenWatch.svg";
import CompeletedIcon from "assets/icons/CoachCalendar/is_completed.svg";
import NotSyncIcon from "assets/icons/CoachCalendar/notSync.svg";
import EventIcon from "assets/icons/CoachCalendar/Event.svg";
import { isBefore, isToday } from "date-fns";
import { formatTime, convertMetersToKilometersIfM } from "hooks/convertValues";
import { parse, addDays, isWithinInterval, format } from "date-fns";

function CoachEventCalendar({
  calendarDay,
  calendarData,
  setRefId,
  setStpesData,
  setTrainingSilderDate,
  setClick,
  setAddWorkout,
  isRightClick,
  setIsRightClick,
  tooltip,
  setTooltip,
  setShowEdit,
  setShowDelete,
  setShowReschdule,
  setClosePopup,
}) {
  const Props = useContext(CoachCalendarData);
  let pressTimer;

  const changeOpacity = (workoutDate) => {
    let weekStartDate = Props.weekDateOpacity;
    let currentDate = workoutDate;

    const parsedWeekStartDate = parse(weekStartDate, "dd-MM-yyyy", new Date());
    const parsedCurrentDate = parse(currentDate, "dd-MM-yyyy", new Date());

    const weekEndDate = addDays(parsedWeekStartDate, 6);

    // Check if currentDate is within the interval
    const isCurrentDateWithinRange = isWithinInterval(parsedCurrentDate, {
      start: parsedWeekStartDate,
      end: weekEndDate,
    });
    if (Props.isCopyWeek?.from?.user === Props.athleteId) {
      return isCurrentDateWithinRange;
    }
    if (Props.isShiftWeek?.user === Props.athleteId) {
      return isCurrentDateWithinRange;
    } else {
      Props.setShiftWeek();
      if (Props.isShiftWeek) {
        Props.setPasteWeek(false);
      }
    }
  };

  function handleClick(
    id,
    eventType,
    activityStatus,
    date,
    is_baseline,
    is_singlestep_dist,
    event
  ) {
    // set copy,paste, week copy etc empty
    Props.setCopy(""); // set copy empty
    Props.setCut(""); // set cut empty
    Props.setPaste(false); // paste workout
    Props.setCopyWeek(); // clear the copye week data
    Props.setShiftWeek(); // clear the shift week data
    Props.setPasteWeek(false); // paste week
    Props.setWeekDateOpacity(""); // reset week opacity
    Props.setBaseline(is_baseline); // set baseline
    Props.setSingleStepDist(is_singlestep_dist);

    if (date) {
      const eventDate = parse(date, "dd-MM-yyyy", new Date());
      const currentDate = new Date(); // Automatically uses today's date

      // Check if the event date is today
      if (isToday(eventDate)) {
        if (activityStatus) {
          setShowDelete(false);
          setShowReschdule(false);
        } else {
          setShowEdit(true);
          setShowDelete(true);
          setShowReschdule(true);
        }
      } else if (isBefore(eventDate, currentDate)) {
        setShowEdit(false);
        if (!activityStatus) {
          setShowDelete(true);
          setShowReschdule(true);
        } else {
          setShowDelete(false);
          setShowReschdule(false);
        }
      } else {
        setShowEdit(true);
        setShowDelete(true);
        setShowReschdule(true);
      }
    }

    const fetchCalendarList = async () => {
      try {
        const res = await getApis(
          `${API_URL_CONSTANTS.GET_WORKOUT_STEPS}${id}`
        );

        if (res && res.data) {
          const planDate = parse(res.data.date, "dd-MM-yyyy", new Date());
          const formattedPlanDate = format(planDate, "EEEE, d MMM");
          setTrainingSilderDate(formattedPlanDate);
          setStpesData(res.data);
          Props.setSubmitSteps(res.data);
          // set Plan date
          Props.setPlanEventDate(res.data.date);
        } else {
          console.error(
            "Error fetching workout steps or unexpected response format:",
            res
          );
          alert("Error fetching workout steps. Please try again later.");
        }
      } catch (error) {
        console.error("Fetch error:", error);
        alert("Error fetching workout steps:");
      }
    };

    if (event.type === "contextmenu" || event.type === "longpress") {
      event.preventDefault();
      setIsRightClick(true);
      setClosePopup(true);
      fetchCalendarList();

      // set activity status
      Props.setWorkoutCompleted(activityStatus);

      // set Event type
      Props.setCalenderEventType(eventType);

      // set ref id
      setRefId(id);

      const isCoachEventClicked = event.target.closest(".coach-event");

      if (isCoachEventClicked) {
        // Get viewport dimensions
        const tooltipWidth = 150; // Approximate width of the tooltip (adjust as needed)
        const tooltipHeight = 50; // Approximate height of the tooltip (adjust as needed)
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Calculate initial tooltip position
        let top = event.clientY + window.scrollY;
        let left = event.clientX + window.scrollX;

        // Adjust for overflow on the right
        if (left + tooltipWidth > viewportWidth + window.scrollX) {
          left = viewportWidth + window.scrollX - tooltipWidth - 10; // Add some padding
        }

        // Adjust for overflow on the bottom
        if (top + tooltipHeight > viewportHeight + window.scrollY) {
          top = viewportHeight + window.scrollY - tooltipHeight - 10; // Add some padding
        }

        // Adjust for overflow on the left
        if (left < window.scrollX) {
          left = window.scrollX + 10; // Add some padding
        }

        // Adjust for overflow on the top
        if (top < window.scrollY) {
          top = window.scrollY + 10; // Add some padding
        }

        setTooltip({
          visible: true,
          position: { top, left },
        });
      }

      if (event.type === "longpress") {
        const rect = event.target.getBoundingClientRect();
        const tooltipPosition = {
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
        };

        setTooltip({ visible: true, position: tooltipPosition });
      }
    } else if (event.type === "click") {
      if (eventType === 2) {
        return;
      }
      // close the right silder delete edit menu
      Props.setShowMenu(false);

      // set activity status
      Props.setWorkoutCompleted(activityStatus);

      // set Event type
      Props.setCalenderEventType(eventType);

      // scroll to top
      window.scrollTo(0, 0);

      // Open slider
      setClick(true);

      // add workout slider to false
      setAddWorkout(false);

      //
      Props.setReschedule(false);

      // set ref id
      setRefId(id);

      fetchCalendarList();
    }
  }

  const handleLongPress = (
    id,
    eventType,
    activityStatus,
    date,
    is_baseline,
    is_singlestep_dist,
    event
  ) => {
    event.type = "longpress"; // Add custom event type
    handleClick(
      id,
      eventType,
      activityStatus,
      date,
      is_baseline,
      is_singlestep_dist,
      event
    );
  };

  const handleTouchStart = (
    id,
    eventType,
    activityStatus,
    date,
    is_baseline,
    is_singlestep_dist,
    event
  ) => {
    pressTimer = setTimeout(() => {
      handleLongPress(
        id,
        eventType,
        activityStatus,
        date,
        is_baseline,
        is_singlestep_dist,
        event
      );
    }, 600); // Trigger long press after 600ms
  };

  const handleTouchEnd = (event) => {
    clearTimeout(pressTimer); // Clear timer on touch end
  };

  return (
    <>
      {calendarData && calendarData.day_workouts && (
        <>
          {calendarData.day_workouts.map((details, index) => (
            <div key={`detils--${index}`}>
              {calendarDay === details.workout_date && (
                <>
                  {details.workouts.map((steps, index) => {
                    let res;

                    if (steps.brief_information.includes(":")) {
                      res = formatTime(steps.brief_information);
                    } else {
                      res = convertMetersToKilometersIfM(
                        steps.brief_information
                      );
                    }
                    let Opcaity = changeOpacity(details.workout_date);

                    return (
                      <div
                        key={`stpes-${index}`}
                        onContextMenu={(e) =>
                          handleClick(
                            steps?.id,
                            steps?.calender_event_type,
                            steps?.is_completed,
                            details?.workout_date,
                            steps?.is_baseline,
                            steps?.is_singlestep_dist,
                            e
                          )
                        }
                        onClick={(e) =>
                          handleClick(
                            steps?.id,
                            steps?.calender_event_type,
                            steps?.is_completed,
                            details?.workout_date,
                            steps?.is_baseline,
                            steps?.is_singlestep_dist,
                            e
                          )
                        }
                        onTouchStart={(e) =>
                          handleTouchStart(
                            steps?.id,
                            steps?.calender_event_type,
                            steps?.is_completed,
                            details?.workout_date,
                            steps?.is_baseline,
                            steps?.is_singlestep_dist,
                            e
                          )
                        } // Handle long press start
                        onTouchEnd={(e) => handleTouchEnd(e)} // Clear long press
                        className={`disable-long-press coach-event relative z-20 md:z-0  flex   bg-[#ededed00] border border-[#ededed]  cursor-pointer text-[.8rem]  rounded-[3px] pr-1 mb-1
                        
                          ${
                            (Props.isCut && steps?.id === Props.refId) ||
                            (Opcaity && Props.isShiftWeek)
                              ? "opacity-[0.5]"
                              : ""
                          }
                         
                          `}
                      >
                        {(Props.isCopy && steps?.id === Props.refId) ||
                        (Opcaity &&
                          steps.calender_event_type !== 3 &&
                          Props.isCopyWeek) ? (
                          <div className="absolute w-full h-full top-0 left-0 bg-[#00000000] rounded-[3px] border-dashed border-2 border-[#c1c1c1]"></div>
                        ) : (
                          ""
                        )}

                        <div>
                          {steps.calender_event_type === 1 && (
                            <p className="event_tag bg-[#C71F37] px-[0.1rem] h-full"></p>
                          )}

                          {steps.calender_event_type === 2 && (
                            <p className="event_tag bg-[#00BBF9] p-[0.1rem] h-full"></p>
                          )}

                          {steps.calender_event_type === 3 && (
                            <p className="event_tag bg-[#cfd11a] p-[0.1rem] h-full"></p>
                          )}
                        </div>

                        <div className="flex flex-col flex-wrap w-full overflow-hidden">
                          <div className="flex flex-col flex-wrap break-words  pt-1 pl-1 mb-[-0.2rem] text-[var(--blue)] ">
                            <div className="flex flex-wrap justify-end items-center">
                              {steps.calender_event_type === 3 && (
                                <img
                                  src={EventIcon}
                                  className="h-[0.65rem] w-auto mr-1"
                                  alt=""
                                />
                              )}
                              {steps?.is_baseline && (
                                <img
                                  src={BaselineIcon}
                                  className="h-[0.65rem] w-auto mr-1"
                                  alt=""
                                />
                              )}

                              {steps?.synced_with_device ? (
                                <img
                                  src={WatchIcon}
                                  className="h-[0.65rem] w-auto mr-1"
                                  alt=""
                                />
                              ) : (
                                ""
                                // <img
                                //   src={NotSyncIcon}
                                //   className="h-[0.65rem] w-auto mr-1"
                                //   alt=""
                                // />
                              )}

                              {steps?.is_completed && (
                                <img
                                  src={CompeletedIcon}
                                  className="h-[0.65rem] w-auto mr-1"
                                  alt=""
                                />
                              )}
                            </div>

                            <span className="flex flex-wrap items-center mr-1">
                              <span className="break-words">
                                {steps.calender_event_type === 3 ? (
                                  <span className="break-words mr-1  font-medium">
                                    {steps.label}
                                  </span>
                                ) : (
                                  `${steps.workout_type}`
                                )}
                              </span>
                            </span>
                          </div>

                          <span></span>

                          <span
                            key={`distance-${index}`}
                            className="text-right  pr-1 text-[.6rem]  text-[var(--blue)]"
                          >
                            {/* {steps.brief_information} */}
                            {steps.calender_event_type === 3 &&
                              `${steps.workout_type}`}{" "}
                            {res || steps.brief_information}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default CoachEventCalendar;
