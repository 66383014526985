import React, { useContext, useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { CoachCalendarData } from "utility/constants/useContext";
import { formatTime } from "hooks/convertValues";

function Graph() {
  const Props = useContext(CoachCalendarData);
  const [time, setTime] = useState("");

  const totalSteps = Props.submitSteps?.steps?.length;

  const numberOfRepeats = Props.submitSteps?.steps?.reduce((count, step) => {
    return step.no_of_repeats > 1 ? count + 1 : count;
  }, 0);

  const addTimeAndDistance = () => {
    let totalSeconds = 0;
    let totalDistanceSum = 0.0;

    // Ensure Props.submitSteps and steps array exist
    if (Props.submitSteps?.steps) {
      Props.submitSteps.steps.forEach((step) => {
        if (step?.activity_steps) {
          step.activity_steps.forEach((activity) => {
            // Parse activity time if it exists
            if (activity?.time) {
              const [hours, minutes, seconds] = activity.time
                .split(":")
                .map((value) => parseInt(value) || 0);
              totalSeconds += hours * 3600 + minutes * 60 + seconds;
            }

            // Parse activity distance if it exists
            totalDistanceSum += parseFloat(activity?.distance) || 0.0;
          });
        }
      });
    }

    // Convert total seconds back to "hh:mm:ss" format
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedTime = [
      hours.toString().padStart(2, "0"), // Ensure two-digit hours
      minutes.toString().padStart(2, "0"), // Ensure two-digit minutes
      seconds.toString().padStart(2, "0"), // Ensure two-digit seconds
    ].join(":");

    return { formattedTime, totalDistanceSum };
  };

  useEffect(() => {
    if (
      Props.submitSteps.total_hours === "" ||
      Props.submitSteps.total_hours === null ||
      Props.submitSteps.total_hours === undefined
    ) {
      return;
    } else {
      const { formattedTime, totalDistanceSum } = addTimeAndDistance();
      setTime(formatTime(formattedTime));
    }
  }, [Props.submitSteps]);

  return (
    <div className="flex flex-col  bg-[#F8F8F8] p-2 mt-4 rounded-[3px] ">
      <div className="flex justify-start mb-4">
        <span className="text-[.7rem] text-[var(--blue)]">
          Workout Intensity
        </span>
      </div>

      <div className="flex justify-center">
        <div className="relative w-[40%]">
          <span className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2">
            {time}
          </span>
          <PieChart
            data={[
              { title: "high", value: numberOfRepeats, color: "#023047" },
              { title: "easy", value: totalSteps, color: "#FDC027" },
            ]}
            lineWidth={30}
            animate={true}
          />
        </div>

        <div className="flex flex-col justify-end pl-4">
          <div className="flex">
            <span className="w-4 h-4 bg-[var(--blue)] "></span>
            <span className="text-[var(--blue)] ml-2">High </span>
          </div>
          <div className="flex">
            <span className="w-4 h-4 bg-[var(--orange)] "></span>
            <span className="text-[var(--blue)] ml-2 ">Easy</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Graph;
