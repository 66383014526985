import React, { useEffect, useState } from "react";

import SearchIcon from "assets/icons/searchIcon.svg";

function AthtleteSearchBox({
  setShowAthleteSearch,
  athletedata,
  setAthleteId,
  setAthleteName,
}) {
  const [searchQuery, setSearchQuery] = useState(""); // State for the search query
  const [filteredAthletes, setFilteredAthletes] = useState(athletedata); // State for filtered list

  // Handle search input change
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter athletes based on the search query
    const filtered = athletedata.filter(
      (athlete) =>
        athlete.name.toLowerCase().includes(query) || // Search by name
        (athlete.plan_name && athlete.plan_name.toLowerCase().includes(query)) // Search by plan_name
    );
    setFilteredAthletes(filtered);
  };
  return (
    <div className="absolute top-full h-[25rem] overflow-y-scroll mt-2 min-w-[20rem] shadow-md bg-white border border-gray-300 rounded-[3px] z-30 p-4">
      <div className="flex items-center  mb-3">
        {" "}
        <h6 className="font-bold mr-2 text-[var(--blue)]">
          ATHLETES TRAINING WITH ME
        </h6>
        <span className="flex items-center justify-center rounded-full bg-[var(--orange)] w-5 h-5">
          <span className="text-black">
            {athletedata ? athletedata.length : 0}
          </span>
        </span>
      </div>

      {/* Search Bar */}
      <div className="flex items-center bg-[var(--light-grey)] rounded-[3px] px-2 py-1 shadow-sm">
        <img
          className="w-4 h-4 text-gray-500 mr-2"
          src={SearchIcon}
          alt="Search Icon"
        />
        <input
          type="text"
          className="flex-1 bg-transparent outline-none text-sm text-gray-700 placeholder-gray-500"
          placeholder="Search athletes..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>

      {/*----------------------- Athlete List --------------------*/}
      <ul className="mt-3">
        {filteredAthletes.length > 0 ? (
          filteredAthletes.map((athlete) => (
            <li
              key={athlete.id}
              onClick={() => {
                setAthleteId(athlete.id);
                setShowAthleteSearch(false);
                setAthleteName(athlete.name);
              }}
              className="pt-2 border-b-[.1px] text-[1rem] border-[var(--orange)] text-[var(--light-blue)] cursor-pointer"
            >
              {athlete.name}{" "}
              <span className="flex justify-end text-[.6rem] text-[#8ECAE6]">
                {athlete.plan_name}
              </span>
            </li>
          ))
        ) : (
          <li className="text-gray-500 text-sm mt-3">No athletes found</li>
        )}
      </ul>
    </div>
  );
}

export default AthtleteSearchBox;
