import React, { useContext } from "react";
import CopyWeelIcon from "assets/icons/CoachCalendar/copyWeek.svg";
import ShiftIcon from "assets/icons/CoachCalendar/shift.svg";
import CopyIcon from "assets/icons/CoachCalendar/Copy.svg";
import ShiftWeekIcon from "assets/icons/CoachCalendar/shiftWeek.svg";
import PasteIcon from "assets/icons/CoachCalendar/Paste.svg";
import { CoachCalendarData } from "utility/constants/useContext";
import { cutCopy, shiftWeek } from "api/CoachCalendar";

function CopyWeekPopup({ copyWeek, setCopyWeek, hideTooltip, setClosePopup }) {
  const Props = useContext(CoachCalendarData);

  const handleCopyWeek = () => {
    Props.setWeekDateOpacity(Props.planEventDate); // set opacity for copy week
    const copyWeekData = {
      keep_original: "1", // 0 for cut 1 for copy
      from: {
        user: Props.athleteId,
        date: Props.planEventDate,
        days: 7, // 1 for copy and cut 7 for week
        id: null, // workout_id if week copy = null
      },
      to: {
        user: 0, // user_ref
        date: "",
      },
    };

    Props.setCopyWeek(copyWeekData);
    Props.setPasteWeek(true);
    setClosePopup(false);
    setCopyWeek({ ...copyWeek, visible: false });
  };

  const handleShifWeek = () => {
    Props.setWeekDateOpacity(Props.planEventDate);
    const shiftWeekData = {
      from_date: Props.planEventDate,
      to_date: "",
      user: Props.athleteId,
    };
    Props.setShiftWeek(shiftWeekData);
    Props.setPasteWeek(true);
    setClosePopup(false);
    setCopyWeek({ ...copyWeek, visible: false });
  };

  const handlePaste = async () => {
    let data = Props.isCopyWeek;

    const submitCopy = {
      keep_original: data.keep_original,
      from: {
        user: data.from.user,
        date: data.from.date,
        days: data.from.days,
        id: data.from.id,
      },
      to: {
        user: Props.athleteId, // user_ref
        date: Props.planEventDate,
      },
    };

    const res = await cutCopy(submitCopy);
    if (res.success === true) {
      setClosePopup(false);
      setCopyWeek({ ...copyWeek, visible: false });
      Props.setPasteWeek(false);
      Props.setCopyWeek();
      Props.setUpdateCalendar(res.data);
      Props.setWeekDateOpacity("");
    } else {
      Props.setErrorPopup(true);
      Props.setErrorHeader("Something went wrong!");
      Props.setErrorMessage(res?.data || "Try again later");
    }
  };

  const handleShift = async () => {
    let data = Props.isShiftWeek;
    const submitData = {
      from_date: data.from_date,
      to_date: Props.planEventDate,
      user: data.user,
    };
    const res = await shiftWeek(submitData);
    if (res.success === true) {
      setClosePopup(false);
      setCopyWeek({ ...copyWeek, visible: false });
      Props.setPasteWeek(false);
      Props.setShiftWeek();
      Props.setUpdateCalendar(res.data);
      Props.setWeekDateOpacity("");
    } else {
      Props.setErrorPopup(true);
      Props.setErrorHeader("Something went wrong!");
      Props.setErrorMessage(res?.data || "Try again later");
    }
  };

  return (
    <div>
      {copyWeek.visible && (
        <div
          className="absolute bg-[#ffe8ace4] Shadow rounded-[3px] tooltip-container  py-1"
          style={{
            position: "absolute",
            top: `${copyWeek.position.top}px`,
            left: `${copyWeek.position.left}px`,
            zIndex: 50,
          }}
          onMouseLeave={hideTooltip}
        >
          {Props.isPasteWeek ? (
            <>
              {Props.isCopyWeek ? (
                <div
                  className="flex items-center cursor-pointer p-2  rounded-[3px] text-[var(--light-blue)]"
                  onClick={() => handlePaste()}
                >
                  <img className="w-4 h-4" src={PasteIcon} alt="" />
                  <p className="pl-2 hover:underline">Paste Week</p>
                </div>
              ) : (
                <div
                  className="flex items-center cursor-pointer p-2  rounded-[3px] text-[var(--light-blue)]"
                  onClick={() => handleShift()}
                >
                  <img className="w-4 h-4" src={PasteIcon} alt="" />
                  <p className="pl-2 hover:underline">Shift</p>
                </div>
              )}
            </>
          ) : (
            <div className="">
              <div
                onClick={() => handleCopyWeek()}
                className="flex items-center cursor-pointer p-2  rounded-[3px] text-[var(--light-blue)]"
              >
                <img className="w-4 h-4" src={CopyIcon} alt="" />
                <p className="pl-2 hover:underline">Copy Week</p>
              </div>
              <div
                onClick={() => handleShifWeek()}
                className="flex items-center cursor-pointer p-2  rounded-[3px] text-[var(--light-blue)]"
              >
                <img className="w-4 h-4" src={ShiftIcon} alt="" />
                <p className="pl-2 hover:underline">Shift Week</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CopyWeekPopup;
