import React, { useContext, useEffect, useState } from "react";
import { CalendarData } from "utility/constants/useContext";
import Redirect from "components/popups/Redirect";
import { getApis } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { format, parse } from "date-fns";
import { formatTime } from "hooks/convertValues";

function CalendarEvents(props) {
  const { calendarDay } = props;
  const Props = useContext(CalendarData);

  const [formatedDate, setFormatedDate] = useState("");

  const EventDetails = Props.calendarData;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Initial state with current window width

  const [redirect, setRedirect] = useState(false); // error popup

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Added  event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleCick(id) {
    // scroll to top
    window.scrollTo(0, 0);

    if (windowWidth > "800") {
      Props.setClick(true);
      // set ref id
      Props.setRefId(id);
      const fetchCalendarList = async () => {
        try {
          const res = await getApis(
            `${API_URL_CONSTANTS.GET_WORKOUT_STEPS}${id}`
          );

          if (res && res.data) {
            const planDate = parse(res.data.date, "dd-MM-yyyy", new Date());
            const formattedPlanDate = format(planDate, "EEEE, d MMM");
            Props.setTrainingSilderDate(formattedPlanDate);
            Props.setStpesData(res.data);
          } else {
            console.error(
              "Error fetching workout steps or unexpected response format:",
              res
            );
            alert("Error fetching workout steps. Please try again later.");
          }
        } catch (error) {
          console.error("Fetch error:", error);
          alert("Error fetching workout steps:");
        }
      };

      fetchCalendarList();
    } else {
      setRedirect(!redirect);
    }
  }

  function convertMetersToKilometers(meters) {
    function removeMetersSuffix(m) {
      if (typeof m === "string") {
        return m.replace(" m", ""); // Remove the " m" part
      }
      return "Please provide a valid string.";
    }

    const m = removeMetersSuffix(meters);

    if (m < 1000) {
      return `${m} m`;
    }

    return `${m / 1000} km`; // Keeping 2 decimal places for km
  }

  return (
    <>
      {redirect && (
        <>
          <div className="delete_blur flex justify-center">
            <Redirect setRedirect={setRedirect} />
          </div>
        </>
      )}

      {EventDetails.map((details, index) => (
        <div key={`detils--${index}`}>
          {calendarDay === details.workout_date && (
            <>
              {details.workouts.map((steps, index) => {
                let res;

                if (steps.brief_information.includes(":")) {
                  res = formatTime(steps.brief_information);
                } else {
                  res = convertMetersToKilometers(steps.brief_information);
                }

                return (
                  <div
                    key={`stpes-${index}`}
                    onClick={() => handleCick(steps.id)}
                    className="relative z-20 md:z-0 flex  md:min-w-[15rem] bg-[#EDEDED] cursor-pointer text-[.8rem] md:text-[1rem] rounded-[3px] pr-1 mb-1 "
                  >
                    <div>
                      {steps.calender_event_type === 1 && (
                        <p className="event_tag bg-[#C71F37] p-[0.1rem] h-full"></p>
                      )}

                      {steps.calender_event_type === 2 && (
                        <p className="event_tag bg-[#00BBF9] p-[0.1rem] h-full"></p>
                      )}

                      {steps.calender_event_type === 3 && (
                        <p className="event_tag bg-[#cfd11a] p-[0.1rem] h-full"></p>
                      )}
                    </div>

                    <div className="flex flex-col w-full overflow-hidden">
                      <div className="flex flex-wrap pt-1 pl-1 mb-[-0.2rem] text-[var(--blue)] ">
                        <span className="break-words mr-1">
                          {steps.workout_type}
                        </span>
                      </div>

                      <span></span>

                      <span
                        key={`distance-${index}`}
                        className="text-right  pr-1 text-[.6rem] md:text-[.8rem] text-[var(--blue)]"
                      >
                        {/* {steps.brief_information} */}
                        {res || steps.brief_information}
                      </span>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      ))}
    </>
  );
}

export default CalendarEvents;
