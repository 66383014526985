import React from "react";
import { Button } from "rsuite";

function SaveBtn({ load, handleSave, action, isDisable, className }) {
  return (
    <div>
      {load ? (
        <Button loading className={className}></Button>
      ) : (
        <button
          onClick={handleSave}
          className={className}
          disabled={isDisable}
          style={isDisable ? { backgroundColor: "#d3d3d3", color: "#888" } : {}}
        >
          {action}
        </button>
      )}
    </div>
  );
}

export default SaveBtn;
