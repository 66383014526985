import React, { useContext, useEffect, useState } from "react";
import FilterIcon from "assets/icons/filterIcon.svg";
import SearchIcon from "assets/icons/searchIcon.svg";
import { getApis } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import RunIcon from "assets/icons/RunIcon.svg";
import SwimIcon from "assets/icons/swim.svg";
import BikeIcon from "assets/icons/bike.svg";
import BrickIcon from "assets/icons/CoachCalendar/BrickIcon.svg";
import TriIcon from "assets/icons/CoachCalendar/TriIcon.svg";
import Wrun from "assets/icons/CoachCalendar/Wrun.svg";
import Wbike from "assets/icons/CoachCalendar/Wbike.svg";
import Wswim from "assets/icons/CoachCalendar/Wswim.svg";
import Wbrick from "assets/icons/CoachCalendar/Wbrick.svg";
import Wtri from "assets/icons/CoachCalendar/Wtri.svg";
import { CoachCalendarData } from "utility/constants/useContext";

function WorkoutTemplates({ fullHeight, WorkoutType }) {
  const Props = useContext(CoachCalendarData);
  const [allTemplates, setAllTemplates] = useState({}); // Store all fetched data by category
  const [templateData, setTemplateData] = useState([]); // Data to display based on filter
  const [filteredData, setFilteredData] = useState([]); // Data filtered by search input
  const [searchTerm, setSearchTerm] = useState(""); // User input for search
  const [selectedCategory, setSelectedCategory] = useState("all"); // Default selected category
  const [filterClicked, setFilterClicked] = useState(false); // Flag to check if filter is clicked

  const categories = [
    { name: "all", label: "All", icon: null },
    { name: "run", label: "Run", activeIcon: Wrun, inactiveIcon: RunIcon },
    { name: "bike", label: "Bike", activeIcon: Wbike, inactiveIcon: BikeIcon },
    { name: "swim", label: "Swim", activeIcon: Wswim, inactiveIcon: SwimIcon },
    {
      name: "brick",
      label: "Brick",
      activeIcon: Wbrick,
      inactiveIcon: BrickIcon,
    },
    { name: "tri", label: "Tri", activeIcon: Wtri, inactiveIcon: TriIcon },
  ];
  // Fetch data for all categories or WorkoutType
  useEffect(() => {
    const fetchTemplates = async () => {
      const data = {};

      if (WorkoutType) {
        // Fetch only for the provided WorkoutType
        const workoutTypeKey = WorkoutType.toLowerCase();
        const res = await getApis(
          `${API_URL_CONSTANTS.GET_WORKOUT_TEMPLATE_LIST}${workoutTypeKey}`
        );
        const categoryData = Array.isArray(res?.data?.options_with_name)
          ? res.data.options_with_name
          : [];
        data[workoutTypeKey] = categoryData;

        setAllTemplates(data);
        setTemplateData(categoryData); // Set data for the provided WorkoutType
        setFilteredData(categoryData); // Set filtered data for display
      } else {
        // Fetch all categories if no WorkoutType is provided
        let combinedData = [];
        for (const category of categories.slice(1)) {
          const res = await getApis(
            `${API_URL_CONSTANTS.GET_WORKOUT_TEMPLATE_LIST}${category.name}`
          );
          const categoryData = Array.isArray(res?.data?.options_with_name)
            ? res.data.options_with_name
            : [];
          data[category.name] = categoryData;
          combinedData = [...combinedData, ...categoryData];
        }
        data.all = combinedData;

        setAllTemplates(data);
        setTemplateData(data.all); // Default to "all" data
        setFilteredData(data.all);
      }
    };

    fetchTemplates();
  }, [WorkoutType]);

  // Handle category selection
  const handleCategoryChange = (category) => {
    setFilterClicked(true);
    setSelectedCategory(category);

    const categoryData = allTemplates[category] || [];
    setTemplateData(categoryData);

    // Filter the data based on the search term
    setFilteredData(
      categoryData.filter((template) =>
        template.value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  // Handle search input
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    // Filter data based on the search term
    const filtered = templateData.filter((template) =>
      template.value.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };

  // Fetch template steps
  const fetchTemplateSteps = async (key) => {
    Props.setTemplateId(key);
    const res = await getApis(
      API_URL_CONSTANTS.GET_WORKOUT_TEMPLATE_STEPS + key
    );
    if (res && !res.error) {
      Props.setTemplateSteps(res.data);
      Props.setSubmitSteps((prev) => ({
        ...prev, // Spread the existing properties of submitSteps
        steps: [...(prev.steps || []), ...(res.data.steps || [])], // Merge the steps arrays
      }));
      Props.scrollToBottom();
      if (res.data.status_code === 1) {
        Props.setTemplateSteps(res.data);
      }
    } else {
      alert("Error fetching Template steps:", res.error);
    }
  };

  return (
    <div className="flex flex-col bg-[#F8F8F8] p-2 rounded-[3px] mt-4">
      {/* Search bar */}
      <div className="flex items-center bg-white rounded-[3px] px-2 py-1 shadow-sm">
        <img
          className="w-4 h-4 text-gray-500 mr-2"
          src={SearchIcon}
          alt="Search Icon"
        />
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          className="flex-1 bg-transparent outline-none text-sm text-gray-700 placeholder-gray-500"
          placeholder="Search Templates..."
        />
      </div>

      {/* Filter buttons for categories */}
      {!WorkoutType && (
        <div className="flex flex-wrap justify-between my-2">
          {categories.map((category) => (
            <button
              key={category.name}
              onClick={() => handleCategoryChange(category.name)}
              className={`flex items-center px-1 space-x-2 rounded my-1 ${
                selectedCategory === category.name
                  ? "bg-[var(--blue)] text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
            >
              {category.name === "all"
                ? "All"
                : category && (
                    <img
                      className="h-4 w-4"
                      src={
                        selectedCategory === category.name
                          ? category.activeIcon
                          : category.inactiveIcon
                      }
                      alt={category.label}
                    />
                  )}
            </button>
          ))}
        </div>
      )}

      {/* Template list */}
      <div
        className={
          fullHeight
            ? "pt-4 h-[60vh] overflow-y-scroll no-scrollbar"
            : "pt-4 h-[10rem] overflow-y-scroll no-scrollbar"
        }
      >
        {filteredData.map((template) => (
          <div
            key={`template-${template.key}`}
            className="flex flex-wrap items-end justify-between border-b border-[#DBDBDB] py-2 cursor-pointer"
            onClick={() => fetchTemplateSteps(template.key)}
          >
            <div className="flex flex-col">
              <p className="text-[var(--blue)] text-[1rem]">{template.value}</p>
              <span className="text-[var(--light-blue)] text-[.7rem]">
                {template.name}
              </span>
            </div>

            <div className="flex justify-end">
              <span className="text-[.7rem] text-[#8ECAE6]">
                {template.brief_information}
              </span>
            </div>
          </div>
        ))}
        {filteredData.length === 0 && (
          <p className="text-gray-500 text-sm text-center mt-4">
            No templates found.
          </p>
        )}
      </div>
    </div>
  );
}

export default WorkoutTemplates;
